interface Props {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
}

export default function ExternalLinkCircleIcon({ className, width, height, color }: Props) {
  return (
    <svg
      className={className}
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.125 6.5V10.25C12.125 10.4158 12.0592 10.5747 11.9419 10.6919C11.8247 10.8092 11.6658 10.875 11.5 10.875C11.3342 10.875 11.1753 10.8092 11.0581 10.6919C10.9409 10.5747 10.875 10.4158 10.875 10.25V8.00859L6.94219 11.9422C6.88412 12.0003 6.81518 12.0463 6.73931 12.0777C6.66344 12.1092 6.58213 12.1253 6.5 12.1253C6.41788 12.1253 6.33656 12.1092 6.26069 12.0777C6.18482 12.0463 6.11588 12.0003 6.05782 11.9422C5.99975 11.8841 5.95368 11.8152 5.92226 11.7393C5.89083 11.6634 5.87466 11.5821 5.87466 11.5C5.87466 11.4179 5.89083 11.3366 5.92226 11.2607C5.95368 11.1848 5.99975 11.1159 6.05782 11.0578L9.99141 7.125H7.75C7.58424 7.125 7.42527 7.05915 7.30806 6.94194C7.19085 6.82473 7.125 6.66576 7.125 6.5C7.125 6.33424 7.19085 6.17527 7.30806 6.05806C7.42527 5.94085 7.58424 5.875 7.75 5.875H11.5C11.6658 5.875 11.8247 5.94085 11.9419 6.05806C12.0592 6.17527 12.125 6.33424 12.125 6.5ZM17.125 9C17.125 10.607 16.6485 12.1779 15.7557 13.514C14.8629 14.8502 13.594 15.8916 12.1093 16.5065C10.6247 17.1215 8.99099 17.2824 7.4149 16.9689C5.8388 16.6554 4.39106 15.8815 3.25476 14.7452C2.11846 13.6089 1.34463 12.1612 1.03112 10.5851C0.717618 9.00901 0.87852 7.37535 1.49348 5.8907C2.10844 4.40605 3.14985 3.1371 4.486 2.24431C5.82214 1.35152 7.39303 0.875 9 0.875C11.1542 0.877275 13.2195 1.73403 14.7427 3.25727C16.266 4.78051 17.1227 6.84581 17.125 9ZM15.875 9C15.875 7.64025 15.4718 6.31104 14.7164 5.18045C13.9609 4.04987 12.8872 3.16868 11.631 2.64833C10.3747 2.12798 8.99238 1.99183 7.65876 2.2571C6.32514 2.52237 5.10013 3.17716 4.13864 4.13864C3.17716 5.10013 2.52238 6.32513 2.2571 7.65875C1.99183 8.99237 2.12798 10.3747 2.64833 11.6309C3.16868 12.8872 4.04987 13.9609 5.18046 14.7164C6.31105 15.4718 7.64026 15.875 9 15.875C10.8227 15.8729 12.5702 15.1479 13.8591 13.8591C15.1479 12.5702 15.8729 10.8227 15.875 9Z"
        fill={color || '#9FA8B7'}
      />
    </svg>
  );
}
