interface Props {
  className?: string;
}

export default function DCAMenuIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 4.24998V7.99998C17.5 8.16574 17.4342 8.32472 17.3169 8.44193C17.1997 8.55914 17.0408 8.62498 16.875 8.62498H13.125C13.0013 8.62508 12.8804 8.58848 12.7775 8.51981C12.6746 8.45114 12.5945 8.35349 12.5471 8.23923C12.4998 8.12496 12.4874 7.99922 12.5115 7.87792C12.5357 7.75661 12.5953 7.64521 12.6828 7.5578L14.1133 6.12498C12.9625 5.02244 11.4319 4.40455 9.83828 4.3992H9.80313C8.17012 4.396 6.60151 5.0359 5.43672 6.18045C5.31736 6.29188 5.15914 6.35216 4.99589 6.34842C4.83265 6.34467 4.67736 6.2772 4.56323 6.16042C4.44911 6.04364 4.38522 5.88683 4.38523 5.72355C4.38524 5.56027 4.44914 5.40347 4.56328 5.2867C5.95655 3.92544 7.82476 3.15962 9.77262 3.15131C11.7205 3.143 13.5952 3.89284 15 5.24217L16.4344 3.8078C16.5219 3.72082 16.6331 3.66169 16.7542 3.63784C16.8752 3.61399 17.0006 3.6265 17.1145 3.67378C17.2285 3.72106 17.3259 3.801 17.3945 3.90354C17.4631 4.00608 17.4998 4.12662 17.5 4.24998ZM14.5633 14.8195C13.4056 15.9502 11.8544 16.5879 10.2362 16.5982C8.61795 16.6086 7.05878 15.9908 5.88672 14.875L7.31719 13.4445C7.40551 13.3572 7.46582 13.2456 7.49045 13.1239C7.51507 13.0022 7.50288 12.8759 7.45544 12.7612C7.408 12.6464 7.32745 12.5484 7.22407 12.4796C7.12069 12.4108 6.99917 12.3744 6.875 12.375H3.125C2.95924 12.375 2.80027 12.4408 2.68306 12.558C2.56585 12.6753 2.5 12.8342 2.5 13V16.75C2.4999 16.8737 2.53651 16.9946 2.60517 17.0975C2.67384 17.2003 2.77149 17.2805 2.88576 17.3279C3.00002 17.3752 3.12576 17.3876 3.24707 17.3634C3.36837 17.3393 3.47978 17.2797 3.56719 17.1922L5 15.7578C6.38453 17.094 8.2321 17.8431 10.1562 17.8484H10.1977C12.1571 17.8535 14.0396 17.0864 15.4375 15.7133C15.5516 15.5965 15.6155 15.4397 15.6156 15.2764C15.6156 15.1131 15.5517 14.9563 15.4375 14.8396C15.3234 14.7228 15.1681 14.6553 15.0049 14.6515C14.8416 14.6478 14.6834 14.7081 14.5641 14.8195H14.5633Z"
        fill="currentColor"
      />
    </svg>
  );
}
