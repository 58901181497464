import clsx from 'clsx';
import { memo } from 'react';

interface Props {
  className?: string;
  forceDark?: boolean;
}

function SwapscannerLogo({ className, forceDark = false }: Props) {
  return (
    <svg className={className} viewBox="0 0 143 24">
      <g clipPath="url(#clip0_411_406)">
        <path
          d="M12.7598 5.77344H15.8182L17.8182 13.9088L20.0412 5.77344H22.5001L24.7112 13.8833L26.7341 5.77344H29.7925L26.1573 18.7563H23.5815L21.2643 10.2438L18.959 18.7563H16.3712L12.7598 5.77344Z"
          className={clsx(forceDark ? 'fill-teal-400' : 'fill-teal-500 dark:fill-teal-400')}
        />
        <path
          d="M42.9331 5.77396H46.0925V6.81396C46.9704 5.6966 48.3622 5.24121 49.6658 5.24121C53.139 5.24121 55.0321 8.4122 55.0321 11.999C55.0321 15.6245 53.139 18.7568 49.6158 18.7568C48.274 18.7568 46.9704 18.3146 46.0925 17.1973V23.8768H42.9331V5.77396ZM51.9482 11.9999C51.9482 9.84253 51.0076 8.16605 48.9016 8.16605C46.7948 8.16605 45.8169 9.81616 45.8169 11.9999C45.8169 14.1836 46.7948 15.8856 48.9016 15.8856C51.0076 15.8856 51.9482 14.1573 51.9482 11.9999Z"
          className={clsx(forceDark ? 'fill-teal-400' : 'fill-teal-500 dark:fill-teal-400')}
        />
        <path
          d="M35.5481 5.24023C33.0783 5.24023 31.4863 6.38397 30.2827 8.08595L32.6296 9.83716C33.169 8.89035 34.1342 8.00859 35.5735 8.00859C37.5667 8.00859 38.1935 8.82705 38.2563 10.7506H34.7831C31.9748 10.7506 29.7925 11.686 29.7925 14.5062C29.7925 17.2227 31.7738 18.7558 34.3437 18.7558C35.7729 18.7558 37.4403 18.1062 38.2554 16.7673V18.7558H41.3148V10.6205C41.3148 7.44947 38.9577 5.24023 35.5481 5.24023ZM34.9586 16.0789C33.5922 16.0789 32.9273 15.4679 32.9273 14.6108C32.9273 13.7923 33.5668 13.038 34.9586 13.038H38.1935H38.2554C38.0671 14.5721 36.839 16.0789 34.9586 16.0789Z"
          className={clsx(forceDark ? 'fill-teal-400' : 'fill-teal-500 dark:fill-teal-400')}
        />
        <path
          d="M4.18309 4.81233C4.18309 3.6431 5.16102 2.85014 6.4146 2.85014C7.60542 2.85014 8.65883 3.56486 9.2729 4.81233L12.2644 3.96661C11.2907 1.11387 8.88699 -0.0878906 6.52741 -0.0878906C3.54357 -0.0878906 0.998239 1.73189 0.998239 4.98024C0.998239 10.9583 9.57485 9.68441 9.57485 13.4928C9.57485 14.7921 8.54688 15.8321 6.65378 15.8321C4.77341 15.8321 3.20601 14.8185 2.98039 12.531L-0.169678 13.4216C0.418946 16.9952 3.4859 18.7569 6.60289 18.7569C9.9879 18.7569 12.7588 16.6646 12.7588 13.1947C12.7597 7.09892 4.18309 8.26903 4.18309 4.81233Z"
          className={clsx(forceDark ? 'fill-teal-400' : 'fill-teal-500 dark:fill-teal-400')}
        />
        <path
          d="M132.987 11.9693C132.987 9.07081 131.571 5.41895 127.183 5.41895C122.944 5.41895 121.039 8.85015 121.039 12.2286C121.039 15.6537 122.99 18.8027 127.126 18.9284V18.9345H131.369V16.0537H127.126C125.353 15.9482 124.348 14.815 124.098 13.1965L132.875 13.2167C132.937 13.0348 132.987 12.502 132.987 11.9693ZM124.098 10.9301C124.386 9.30553 125.351 8.08444 127.157 8.08444C128.486 8.08444 129.514 8.77279 129.828 10.1117C129.904 10.3719 129.928 10.6444 129.928 10.9301H124.098Z"
          className={clsx(forceDark ? 'fill-white' : 'fill-gray-400 dark:fill-white')}
        />
        <path
          d="M119.825 10.8833C119.825 8.16679 118.533 5.4248 115.248 5.4248C114.019 5.4248 112.577 5.93206 111.575 7.20502V5.95668H108.515V18.9343H111.575V18.9387V11.5716C111.575 9.31052 112.653 8.34876 114.371 8.34876C115.938 8.34876 116.766 9.58305 116.766 11.2859V18.9343H119.826V10.8833H119.825Z"
          className={clsx(forceDark ? 'fill-white' : 'fill-gray-400 dark:fill-white')}
        />
        <path
          d="M106.897 10.8833C106.897 8.16679 105.605 5.4248 102.32 5.4248C101.091 5.4248 99.6495 5.93206 98.647 7.20502V5.95668H95.5876V18.9343H98.647V18.9387V11.5716C98.647 9.31052 99.725 8.34876 101.443 8.34876C103.011 8.34876 103.839 9.58305 103.839 11.2859V18.9343H106.898V10.8833H106.897Z"
          className={clsx(forceDark ? 'fill-white' : 'fill-gray-400 dark:fill-white')}
        />
        <path
          d="M88.212 5.41943C85.7422 5.41943 84.1502 6.56317 82.9467 8.26515L85.2935 10.0164C85.833 9.06955 86.7982 8.18778 88.2375 8.18778C90.2307 8.18778 90.8575 9.00624 90.9202 10.9298H87.4479C84.6396 10.9298 82.4573 11.8651 82.4573 14.6854C82.4573 17.4019 84.4386 18.935 87.0085 18.935C88.4377 18.935 90.1051 18.2854 90.9202 16.9465V18.935H93.9795V10.7996C93.9795 7.62866 91.6225 5.41943 88.212 5.41943ZM87.6226 16.2581C86.2562 16.2581 85.5912 15.6471 85.5912 14.79C85.5912 13.9715 86.2307 13.2172 87.6226 13.2172H90.8575H90.9194C90.7319 14.7504 89.5038 16.2581 87.6226 16.2581Z"
          className={clsx(forceDark ? 'fill-white' : 'fill-gray-400 dark:fill-white')}
        />
        <path
          d="M141.71 8.91941L143.127 6.34974C142.506 5.71501 141.612 5.41699 140.759 5.41699C139.308 5.41699 137.843 6.30051 137.286 7.87326V5.94886H134.201V18.9344H137.26L137.26 13.1884C137.26 9.88732 138.802 8.50974 140.279 8.50974C140.785 8.50974 141.264 8.66534 141.692 8.95193L141.71 8.91941Z"
          className={clsx(forceDark ? 'fill-white' : 'fill-gray-400 dark:fill-white')}
        />
        <path
          d="M79.1105 14.2471C78.7271 15.3319 77.8238 16.0633 76.6813 16.0633C74.5499 16.0633 73.7475 14.1785 73.7475 12.2031C73.7475 10.2664 74.5753 8.29104 76.6813 8.29104C77.845 8.29104 78.7279 9.05235 79.1096 10.16L81.649 8.26554C80.5922 6.34906 78.8238 5.41895 76.6813 5.41895C72.594 5.41895 70.6382 8.79829 70.6382 12.2031C70.6382 15.6466 72.6195 18.9354 76.7068 18.9354C78.8331 18.9354 80.5727 17.8858 81.5481 16.066L79.1105 14.2471Z"
          className={clsx(forceDark ? 'fill-white' : 'fill-gray-400 dark:fill-white')}
        />
        <g clipPath="url(#clip1_411_406)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.2738 14.0873C54.7222 10.634 59.5399 1.98115 65.2028 5.23778L65.222 5.24903L69.6039 7.87088C70.2243 8.24212 70.437 9.06441 70.0788 9.70751C69.7206 10.3506 68.9273 10.571 68.3068 10.1998L63.9353 7.58413C61.2537 6.05375 58.9805 10.1347 61.6002 11.7762L63.9494 13.1818C64.5699 13.553 64.7825 14.3753 64.4244 15.0184C64.0662 15.6615 63.2729 15.8819 62.6524 15.5107L60.2928 14.0989L60.2738 14.0873Z"
            className={clsx(forceDark ? 'fill-teal-400' : 'fill-teal-500 dark:fill-teal-400')}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.6897 9.03538C62.0479 8.39227 62.8412 8.17187 63.4617 8.5431L65.8212 9.95486L65.8402 9.96645C71.3919 13.4198 66.5742 22.0726 60.9113 18.816L60.8921 18.8048L56.5102 16.1829C55.8898 15.8117 55.6771 14.9894 56.0353 14.3463C56.3935 13.7032 57.1868 13.4828 57.8073 13.854L62.1788 16.4697C64.8604 18 67.1336 13.9191 64.5139 12.2776L62.1647 10.872C61.5442 10.5008 61.3316 9.67849 61.6897 9.03538Z"
            className={clsx(forceDark ? 'fill-white' : 'fill-gray-400 dark:fill-white')}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_411_406">
          <rect
            width="143"
            height="24"
            className={clsx(forceDark ? 'fill-white' : 'fill-gray-400 dark:fill-white')}
          />
        </clipPath>
        <clipPath id="clip1_411_406">
          <rect
            width="15.0973"
            height="15.6484"
            className={clsx(forceDark ? 'fill-white' : 'fill-gray-400 dark:fill-white')}
            transform="translate(55.5547 4.21973)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(SwapscannerLogo);
