import useSWR, { SWRConfiguration } from 'swr';

import { call } from '@/ws';

import type { MaybePromise } from '@/types';
import type { IWSRequestParams } from 'rpc-websockets/dist/lib/client';

export type WithFetchedAt<T> = T & { fetchedAt?: number };

type WSFetcherOptions<Params> = {
  method: string;
  params?: Params;
  timeout?: number;
};

export async function wsFetcher<Result = any, Params extends IWSRequestParams = any>({
  method,
  params,
  timeout = 5_000,
}: WSFetcherOptions<Params>): Promise<Result> {
  const result = (await call(method, params, timeout)) as any;
  return result;
}

type UseWSCallSWROptions<Result> = {
  cacheKey?: string;
  onFetchSuccess?: (result: WithFetchedAt<Result>) => void;
  onFetchError?: (error: any) => MaybePromise<any>;
  withTimstamp?: boolean;
} & Omit<SWRConfiguration, 'fetcher'>;

/**
 * Passing falsy value to `method` will prevent executing `wsFetcher`.
 * It will automatically revalidate the data if `cacheKey` changes. (`method` when `cacheKey` is not provided)
 * Fully configurable with all features of SWR
 * @see https://swr.vercel.app/docs/options for more details
 */
export default function useWSCallSWR<Result = any>(
  method?: string | null,
  params: object = {},
  {
    cacheKey,
    onFetchSuccess,
    onFetchError,
    withTimstamp,
    ...options
  }: UseWSCallSWROptions<Result> = {},
) {
  const { data, error, mutate, isValidating } = useSWR<WithFetchedAt<Result>>(
    // Do not call fetcher if method is `null`
    !!method ? [cacheKey ?? method, method, params] : null,
    async (_key, method, params) =>
      await wsFetcher<WithFetchedAt<Result>>({ method, params, timeout: 7_000 })
        .then((result) => {
          if (withTimstamp) {
            result.fetchedAt = Date.now();
          }
          onFetchSuccess && onFetchSuccess(result);
          return result;
        })
        .catch((err) => {
          if (onFetchError) {
            return onFetchError(err);
          }
          throw err;
        }),
    options,
  );

  return { result: data, loading: !data && !error, error, isValidating, mutate };
}
