import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  show?: boolean;
  slow?: boolean;
}

export default function MenuTransition({ children, show, slow }: Props) {
  return (
    <Transition
      show={show}
      as={Fragment}
      enter={clsx('transition ease-out', slow ? 'duration-300' : 'duration-100')}
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave={clsx('transition ease-in', slow ? 'duration-200' : 'duration-75')}
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      {children}
    </Transition>
  );
}
