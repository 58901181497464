import { memo } from 'react';

interface Props {
  className?: string;
}
function Grid6ICon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11V3H8V11H3ZM4.5 4.5H6.5V9.5H4.5V4.5ZM3 21V13H8V21H3ZM4.5 14.5H6.5V19.5H4.5V14.5ZM9.5 3V11H14.5V3H9.5ZM13 4.5H11V9.5H13V4.5ZM9.5 21V13H14.5V21H9.5ZM11 14.5H13V19.5H11V14.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3V11H21V3H16ZM17.5 4.5H19.5V9.5H17.5V4.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 13V21H21V13H16ZM17.5 14.5H19.5V19.5H17.5V14.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default memo(Grid6ICon);
