/**
 * To resolve the issue of "Error: No matching key. pairing:...", delete the WalletConnect IndexedDB.
 */
export default function clearWalletConnectIndexedDB() {
  const dbName = 'WALLET_CONNECT_V2_INDEXED_DB';
  const request = indexedDB.open(dbName);

  request.onsuccess = function (event: Event) {
    if (!event || !event.target) {
      return;
    }

    const db = (event.target as IDBRequest).result;
    const transaction = db.transaction(['keyvaluestorage'], 'readwrite');
    const objectStore = transaction.objectStore('keyvaluestorage');
    const objectStoreRequest = objectStore.clear();

    objectStoreRequest.onerror = (event: Event) => {
      console.error('failed to clear indexedDB: WALLET_CONNECT_V2_INDEXED_DB', event);
    };
  };

  request.onerror = (event) => {
    console.error('failed to open indexedDB: WALLET_CONNECT_V2_INDEXED_DB', event);
  };
}
