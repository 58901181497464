import { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';

export default function useLocalStorage<T>(
  key: string,
  defaultValue: T,
): [T, (value: T) => void, Dispatch<SetStateAction<T>>] {
  const [altered, setAltered] = useState(false);
  const [value, _setValue] = useState(defaultValue);

  useEffect(() => {
    if (altered) return;
    const storedValue = window.localStorage.getItem(key);
    if (storedValue) {
      _setValue(JSON.parse(storedValue));
    }
  }, [altered, key]);

  useEffect(() => {
    if (!altered) return;
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [altered, key, value]);

  const setValue = useCallback(
    (newValue: T) => {
      setAltered(true);
      _setValue(newValue);
    },
    [setAltered, _setValue],
  );

  return [value, setValue, _setValue];
}
