import clsx from 'clsx';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { dressTokenCount } from '@/utils/dress-token-count';

import type { Token } from '@/lib/tokens';

type PriceConditionPercentageInputProps = {
  className?: string;
  percentage: number | null;
  minimum: number;
  maximum: number;
  quoteRate: number | null;
  tokenIn: Token | null;
  tokenOut: Token | null;
  rateReversed: boolean;
  onChange: (percentage: number | null) => void;
  forceDark?: boolean;
};

export function PriceConditionPercentageInputV2({
  className,
  percentage,
  minimum,
  maximum,
  quoteRate,
  tokenIn,
  tokenOut,
  rateReversed,
  onChange,
  forceDark,
}: PriceConditionPercentageInputProps) {
  const [inputString, setInputString] = useState(percentage === null ? '' : percentage.toString());

  useEffect(() => {
    setInputString(percentage === null ? '' : percentage.toString());
  }, [percentage]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.value === '' || e.target.value === '-') {
        setInputString(e.target.value);
        return;
      }

      const value = parseInt(e.target.value.replace(/,/g, ''), 10);

      if (isNaN(value)) {
        return;
      }

      setInputString(value.toString());
    } catch (e) {
      console.error(e);
    }
  };

  const limitRate = useMemo(() => {
    if (percentage === null || quoteRate === null) {
      return null;
    }

    const baseRate = !rateReversed ? quoteRate : 1 / quoteRate;

    return baseRate * (1 + percentage / 100);
  }, [percentage, quoteRate, rateReversed]);

  return (
    <div
      className={clsx(
        className,
        'p-4 rounded-2lg border',
        forceDark
          ? 'bg-gray-950 border-gray-700'
          : 'bg-gray-50 border-gray-200 dark:bg-gray-950 dark:border-gray-700',
      )}
    >
      <div
        className={clsx(
          'flex justify-between items-center text-lg',
          forceDark ? 'text-white' : 'text-gray-800 dark:text-white',
        )}
      >
        <div className="grid grid-cols-1 flex-grow">
          <input
            type="text"
            value={inputString}
            className="p-0 bg-transparent border-none focus:ring-0 font-semibold text-2xl"
            onChange={handleChange}
            onBlur={() => {
              if (inputString === '') {
                onChange(null);
                return;
              }

              const value = parseInt(inputString.replace(/,/g, ''), 10);

              if (value < minimum) {
                onChange(minimum);
              } else if (value > maximum) {
                onChange(maximum);
              } else {
                onChange(value);
              }
            }}
          />
        </div>
        <span className="ml-2 font-semibold text-gray-400 text-2xl">%</span>
      </div>

      <div className="mt-2 flex justify-end space-x-1 text-gray-400 text-sm font-medium">
        <span>{limitRate ? dressTokenCount(limitRate) : '-'}</span>
        <span>
          {!rateReversed ? tokenOut?.symbol : tokenIn?.symbol}/
          {!rateReversed ? tokenIn?.symbol : tokenOut?.symbol}
        </span>
      </div>
    </div>
  );
}
