import { Trans, useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import ABICoder from 'web3-eth-abi';

import { LinkIcon } from '@/components/vector';
import { useWalletContext, Wallet } from '@/context/wallet';
import { ZERO_ADDRESS } from '@/defines/token-address';
import { bigintMin } from '@/lib/bigintMin';
import bigIntToHexString from '@/utils/bigint-to-hex-string';
import setIntervalWithTimeout from '@/utils/setIntervalWithTimeout';

export type TransactionSpec = {
  // from address (hex)
  from?: string;
  // to address (hex)
  to?: string;
  abi?: any;
  params?: any[];
  value: bigint | string | number;
  name?: string;
};

type KlipTransaction = {
  from: string;
  to: string;
  // falsey abi && params means it's a value transfer
  abi?: string;
  params?: string;
  value: string;
};

type Web3TransactionWithoutGas = {
  from: string;
  to: string;
  data: string;
  value: string;
};

type Web3Transaction = {
  gas: string;
} & Web3TransactionWithoutGas;

type ETHTransaction = {
  type: '0x2';
  maxPriorityFeePerGas: string;
  maxFeePerGas: string;
} & Web3Transaction;

type KLAYTransaction = {
  type: 'SMART_CONTRACT_EXECUTION';
  gasPrice: string;
} & Web3Transaction;

type Transaction = KlipTransaction | ETHTransaction | KLAYTransaction;

type PromiseLikeAction = {
  then?: (res: any) => void;
  catch?: (err: any) => void;
  finally?: () => void;
};

const formatParamsKlip = (params: any[]): any[] => {
  return params.map((p) => {
    if (typeof p === 'bigint') {
      return p.toString();
    }
    return p;
  });
};

const formatParamsWeb3 = (params: any[]): any[] => {
  return params.map((p) => {
    if (typeof p === 'bigint') {
      return bigIntToHexString(p);
    }
    return p;
  });
};

const generateDecoratedTxCreator =
  (tx?: TransactionSpec, wallet?: Wallet) => async (): Promise<Transaction | undefined> => {
    if (!tx) throw new Error('tx is empty');
    if (!wallet) throw new Error('wallet not found');
    if (!wallet.type) throw new Error('wallet not connected');

    const { from, to, abi, params, value: valueLike } = tx;

    if (!from) throw new Error('empty from address');
    if (!to) throw new Error('empty to address');
    if (to.toLowerCase() === ZERO_ADDRESS) throw new Error('null to address');
    // abi and params cannot be undefined at the same time
    if ((abi && !params) || (!abi && params)) throw new Error('empty definition');

    const value = BigInt(valueLike);

    if (wallet.type === 'klip') {
      return {
        from,
        to,
        abi: abi ? JSON.stringify(abi) : undefined,
        params: params ? JSON.stringify(formatParamsKlip(params)) : undefined,
        value: value.toString(),
      } as KlipTransaction;
    }

    const web3Tx = {
      from,
      to,
      data: abi && params ? ABICoder.encodeFunctionCall(abi, formatParamsWeb3(params)) : undefined,
      value: bigIntToHexString(value),
    };
    const [gas, gasPriceRaw, upperBoundGasPrice] = await Promise.all([
      wallet.estimateGas(web3Tx),
      wallet.gasPrice(),
      wallet.upperBoundGasPrice().catch((err) => {
        // there are few wallets that have not upgraded their node to >= 1.9.0
        console.error('upperBoundGasPrice', err);
        return null;
      }),
    ]);

    // KEN returns gasPrice to be baseFee * 2, regardless of the upperBoundGasPrice. Thus we are
    // capping here.
    const gasPrice = bigintMin([gasPriceRaw, upperBoundGasPrice]);

    if (wallet.type === 'metamask' || wallet.type === 'walletConnect') {
      return {
        ...web3Tx,
        type: '0x2',
        maxPriorityFeePerGas: bigIntToHexString(gasPrice),
        maxFeePerGas: bigIntToHexString(gasPrice),
        gas: bigIntToHexString(gas),
      } as ETHTransaction;
    }

    return {
      ...web3Tx,
      type: abi ? 'SMART_CONTRACT_EXECUTION' : 'VALUE_TRANSFER',
      gasPrice: bigIntToHexString(gasPrice),
      gas: bigIntToHexString(gas),
    } as KLAYTransaction;
  };

type UseWeb3TransactionReturns = {
  txId?: string;
  send: () => Promise<void>;
  inFlight: boolean;
};

type UseWeb3TransactionProps = {
  tx?: TransactionSpec;
  txId?: string;
  before?: () => void;
  wait?: boolean;
  and?: PromiseLikeAction;
};

export const useWeb3Transaction = ({
  tx,
  txId,
  before,
  and,
  wait = false,
}: UseWeb3TransactionProps): UseWeb3TransactionReturns => {
  const { enqueueSnackbar } = useSnackbar();
  const { wallet, updateInvalidator } = useWalletContext();
  const { t: tc } = useTranslation();

  const [inFlight, setInFlight] = useState<boolean>(false);

  const decoratedTxCreator = useMemo<() => Promise<Transaction | undefined>>(
    () => generateDecoratedTxCreator(tx, wallet),
    [tx, wallet],
  );
  const { name } = tx || {};

  const send = useCallback(async () => {
    if (!wallet) {
      enqueueSnackbar(tc('Please connect your wallet'));
      return;
    }

    if (!wallet.send) {
      enqueueSnackbar(tc('This wallet does not support sending transaction'), { variant: 'error' });
      return;
    }

    const shouldWait = wait && wallet?.type !== 'klip' && wallet?.getTransactionReceiptStatus;

    let resolvedTx;

    try {
      setInFlight(true);
      const resolvedDecoratedTx = await decoratedTxCreator();
      if (!resolvedDecoratedTx) {
        enqueueSnackbar(tc('Failed to construct transaction'), { variant: 'error' });
        return;
      }

      resolvedTx = resolvedDecoratedTx;

      before?.();
      const txHash = await wallet.send(resolvedDecoratedTx, { name });
      if (!txHash) {
        throw new Error('failed to submit transaction: no tx hash');
      }

      if (shouldWait) {
        await new Promise<void>((resolve, reject) => {
          let count = 0;
          setIntervalWithTimeout(async (clear) => {
            count += 1;
            if (count > 20) {
              clear();
              resolve();
              return;
            }
            const status = await wallet.getTransactionReceiptStatus(txHash);
            if (status === false) {
              clear();
              reject(new Error('transaction reverted'));
              return;
            } else if (status === true) {
              clear();
              resolve();
              return;
            }
          }, 1000);
        });
      }

      enqueueSnackbar(
        <Trans
          t={tc}
          i18nKey="transaction-submitted"
          components={{
            container: <div className="flex items-center" />,
            scopeLink: (
              <a
                className="pl-1 text-white"
                href={'https://scope.klaytn.com/tx/' + txHash}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            linkIcon: <LinkIcon />,
          }}
        />,
        {
          variant: 'success',
        },
      );

      and?.then?.(txHash);
    } catch (err) {
      enqueueSnackbar(
        <Trans
          t={tc}
          i18nKey="transaction-error"
          values={{
            message: err.data?.message || err.message,
          }}
        />,
        {
          variant: 'error',
        },
      );
      and?.catch?.(err);
    } finally {
      updateInvalidator();
      setInFlight(false);
      and?.finally?.();
    }
  }, [wallet, and, before, name, tc, enqueueSnackbar, decoratedTxCreator, wait, updateInvalidator]);

  return {
    txId,
    send,
    inFlight,
  };
};
