import { useMemo } from 'react';

import { IMAGINARY_FIAT_ADDRESS_MAP } from '@/defines/token-address';

import useWSCallSWR from './use-ws-call-swr';

import type { Token, TokenMap } from '@/lib/tokens';

export default function useTokens({ withImaginaryFiats = false, whitelistedOnly = false } = {}) {
  const { result: rawTokens } = useWSCallSWR<Token[]>('tokens', undefined, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    refreshInterval: 60 * 1_000,
  });

  const tokens = useMemo<Token[]>(() => {
    if (!rawTokens) return [];

    let _tokens: Token[] = [...rawTokens];

    if (whitelistedOnly) {
      _tokens = _tokens.filter((t) => t.whitelisted);
    }

    if (withImaginaryFiats) {
      Object.values(IMAGINARY_FIAT_ADDRESS_MAP).forEach((address) => {
        _tokens.unshift({
          address,
          name: address.toUpperCase(),
          symbol: address.toUpperCase(),
          decimals: '1',
          whitelisted: true,
        });
      });
    }

    return _tokens;
  }, [rawTokens, whitelistedOnly, withImaginaryFiats]);

  const tokenAddressToToken = useMemo<TokenMap>(() => {
    if (!tokens) return {};

    return Object.fromEntries(tokens.map((t) => [t.address, t]));
  }, [tokens]);

  return {
    tokens,
    tokenAddressToToken,
  };
}
