interface Props {
  className?: string;
}

export default function EditCompleteIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="m9 9.707l5.146 5.147a.5.5 0 0 0 .708-.708l-13-13a.5.5 0 1 0-.708.708L6.293 7L3.338 9.955a1.65 1.65 0 0 0-.398.644l-.914 2.743a.5.5 0 0 0 .632.632l2.743-.914c.243-.08.463-.217.644-.398L9 9.707ZM8.293 9l-2.955 2.955a.648.648 0 0 1-.253.156l-1.794.598l.598-1.793a.649.649 0 0 1 .156-.254L7 7.707L8.293 9Zm3-2.999L9.706 7.586l.707.707l3.026-3.025a1.914 1.914 0 0 0-2.707-2.708L7.707 5.586l.707.707L10 4.708l1.293 1.293Zm.147-2.733a.914.914 0 1 1 1.293 1.292L12 5.294l-1.293-1.293l.734-.733Z"
      />
    </svg>
  );
}
