import { Skeleton } from '@/components/Skeleton';
import useTokens from '@/hooks/use-tokens';

export default function TokenSymbol({ address }: { address?: string | null }) {
  const { tokenAddressToToken } = useTokens({ withImaginaryFiats: true });

  if (!tokenAddressToToken) {
    return <Skeleton width={100} />;
  }

  return <>{(tokenAddressToToken[address ?? ''] || {}).symbol || ''}</>;
}
