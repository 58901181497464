export const SWAP_INPUT_DEBOUNCE_MS = 500;
export const SWAP_DEFAULT_REFRESH_INTERVAL = 15_000;
export const SWAP_INITIAL_REFRESH_INTERVAL_ON_ERROR = 500;
export const SWAP_MAX_REFRESH_INTERVAL = 60_000;
export const SWAP_QUOTE_EXPIRATION_TIME = 30_000;
export const SWAP_REFRESH_DISABLED_TIME = 180_000; // 3 minutes

export const SWAP_SLIPPAGE_DENOMINATOR = '10000';

export const SWAP_MODES = ['gasEfficient', 'maxReturn'] as const;
type PanelFlags = {
  right: boolean;
  bottom: 'routing' | 'swap-history' | 'large-swap-history' | null;
};
export type SwapMode = (typeof SWAP_MODES)[number];
export const defaultSwapConfig = {
  slippage: 100,
  mode: 'gasEfficient' as SwapMode,
  feeIncluded: true,
  autoRefresh: true,
  amountIn: '100',
  panelFlags: { right: true, bottom: 'routing' } as PanelFlags,
  payWithSCNR: false,
};

export type QuoteBase = {
  id: string;
  priceImpact: number;
  amountInUSDC: number;
  minAmountOut: string;
  minAmountOutUSDC: number;
  estimatedAmountOutAfterFee: string;
  estimatedAmountOutAfterFeeUSDC: number;
  estimatedAmountOutAfterFeeReward: string;
  estimatedAmountOutAfterFeeRewardUSDC: number;
  estimatedAmountOut: string;
  estimatedAmountOutUSDC: number;
  gasIncludedAmountOutUSDC: number;
  // estimatedGas: string;
  // estimatedGasUSDC: number;
  swapscannerFee: string;
  swapscannerFeeUSDC: number;
  swapscannerFeeAfterDiscount: string;
  swapscannerFeeAfterDiscountUSDC: number;
  swapscannerRewardAddress: string;
  swapscannerRewardMultiplier: number;
  swapscannerReward: string;
  swapscannerRewardUSDC: number;
  path: {
    parts: number;
    swaps: {
      exchange: string;
      path: string[];
      amountOuts: string[];
      pathMode: 'token' | 'token-pool';
    }[];
  }[];
  type: string;
  paths: {
    path: { token: string; subPaths: { exchange: string; fraction: number }[] }[];
  }[];
  txWarning?: string;
};

type QuoteResultBase = {
  tokenInAddress: string;
  tokenInBalance: string;
  tokenOutBalance: string;
  tokenInAmount: string;
  tokenOutAddress: string;
  quotes: {
    id: string;
    priceImpact: number;
    amountInUSDC: number;
    minAmountOut: string;
    minAmountOutUSDC: number;
    estimatedAmountOutAfterFee: string;
    estimatedAmountOutAfterFeeUSDC: number;
    estimatedAmountOutAfterFeeReward: string;
    estimatedAmountOutAfterFeeRewardUSDC: number;
    estimatedAmountOut: string;
    estimatedAmountOutUSDC: number;
    gasIncludedAmountOutUSDC: number;
    // estimatedGas: string;
    // estimatedGasUSDC: number;
    type: string; // should be "single_exchange"
    exchange: string;
    parts: number; // should be 1
  }[];
};

type QuoteResultOnError = QuoteResultBase & {
  quote:
    | (QuoteBase & {
        txError: 'empty_from' | 'preview_only' | 'insufficient_balance'; // | 'gas_estimation' ;
      })
    | null;
};

export type TxETH = {
  from: string;
  to: string;
  data: string;
  value: string;
  type: string;
  maxPriorityFeePerGas: string;
  maxFeePerGas: string;
  gas?: string;
};

export type TxKLAY = {
  from: string;
  to: string;
  data: string;
  value: string;
  type: string;
  gasPrice: string;
  gas: string;
};

export type TxKlip = {
  bappName: string;
  to: string;
  value: string;
  abi: string;
  params: string;
  from: string;
};

type QuoteResultOnSuccess = QuoteResultBase & {
  quote:
    | (QuoteBase & {
        txType: 'swap' | 'approve';
        txError: undefined;
        tx: TxETH;
        txKlay: TxKLAY;
        txKlip: TxKlip;
      })
    | null;
};

export type QuoteResult = QuoteResultOnError | QuoteResultOnSuccess;
