import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

export type ValidatedInputHandle = {
  focus: () => void;
  cleanup: () => void;
};

type ValidatedInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  value?: string;
  decimals?: number;
  onChange: (value: string) => void;
  validate: (value: string) => boolean | Promise<boolean>;
  error: boolean;
  setError: (value: boolean) => void;
};

export const ValidatedInput = React.forwardRef<ValidatedInputHandle, ValidatedInputProps>(
  (
    {
      defaultValue = '',
      value = '',
      decimals,
      validate,
      onFocus,
      onBlur,
      onChange,
      error,
      setError,
      ...props
    },
    ref,
  ) => {
    const textInput = useRef<HTMLInputElement>(null);
    const [isInputDirty, setInputDirty] = useState<boolean>(false);
    const [isInputFocused, setFocused] = useState<boolean>(false);
    const [isValid, setValid] = useState<boolean>(false);
    const inputValueRef = useRef<string>('');

    useEffect(() => {
      Promise.resolve(validate(value))
        .then(setValid)
        .catch(() => setValid(false));
    }, [value, validate]);

    useEffect(() => {
      const hasError = isInputDirty && ((isInputFocused && !value) || !isValid);
      setError(hasError);
    }, [isInputDirty, isInputFocused, isValid, setError, value]);

    const cleanup = useCallback(() => {
      setInputDirty(false);
      setFocused(false);
      setValid(false);
      onChange('');
    }, [onChange]);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => textInput.current?.focus(),
        cleanup,
      }),
      [cleanup],
    );

    return (
      <input
        ref={textInput}
        value={value ?? ''}
        onFocus={(e) => {
          setFocused(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          onBlur?.(e);
        }}
        onChange={(e) => {
          const changedAddress = e.target.value;

          if (!!decimals) {
            const [_, fraction] = changedAddress.split('.');

            if (!!fraction && fraction.length > decimals) {
              e.currentTarget.value = inputValueRef.current;
              return;
            }

            inputValueRef.current = changedAddress;
          }

          setInputDirty(true);
          onChange(changedAddress);
          Promise.resolve(validate(changedAddress))
            .then(setValid)
            .catch(() => setValid(false));
        }}
        {...props}
      />
    );
  },
);
