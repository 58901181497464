import '@styles/globals.css';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { SnackbarProvider } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Tooltip } from 'react-tooltip';
import { SWRConfig } from 'swr';

import Banner from '@/components/Banner';
import KlipQrPopup from '@/components/core/KlipQrPopup';
import VersionCheckerProvider from '@/components/core/VersionCheckerProvider';
import { ChatContextProvider } from '@/context/chat';
import { CurrencyContextProvider } from '@/context/currency';
import { DialogContextProvider } from '@/context/dialog';
import { KlipContextProvider } from '@/context/klip';
import { SettingsProvider } from '@/context/settings';
import { WalletContextProvider } from '@/context/wallet';
import { LOCAL_STORAGE_KEYS } from '@/defines/local-storage-keys';
import useAssertiveDisabled from '@/hooks/use-assertive-disabled';
import { getFromlocalStorage } from '@/utils/get-from-local-storage';
import moment from '@/utils/moment';
import { GTM } from '@/utils/tag-manager';

import type { NextComponentType, NextPageContext } from 'next';
import type { AppProps } from 'next/app';

type Props = AppProps & {
  Component: NextComponentType<NextPageContext, any, {}> & {
    Layout?: React.FC;
  };
  pageProps: any;
};

const MyApp = (props: Props) => {
  const { Component, pageProps } = props;
  const router = useRouter();
  const { t } = useTranslation('common');
  const [showBanner, setShowBanner] = useState(false);

  const isAssertivesDisabled = useAssertiveDisabled();

  useEffect(() => {
    const hideBanner = localStorage.getItem(LOCAL_STORAGE_KEYS.hideOrbitHackingBanner);

    if (!hideBanner && !isAssertivesDisabled) setShowBanner(true);
  }, [isAssertivesDisabled]);

  // React.useEffect(() => {
  //   const handleRouteChange = (url) => {
  //     if (typeof window !== 'undefined' && window && window.gtag) {
  //       window.dataLayer.push({
  //         event: 'pageview',
  //         page: url,
  //       });
  //     }
  //   };

  //   router.events.on('routeChangeComplete', handleRouteChange);
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange);
  //   };
  // }, [router.events]);

  useEffect(() => {
    // @ts-ignore
    Moment.globalLocal = router?.locale;
    moment.locale(router?.locale);
  }, [router?.locale]);

  useEffect(() => {
    if (!router.locale) return;

    GTM.initialize('GTM-MPFRHSH', {
      dataLayer: {
        currency: getFromlocalStorage('currency'),
        locale: router.locale,
        systemPrefersDark: window.matchMedia('(prefers-color-scheme: dark)').matches,
        themeMode: getFromlocalStorage('settings')?.themeMode ?? null,
      },
    });
  }, [router.locale]);

  const Layout = Component.Layout ?? Fragment;

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      {/** @link https://swr.vercel.app/docs/options#options */}
      <SWRConfig
        value={{
          focusThrottleInterval: 15_000,
        }}
      >
        <ChatContextProvider>
          <KlipContextProvider>
            <WalletContextProvider>
              <KlipQrPopup />
              <CurrencyContextProvider initialForex={pageProps.forex ?? undefined}>
                <SettingsProvider>
                  <SnackbarProvider maxSnack={3}>
                    <DialogContextProvider>
                      <VersionCheckerProvider>
                        <Layout>
                          <Component {...pageProps} />
                        </Layout>
                        {/* <Banner
                          show={showBanner}
                          title={t(
                            'Inaccurate Token Prices: Due to the Orbit Bridge hacking incident on January 1, fiat prices may not be accurate.',
                          )}
                          titleShort={t(
                            'Inaccurate Token Prices: Due to the Orbit Bridge hacking incident on January 1, fiat prices may not be accurate.',
                          )}
                          // href={t('release-gcklay-link')}
                          isExternalLink
                          onClose={() => {
                            setShowBanner(false);
                            localStorage.setItem(LOCAL_STORAGE_KEYS.hideOrbitHackingBanner, 'true');
                          }}
                        /> */}
                      </VersionCheckerProvider>
                    </DialogContextProvider>
                  </SnackbarProvider>
                </SettingsProvider>
              </CurrencyContextProvider>
            </WalletContextProvider>
          </KlipContextProvider>
        </ChatContextProvider>
      </SWRConfig>

      <Tooltip id="global-tooltip" className="ss-tooltip-base" opacity={1} />
    </>
  );
};

export default appWithTranslation(MyApp);
