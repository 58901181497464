import { createContext, useContext, useCallback, useEffect, useState } from 'react';

import { KlipClient, KlipResult, MessageKlip, TxKlip } from '@/klip/klipClient';

type KlipRequestWithPromiseHandlers = {
  spec: { name?: string };
  status: 'completed' | any;
  requestKey: string;
  resolve: (result: KlipResult) => void;
  reject: (reason: any) => void;
  cancel: () => void;
  expiresAt?: Date;
};

type CreateRequestOptions = {
  type: string;
  transaction?: TxKlip;
  message?: MessageKlip;
  spec?: object;
};

type KlipContext = {
  requests: KlipRequestWithPromiseHandlers[];
  createRequest: (options: CreateRequestOptions) => Promise<{
    promise: Promise<any>;
    cancel: () => void;
  }>;
  cancelAll: () => void;
};

const klipClient = new KlipClient({ bappData: { name: 'Swapscanner' } });

export const KlipContext = createContext<KlipContext | undefined>(undefined);

type Props = {
  children?: React.ReactNode;
};

export const KlipContextProvider: React.FC<Props> = ({ children }) => {
  const [requests, setRequests] = useState<KlipRequestWithPromiseHandlers[]>([]);

  useEffect(() => {
    if (!requests || requests.length === 0) return;

    let destroyed: boolean = false;
    let timeout: NodeJS.Timeout;

    const poll = async () => {
      for (let i = 0; i < requests.length; i += 1) {
        const { resolve, reject, status, requestKey } = requests[i];

        try {
          const res = await klipClient.getResult(requests[i].requestKey);
          if (destroyed) return;

          if (res.status === 'completed') {
            // resolve with res
            resolve(res);

            setRequests((prev) => {
              return prev.filter((r) => r.requestKey !== requestKey);
            });
          } else if (Date.now() > res.expiration_time * 1000 - 10000) {
            reject(new Error('timeout'));

            setRequests((prev) => {
              return prev.filter((r) => r.requestKey !== requestKey);
            });
          } else {
            setRequests((prev) => {
              return prev.map((r) => {
                if (r.requestKey !== requestKey) {
                  return r;
                }

                const result = {
                  ...r,
                  status: res.status,
                };

                if (res.expiration_time) {
                  result.expiresAt = new Date(res.expiration_time * 1000);
                }

                return result;
              });
            });
          }
          // console.log(res);
        } catch (err) {
          reject(err);
          setRequests((prev) => {
            return prev.filter((r) => r.requestKey !== requestKey);
          });
        }
      }
      timeout = setTimeout(poll, 1000);
    };

    timeout = setTimeout(poll, 1000);

    return () => {
      clearTimeout(timeout);
      destroyed = true;
    };
  }, [requests]);

  const createRequest = useCallback(
    async ({ type, transaction, message, spec = {} }: CreateRequestOptions) => {
      // console.log('submitting', method);

      let resolve: (value: KlipResult) => void;
      let reject: (reason: any) => void;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });

      if (!!transaction && !!message) {
        throw new Error('transaction and message cannot be both set');
      }

      const request = await klipClient.prepare({ type, transaction, message });

      const { request_key: requestKey, status } = request;

      const cancel = () => {
        // console.log('cancelling', requestKey);
        setRequests((prev) => {
          return prev.filter((o) => o.requestKey !== requestKey);
        });
        reject('cancelled');
      };

      setRequests((prev) => {
        return [
          ...prev,
          {
            spec,
            resolve,
            reject,
            cancel,
            status,
            requestKey,
          },
        ];
      });

      return {
        promise,
        cancel,
      };
    },
    [setRequests],
  );

  const cancelAll = useCallback(() => {
    requests.forEach((r) => {
      r.cancel();
    });
  }, [requests]);

  return (
    <KlipContext.Provider value={{ requests, createRequest, cancelAll }}>
      {children}
    </KlipContext.Provider>
  );
};

export const useKlipContext = () => {
  const context = useContext(KlipContext);

  if (typeof context === 'undefined') {
    throw new Error(`useKlipContext must be used within a KlipContextProvider`);
  }

  return context;
};
