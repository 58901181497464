import { useCallback, useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { useCurrencyContext } from '@/context/currency';
import { ImaginaryFiatAddress, IMAGINARY_FIAT_ADDRESS_MAP } from '@/defines/token-address';
import { fetchTokenStatsV2, TokenStatsV2 } from '@/lib/token-stats-v2';
import { safeFiniteNumber } from '@/utils/safe-finite-number';

import type { LineData } from '@/lib/spline';

type UseTokenStatsV2Options = {
  tokenAddrs?: string[];
  excludeImaginaryFiats?: boolean;
} & Pick<
  SWRConfiguration<TokenStatsV2[]>,
  'fallbackData' | 'revalidateOnMount' | 'revalidateOnFocus'
>;

export function useTokenStatsV2({
  tokenAddrs,
  fallbackData,
  excludeImaginaryFiats,
  revalidateOnMount,
  ...swrOptions
}: UseTokenStatsV2Options = {}) {
  const { data: stats = fallbackData ?? [], mutate } = useSWR<TokenStatsV2[]>(
    !tokenAddrs ? '@token-stats' : `@token-stats-${tokenAddrs.join(',')}`,
    () => fetchTokenStatsV2(tokenAddrs),
    {
      refreshInterval: 60 * 1_000,
      revalidateIfStale: true,
      revalidateOnMount: revalidateOnMount ?? true,
      ...swrOptions,
    },
  );

  const revalidate = useCallback(() => mutate(), [mutate]);

  const { currency, rate } = useCurrencyContext();

  const currencyStats = useMemo(() => {
    if (!currency || currency === 'USD') {
      return null;
    }

    return (
      stats
        .filter((tokenStat) =>
          Object.values(IMAGINARY_FIAT_ADDRESS_MAP).includes(
            tokenStat.address as ImaginaryFiatAddress,
          ),
        )
        .find((tokenStat) => tokenStat.address === currency.toLowerCase()) ?? null
    );
  }, [stats, currency]);

  const currencySplines = currencyStats?.splines ?? null;
  const [currencyTodayOpenPrice, currencyNowPrice] = useMemo(() => {
    if (!currencyStats || !currencyStats.prices) return [1, 1];
    if (currency === 'USD') return [1, 1];

    return currencyStats.prices;
  }, [currencyStats, currency]);

  const correctedStatsWithCurrency = useMemo(() => {
    const ts = Date.now();

    return stats.map(({ address, prices, splines, ...stats }) => {
      /**
       * If a currency other than USD is selected,
       * convert based on the spline data of the selected currency
       *
       * If currency spline data is not available, return 0 to avoid displaying it on the spline chart.
       */
      const [correctedTodayOpenPrice, correctedNowPrice] = prices
        ? [
            prices[0] * safeFiniteNumber(1 / currencyTodayOpenPrice, 0),
            prices[1] * safeFiniteNumber(1 / currencyNowPrice, 0),
          ]
        : [0, 0];

      const nowPrice = prices[1];

      return {
        ...stats,
        address,
        prices,
        // The price adjustment is handled by 'useForex,' so the unadjusted value is passed.
        price: nowPrice,
        splines: splines
          ? [...splines, [nowPrice, ts] as LineData].map(([price, ts]) => {
              const currencyPriceByTs = currencySplines?.find(([_, _ts]) => _ts === ts)?.[0];
              const currencyPrice =
                currency === 'USD' ? 1 : currencyPriceByTs ? 1 / currencyPriceByTs : rate;

              return [price * currencyPrice, ts] as LineData;
            })
          : undefined,
        priceDiffRatio: safeFiniteNumber(
          (correctedNowPrice - correctedTodayOpenPrice) / correctedTodayOpenPrice,
          0,
        ),
        todayOpenPrice: correctedTodayOpenPrice,
      };
    });
  }, [stats, currency, rate, currencySplines, currencyTodayOpenPrice, currencyNowPrice]);

  return {
    tokenStats: correctedStatsWithCurrency.filter(
      (stat) =>
        !excludeImaginaryFiats ||
        !Object.values(IMAGINARY_FIAT_ADDRESS_MAP).includes(stat.address as ImaginaryFiatAddress),
    ),
    revalidate,
    mutate,
  };
}
