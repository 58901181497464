import { useMemo } from 'react';
import useSWR from 'swr';

import { useWalletContext } from '@/context/wallet';
import fetcher from '@/lib/fetcher';

export type ChatProfileStats = {
  profile: {
    nickname: string;
    color: string;
    sdoongeLevel: string;
    profileImage: string;
    banned: boolean;
    banUntil: string;
    blockNumber: number;
    isCM: boolean;
  };
  contract: {
    address: string;
    abi: {
      setInitialNickname: any;
      changeNickname: any;
      banAccount: any;
      permitAccount: any;
      setNicknameChangeCost: any;
    };
    nicknameChangeCost: string;
    allowance: string;
    permissions: (
      | { role: 'ADMIN_ROLE'; hasRole: boolean }
      | { role: 'CM_ROLE'; hasRole: boolean }
    )[];
  };
};

type Options = { skip: boolean; walletAddress?: string };

export const useChatProfileStats = ({ skip, walletAddress = '' }: Options) => {
  const { wallet } = useWalletContext();

  const account = useMemo(() => {
    return walletAddress || wallet?.account;
  }, [walletAddress, wallet?.account]);

  const res = useSWR<ChatProfileStats>(
    skip || !account ? null : `${process.env.API_HOST}/v1/stats/chat/profile/${account}`,
    { fetcher },
  );

  return res;
};
