export const getFromlocalStorage = <T = any>(
  key: string,
  { parser, throwError = false }: { parser?: (val: string) => T; throwError?: boolean } = {},
) => {
  const value = localStorage.getItem(key);
  if (value === null) return value;

  if (parser) {
    return parser(value);
  }

  try {
    return JSON.parse(value) as T;
  } catch (err) {
    if (throwError) {
      throw err;
    }
    return null;
  }
};
