import { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';

import fetcher from '@/lib/fetcher';
import { subscribePrices, SubscribePricesProps } from '@/lib/subscribe-prices';

type UsePriceOptions = {
  refreshInterval?: number;
  skip?: boolean;
  subscribeAll?: boolean;
  tokensToSubscribe?: string[];
};

export type Prices = {
  [address: string]: number;
};

/**
 * FIXME: Currently, only 1 subscription should be made in single page.
 */
export default function usePrices({
  refreshInterval = 15_000,
  skip = false,
  subscribeAll = false,
  tokensToSubscribe = [],
}: UsePriceOptions = {}) {
  const { data, mutate } = useSWRImmutable<Prices>(
    skip ? null : `${process.env.API_HOST}/v0/tokens/prices`,
    fetcher,
    { refreshInterval, revalidateIfStale: false },
  );

  useEffect(() => {
    if (!subscribeAll && tokensToSubscribe.length === 0) return;

    const handler: SubscribePricesProps['handler'] = ({ timestamp, prices }) => {
      mutate((prev) => ({ ...prev, ...prices }), {
        revalidate: false,
        populateCache: true,
      });
    };
    let unsubscriber: (() => void) | undefined;

    subscribePrices({
      tokenAddressList: subscribeAll ? undefined : tokensToSubscribe,
      handler,
    }).then((_unsubscriber) => {
      unsubscriber = _unsubscriber;
    });

    return () => {
      unsubscriber?.();
    };

    /// @dev use primitive value for dependency list to avoid unnecessary re-subscription.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeAll, tokensToSubscribe.sort().join('-'), mutate]);

  return {
    prices: data,
    mutatePrices: mutate,
  };
}
