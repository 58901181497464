interface Props {
  className?: string;
}

export default function WrapMenuIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.475 5.66798L10.6 1.90626C10.4163 1.80477 10.2099 1.75153 10 1.75153C9.79013 1.75153 9.58369 1.80477 9.4 1.90626L2.525 5.66955C2.32866 5.77697 2.16477 5.93514 2.05043 6.12753C1.93609 6.31993 1.87551 6.53949 1.875 6.7633V14.2352C1.87551 14.459 1.93609 14.6785 2.05043 14.8709C2.16477 15.0633 2.32866 15.2215 2.525 15.3289L9.4 19.0922C9.58369 19.1937 9.79013 19.2469 10 19.2469C10.2099 19.2469 10.4163 19.1937 10.6 19.0922L17.475 15.3289C17.6713 15.2215 17.8352 15.0633 17.9496 14.8709C18.0639 14.6785 18.1245 14.459 18.125 14.2352V6.76408C18.1249 6.53987 18.0645 6.31982 17.9502 6.12698C17.8358 5.93414 17.6717 5.7756 17.475 5.66798ZM10 3.00001L16.2773 6.43751L13.9508 7.71017L7.67344 4.27267L10 3.00001ZM10 9.87501L3.72266 6.43751L6.37187 4.98673L12.6492 8.42423L10 9.87501ZM16.875 14.2383L10.625 17.6594V10.9555L13.125 9.58751V12.375C13.125 12.5408 13.1908 12.6997 13.3081 12.817C13.4253 12.9342 13.5842 13 13.75 13C13.9158 13 14.0747 12.9342 14.1919 12.817C14.3092 12.6997 14.375 12.5408 14.375 12.375V8.90314L16.875 7.53517V14.2352V14.2383Z"
        fill="currentColor"
      />
    </svg>
  );
}
