export default function TermsOfUse() {
  return (
    <div className="flex flex-col items-start space-y-6">
      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">1. General</h3>

        <p>
          These terms and conditions (&ldquo;<b>Terms</b>&rdquo;) govern the use of the Website
          (defined below) and the Services (defined below). These Terms also include any guidelines,
          announcements, additional terms, policies, and disclaimers made available or issued by us
          from time to time. These Terms constitute a binding and enforceable legal contract between
          FLO Works Inc. and its affiliates (&ldquo;<b>Company</b>&rdquo;, &ldquo;<b>Swapscanner</b>
          &rdquo;, &ldquo;<b>we</b>&rdquo;, &ldquo;<b>us</b>&rdquo;) and you, an end user of the
          services, whether personally or on behalf of an entity (&ldquo;<b>you</b>&rdquo; or
          &ldquo;<b>User</b>&rdquo;) at https://swapscanner.io/ (&ldquo;<b>Services</b>&rdquo;).
        </p>

        <p>
          By accessing, using or clicking on our website (and all related subdomains) or its mobile
          applications (&ldquo;<b>Website</b>&rdquo;) or accessing, using or attempting to use the
          Services, you agree that you have read, understood, and are bound by these Terms and that
          you comply with the requirements listed herein. Please note that blockchain networks, even
          if Swapscanner publishes domain names or other records to them, are controlled by third
          parties (who may be distributed networks of independent computers), and are therefore not
          included in the definition of the &ldquo;Website&rdquo;. If you do not agree to all of
          these Terms or comply with the requirements herein, please do not access or use the
          Website or the Services. In addition, when using some features of the Services, you may be
          subject to specific additional terms and conditions applicable to those features.
        </p>

        <p>
          If you are acting for or on behalf of an entity, you hereby represent and warrant that you
          are authorised to accept these Terms and enter into a binding agreement with Swapscanner
          on such entity’s behalf, and you accept these Terms both on behalf of such entity and on
          your own behalf.
        </p>

        <p>
          Information contained in this Website is current as at the date of publication, and we may
          modify, suspend or discontinue the Website or the Services at any time and without
          notifying you. We may also change, update, add or remove provisions of these Terms from
          time to time. Any and all modifications or changes to these Terms will become effective
          upon publication on our Website or release to Users, and you waive any right to receive
          specific notice of each of such change. It is your responsibility to periodically review
          these Terms to stay informed of updates. Therefore, your continued use of our Services is
          deemed your acceptance of the modified Terms and rules. If you do not agree to any changes
          to these Terms, please do not access or use the Website or the Services. We note that
          these Terms between you and us do not enumerate or cover all rights and obligations of
          each party, and do not guarantee full alignment with needs arising from future
          development. Therefore, our privacy policy which can be viewed at the &ldquo;Privacy
          Policy&rdquo; link at our Website, platform rules, guidelines and all other agreements
          entered into separately between you and us are deemed supplementary terms that are an
          integral part of these Terms and shall have the same legal effect. Your use of the Website
          or Services is deemed your acceptance of any supplementary terms too.
        </p>

        <p>
          This Website and the Services are not available to residents of Afghanistan, American
          Samoa, Angola, Armenia, Azerbaijan, Balkans, Belarus, Bosnia and Herzegovina, Botswana,
          Burma (Myanmar), Burundi, Cambodia, Cameroon, Canada, Central African Republic, Chad,
          China, Crimea of Ukraine, Cuba, Democratic People&rsquo;s Republic of Korea, Democratic
          Republic of the Congo, England and Wales, Eritrea, Ethiopia, Ghana, Guam, Guinea,
          Guinea-Bissau, Haiti, Iran, Iraq, Japan, Laos, Lebanon, Liberia, Libya, Madagascar, Mali,
          Mozambique, Nicaragua, Northern Mariana Islands, Pakistan, Puerto Rico, Republic of the
          Congo, Russia, Somalia, South Sudan, Sri Lanka, Sudan, Syrian Arab Republic, Tajikistan,
          Trinidad and Tobago, Turkmenistan, Uganda, United States, Uzbekistan, Vanuatu, Venezuela,
          Virgin Islands (U.S.), Yemen, Zimbabwe and any other jurisdiction in which accessing or
          using our protocol is prohibited (each a &ldquo;<b>Restricted Country</b>&rdquo;). We
          reserve the right to choose markets and jurisdictions to conduct business and may restrict
          or refuse the access of Website and our Services in other countries or regions in our sole
          discretion.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">2. Protocol</h3>

        <p>
          The Website is an interface that enables you to interact with the Protocol, which is a
          decentralised autonomous smart contract system deployed on certain blockchain networks
          (the &ldquo;Protocol&rdquo;). The Protocol is now available on the Klaytn network and
          shall be available on one or more blockchain networks which may evolve from time to time.
          It is further expressly acknowledged that we neither control, nor operate, the Protocol.
          Any information and materials about the Protocol available on the Website are provided for
          information purposes only, are not binding and do not form a part of these Terms.
        </p>

        <p>
          Given that the Protocol is comprised of smart contracts deployed on the blockchain network
          that operate in a decentralised and autonomous manner, these smart contracts can be
          reviewed, verified, used, copied, modified, and distributed by anyone (subject to the
          terms of the applicable license). Accordingly, there might be other interfaces enabling
          interaction with the Protocol that we neither control nor are affiliated with.
          Furthermore, anyone can interact directly with the Protocol bypassing Swapscanner and
          other interfaces. You should carefully and thoroughly review and assess the Protocol and
          related software before you use them, and any such use shall be at your own risk. You
          should always do your own research.
        </p>

        <p>
          You further acknowledge that we do not control the Protocol, its underlying blockchain
          networks, and any software through which such network is formed. Swapscanner only provides
          the users with the Website being an interface to access the Protocol. Accordingly, in no
          event shall Swapscanner be responsible for, or held liable in connection with, the
          Protocol, underlying blockchain networks or software, their operation, functioning,
          implementation, or use, and you hereby assume and accept any and all related risks,
          including the risk of possible losses and damages that you may incur in connection with
          the use thereof.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">3. Your Digital Wallet</h3>

        <p>
          When using the Website, you may connect your digital wallet through one of the third party
          software wallets compatible with the blockchain network where the Protocol is deployed on.
          Software wallets constitute third party services and Swapscanner is not responsible for,
          does not endorse, shall not be held liable in connection with, and does not make any
          warranties, whether express or implied, as to the software wallets used by you with the
          Website or otherwise. When using third party software wallets, you should review
          applicable terms and policies that govern your use of such software.
        </p>

        <p>
          Swapscanner never receives access to or control over your digital wallet or virtual assets
          held in such digital wallet. Therefore, you are solely responsible for securing your
          digital wallet and credentials thereto (including private key, seed phrase, password,
          etc.). You may disconnect your digital wallet from the Website at any time. We are not a
          wallet provider, exchange, broker, financial institution, money services business, or
          creditor. We do not have custody or control over tokens or blockchains you are interacting
          with and we do not execute or effectuate purchases, transfers, or sales of tokens. To use
          our Services, you must use a third party wallet which allows you to engage in transactions
          on blockchains. You bear full responsibility for verifying the identity, legitimacy, and
          authenticity of tokens that you purchase from third party sellers using the Service and we
          make no claims about the identity, legitimacy, functionality, or authenticity of users or
          tokens visible on the Service.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">4. Eligibility</h3>

        <p>
          By accessing, using or clicking on our Website and using or attempting to use our
          Services, you represent and warrant that:
        </p>

        <p>
          (a) as an individual, legal person, or other organisation, you have full legal capacity
          and authority to agree and bind yourself to these Terms;
        </p>

        <p>
          (b) you are at least 18 or are of legal age to form a binding contract under applicable
          laws;
        </p>
        <p>
          (c) your use of the Services is not prohibited by applicable law, and at all times
          compliant with applicable law, including but not limited to regulations on anti-money
          laundering (&ldquo;AML&rdquo;), anti-corruption, and counter-terrorist financing
          (&ldquo;CTF&rdquo;);
        </p>
        <p>
          (d) you are not a citizen, resident or domiciliary in a Restricted Country, nor are you
          using our Services on behalf of any person or entity from a Restricted Country;
        </p>
        <p>
          (e) you have not been included in any trade embargoes or economic sanctions list, the list
          of specially designated nationals maintained by the Office of Foreign Assets Control
          (OFAC), or the denied persons or entity list of the U.S. Department of Commerce, nor you
          have been a subject or target of any other economic sanctions administered or enforced by
          the United Nations, the European Union or the United Kingdom;
        </p>
        <p>(f) you have not been previously suspended or removed from using our Services; </p>
        <p>
          (g) if you act as an employee or agent of a legal entity, and enter into these Terms on
          their behalf, you represent and warrant that you have all the necessary rights and
          authorisations to bind such legal entity; and
        </p>
        <p>
          (h) you are solely responsible for use of the Services and, if applicable, for all
          activities that occur on or through your user identity.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">5. Identity Verification</h3>

        <p>
          We and our affiliates may, but are not obligated to, collect and verify information about
          you in order to keep appropriate record of our users, protect us and the community from
          fraudulent users, and identify traces of money laundering, terrorist financing, fraud and
          other financial crimes, or for other lawful purposes.
        </p>
        <p>
          We may require you to provide or verify additional information before permitting you to
          access, use or click on our Website and/or use or attempt to use any Services. We may also
          suspend, restrict, or terminate your access to our Website or any or all of the Services
          in the following circumstances: (a) if we reasonably suspect you of using our Website and
          Services in connection with any prohibited use or business; (b) your use of our Website or
          Services is subject to any pending litigation, investigation, or government proceeding
          and/or we perceive a heightened risk of legal or regulatory non-compliance associated with
          your activity; or (c) you take any action that we deem as circumventing our controls,
          including, but not limited to, abusing promotions which we may offer from time to time.
        </p>
        <p>
          In addition to providing any required information, you agree to allow us to keep a record
          of that information during the period where you access our Website and use our Services
          and within five (5) years after your use of our Services is terminated. You also authorise
          us to share your submitted information and documentation to third parties to verify the
          authenticity of such information. We may also conduct necessary investigations directly or
          through a third party to verify your identity or protect you and/or us from financial
          crimes, such as fraud, and to take necessary action based on the results of such
          investigations. We will collect, use and share such information in accordance with our
          privacy policy.
        </p>
        <p>
          If you provide any information to us, you must ensure that such information is true,
          complete, and timely updated when changed. If there are any grounds for believing that any
          of the information you provided is incorrect, false, outdated or incomplete, we reserve
          the right to send you a notice to demand correction, directly delete the relevant
          information, and as the case may be, terminate all or part of the Services we provide for
          you. You shall be fully liable for any loss or expense caused to us during your use of the
          Services. You hereby acknowledge and agree that you have the obligation to keep all the
          information accurate, update and correct at all times.
        </p>
        <p>
          We reserve the right to confiscate any and all funds that are found to be in violation of
          relevant and applicable AML or CTF laws and regulations, and to cooperate with the
          competent authorities when and if necessary.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">6. Restrictions</h3>

        <p>
          You shall not access, use or click on our Website and/or use or attempt to use the
          Services in any manner except as expressly permitted in these Terms. Without limiting the
          generality of the preceding sentence, you may NOT:
        </p>

        <p>
          (a) use our Website or use the Services in any dishonest or unlawful manner, for
          fraudulent or malicious activities, or in any manner inconsistent with these Terms;
        </p>

        <p>(b) violate applicable laws or regulations in any manner;</p>

        <p>
          (c) infringe any proprietary rights, including but not limited to copyrights, patents,
          trademarks, or trade secrets of Swapscanner;
        </p>

        <p>
          (d) use our Website or use the Services to transmit any data or send or upload any
          material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers,
          spyware, adware, or any other harmful programmes or computer code designed to adversely
          affect the operation of any computer software or hardware;
        </p>

        <p>
          (e) use any deep linking, web crawlers, bots, spiders or other automatic devices,
          programs, scripts, algorithms or methods, or any similar or equivalent manual processes to
          access, obtain, copy, monitor, replicate or bypass the Website or the Services;
        </p>

        <p>
          (f) make any back-up or archival copies of the Website or any part thereof, including
          disassembling or de-compilation of the Website;
        </p>

        <p>
          (g) violate public interests, public morals, or the legitimate interests of others,
          including any actions that would interfere with, disrupt, negatively affect, or prohibit
          other Users from using our Website and the Services;
        </p>

        <p>
          (h) use the Services for market manipulation (such as pump and dump schemes, wash trading,
          self-trading, front running, quote stuffing, and spoofing or layering, regardless of
          whether prohibited by law);
        </p>

        <p>
          (i) attempt to access any part or function of the Website without authorisation, or
          connect to the Website or Services or any Company servers or any other systems or networks
          of any the Services provided through the services by hacking, password mining or any other
          unlawful or prohibited means;
        </p>

        <p>
          (j) probe, scan or test the vulnerabilities of the Website or Services or any network
          connected to the properties, or violate any security or authentication measures on the
          Website or Services or any network connected thereto;
        </p>

        <p>
          (k) reverse look-up, track or seek to track any information of any other Users or visitors
          of the Website or Services;
        </p>

        <p>
          (l) take any actions that imposes an unreasonable or disproportionately large load on the
          infrastructure of systems or networks of the Website or Services, or the infrastructure of
          any systems or networks connected to the Website or Services;
        </p>

        <p>
          (m) use any devices, software or routine programs to interfere with the normal operation
          of any transactions of the Website or Services, or any other person’s use of the Website
          or Services; or
        </p>

        <p>
          (n) forge headers, impersonate, or otherwise manipulate identification, to disguise your
          identity or the origin of any messages or transmissions you send to Swapscanner or the
          Website.
        </p>

        <p>
          By accessing the Services, you agree that we have the right to investigate any violation
          of these Terms, unilaterally determine whether you have violated these Terms, and take
          actions under relevant regulations without your consent or prior notice.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">7. Termination</h3>

        <p>
          Swapscanner may terminate, suspend, or modify your access to Website and/or the Services,
          or any portion thereof, immediately and at any point, at its sole discretion. Swapscanner
          will not be liable to you or to any third party for any termination, suspension, or
          modification of your access to the Services. Upon termination of your access to the
          Services, these Terms shall terminate, except for those clauses that expressly or are
          intended to survive termination or expiry.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">8. No Warranties and Limitation of Liabilities</h3>

        <p>
          OUR SERVICES ARE PROVIDED ON AN &rdquo;AS IS&rdquo; AND &rdquo;AS AVAILABLE&rdquo; BASIS
          WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. YOU HEREBY
          ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR AGREEMENT,
          WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF THE SERVICES.
        </p>

        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SPECIFICALLY DISCLAIMS ANY IMPLIED
          WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR
          NON-INFRINGEMENT. SWAPSCANNER DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS
          TO THE WEBSITE, ANY PART OF THE SERVICES, INCLUDING MOBILE SERVICES, OR ANY OF THE
          MATERIALS CONTAINED THEREIN, WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE AND
          WILL NOT BE LIABLE FOR ANY LOSSES RELATING THERETO. SWAPSCANNER DOES NOT REPRESENT OR
          WARRANT THAT THIS WEBSITE WILL MEET YOUR NEEDS, OR THAT THE WEBSITE, THE SERVICES OR ANY
          MATERIALS OF SWAPSCANNER ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, OR FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS.
        </p>

        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR AFFILIATES AND THEIR
          RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
          REPRESENTATIVES, SUPPLIERS OR CONTRACTORS EXPRESSLY DISCLAIM ANY LIABILITY, WHETHER IN
          CONTRACT, TORT, STRICT LIABILITY OF OTHERWISE FOR ANY DIRECT, INDIRECT, SPECIAL,
          INCIDENTAL, INTANGIBLE OR CONSEQUENTIAL LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO:
        </p>

        <p>
          (a)ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR
          OTHER ITEM PROVIDED BY OR ON BEHALF OF SWAPSCANNER OR ITS AFFILIATES;
        </p>
        <p>
          (b)ANY AUTHORISED OR UNAUTHORISED USE OF THE WEBSITE OR SERVICES, OR IN CONNECTION WITH
          THIS AGREEMENT;
        </p>
        <p>(c)ANY INACCURACY, DEFECT OR OMISSION OF ANY DATA OR INFORMATION ON THE WEBSITE;</p>
        <p>(d)ANY ERROR, DELAY OR INTERRUPTION IN THE TRANSMISSION OF SUCH DATA;</p>
        <p>
          (e)ANY DAMAGES INCURRED BY ANY ACTIONS, OMISSIONS OR VIOLATIONS OF THESE TERMS BY ANY
          THIRD PARTIES; OR
        </p>
        <p>
          (f)ANY DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR ACTIONS WITHOUT
          AUTHORISED BY SWAPSCANNER.
        </p>

        <p>
          EVEN IF SWAPSCANNER KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES AND
          NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE, EXCEPT
          TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF OUR
          GROSS NEGLIGENCE, ACTUAL FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW OR
          EXCEPT IN JURISDICTIONS THAT DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
          CONSEQUENTIAL DAMAGES. WE WILL NOT BE LIABLE TO ANY PERSON FOR ANY LOSS RESULTING FROM A
          CAUSE OVER WHICH WE DO NOT HAVE CONTROL. THIS PROVISION WILL SURVIVE THE TERMINATION OF
          THESE TERMS.
        </p>

        <p>
          WE MAKE NO WARRANTY AS TO THE MERIT, LEGALITY OR JURIDICAL NATURE OF ANY TOKEN SOLD ON OUR
          PLATFORM (INCLUDING WHETHER OR NOT IT IS CONSIDERED A SECURITY OR FINANCIAL INSTRUMENT
          UNDER ANY APPLICABLE SECURITIES LAWS).
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">9. Intellectual Property</h3>

        <p>
          All present and future copyright, title, interests in and to the Services, registered and
          unregistered trademarks, design rights, unregistered designs, database rights and all
          other present and future intellectual property rights and rights in the nature of
          intellectual property rights that exist in or in relation to the use and access of the
          Website and the Services are owned by or otherwise licensed to Swapscanner. Subject to
          your compliance with these Terms, we grant you a non-exclusive, non-sub license, and any
          limited license to merely use or access the Website and the Services in the permitted
          hereunder.
        </p>
        <p>
          Except as expressly stated in these Terms, nothing in these Terms should be construed as
          conferring any right in or license to our or any other third party’s intellectual rights.
        </p>
        <p>
          If and to the extent that any such intellectual property rights are vested in you by
          operation of law or otherwise, you agree to do any and all such acts and execute any and
          all such documents as we may reasonably request in order to assign such intellectual
          property rights back to us.
        </p>
        <p>
          You agree and acknowledge that all content on the Website must not be copied or
          reproduced, modified, redistributed, used, created for derivative works, or otherwise
          dealt with for any other reason without being granted a written consent from us.
        </p>
        <p>
          Third parties participating on the Website may permit us to utilise trademarks,
          copyrighted material, and other intellectual property associated with their businesses. We
          will not warrant or represent that the content of the Website does not infringe the rights
          of any third party.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">10. Independent Parties</h3>

        <p>
          Swapscanner is an independent contractor but not an agent of you in the performance of
          these Terms. These Terms shall not be interpreted as facts or evidence of an association,
          joint venture, partnership or franchise between the parties.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">11. No Professional Advice or Advertisement</h3>

        <p>
          All information provided on the Website and throughout our Services is for informational
          purposes only and should not be construed as professional advice. We do not provide
          investment advice or investment recommendations and no communication, through the Website
          or in any other medium, should not be considered as a substitute for tailored investment
          advice or construed as advice or recommendation.
        </p>
        <p>
          The information and descriptions contained in the Website are not to be construed as an
          offering memorandum, advertisement or prospectus. Accordingly, this information is not
          intended to be a complete description of all terms, exclusions and conditions applicable
          to the Services described in this Website. This Website and any information or materials
          contained in it do not constitute the distribution, an offer or solicitation of any kind
          to purchase or sell any product, security or instrument whatsoever nor should they be
          construed as providing any type of investment or other advice or recommendations by us,
          any of our affiliates or third parties to any person in any jurisdiction where such
          distribution, offer, solicitation, purchase or sale would be unlawful under the laws of
          such jurisdiction. Moreover, we do not give investment advice, endorsement, analysis or
          recommendations with respect to any cryptocurrencies, digital assets, tokens or securities
          or provide any financial, tax, legal advice or consultancy services of any kind. We are
          not your broker, intermediary, agent, or advisor and has no fiduciary relationship or
          obligation to you in connection with any trades or other decisions or activities effected
          by you using this Website.
        </p>
        <p>
          Investing in digital assets is highly risky and may lead to a total loss of investment.
          You must have sufficient understanding of cryptographic tokens, token storage mechanisms
          (such as token wallets), and blockchain technology to appreciate the risks involved in
          dealing in digital assets. You understand and agree that the value of digital assets can
          be volatile, and we are not in any way responsible or liable for any losses you may incur
          by using or transferring digital assets in connection with our Services. You should not
          take, or refrain from taking, any action based on any information contained on the
          Website. Before you make any financial, legal, or other decisions involving our Services,
          you should seek independent professional advice from an individual who is licensed and
          qualified in the area for which such advice would be appropriate.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">12. Indemnification</h3>

        <p>
          You agree to indemnify and hold harmless Swapscanner and its affiliates and their
          respective shareholders, members, directors, officers, employees, attorneys, agents,
          representatives, suppliers or contractors from and against any potential or actual claims,
          actions, proceedings, investigations, demands, suits, costs, expenses and damages
          (including attorneys’ fees, fines or penalties imposed by any regulatory authority)
          arising out of or related to:
        </p>
        <p>(a)your use of, or conduct in connection with, the Website or Services;</p>
        <p>(b)your breach or our enforcement of these Terms; or</p>
        <p>
          (c)your violation of any applicable law, regulation, or rights of any third party during
          your use of the Website or Services.
        </p>
        <p>
          If you are obligated to indemnify Swapscanner and its affiliates and their respective
          shareholders, members, directors, officers, employees, attorneys, agents, representatives,
          suppliers or contractors pursuant to these Terms, Swapscanner will have the right, in its
          sole discretion, to control any action or proceeding and to determine whether Swapscanner
          wishes to settle, and if so, on what terms.
        </p>
        <p>
          Your obligations under this indemnification provision will continue even after these Terms
          have expired or been terminated.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">13. Taxes</h3>

        <p>
          As between us, you will be solely responsible to pay any and all sales, use, value-added
          and other taxes, duties, and assessments (except taxes on our net income) now or hereafter
          claimed or imposed by any governmental authority (collectively, &ldquo;<b>Taxes</b>
          &rdquo;) associated with your use of the Services. Except for income taxes levied on the
          Company, you: (i) will pay or reimburse us for all national, federal, state, local, or
          other taxes and assessments of any jurisdiction, including value-added taxes and taxes as
          required by international tax treaties, customs or other import or export taxes, and
          amounts levied in lieu thereof based on charges set, services performed or payments made
          hereunder, as are now or hereafter may be imposed under the authority of any national,
          state, local or any other taxing jurisdiction; and (ii) shall not be entitled to deduct
          the amount of any such taxes, duties or assessments from payments made to us pursuant to
          these Terms.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">14. Confidentiality</h3>

        <p>
          You acknowledge that the Services contain Swapscanner’s and its affiliates’ trade secrets
          and confidential information. You agree to hold and maintain the Services in confidence,
          and not to furnish any other person any confidential information of the Services or the
          Website. You agree to use a reasonable degree of care to protect the confidentiality of
          the Services. You will not remove or alter any of Swapscanner’s or its affiliates’
          proprietary notices. Your obligations under this provision will continue even after these
          Terms have expired or been terminated.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">15. Anti-Money Laundering</h3>

        <p>
          Swapscanner expressly prohibits and rejects the use of the Website or the Services for any
          form of illicit activity, including money laundering, terrorist financing or trade
          sanctions violations. By using the Website or the Services, you represent that you are not
          involved in any such activity.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">16. Force Majeure</h3>

        <p>
          Swapscanner shall have no liability to you if it is prevented from or delayed in
          performing its obligations or from carrying on its Services and business, by acts, events,
          omissions or accidents beyond its reasonable control, including, without limitation,
          strikes, failure of a utility service or telecommunications network, act of God, war,
          riot, civil commotion, malicious damage, compliance with any law or governmental order,
          rule, regulation, or direction.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">17. Jurisdiction and Governing Law</h3>

        <p>
          The parties shall attempt in good faith to mutually resolve any and all disputes, whether
          of law or fact, and of any nature whatsoever arising from or with respect to these Terms.
          These Terms and any dispute or claim arising out of or in connection with the Services or
          the Website shall be governed by, and construed in accordance with, the laws of the
          Republic of Panama.
        </p>
        <p>
          Any dispute that is not resolved after good faith negotiations may be referred by either
          party for final, binding resolution by arbitration under the arbitration rules of the
          Conciliation and Arbitration Center of Panama (&ldquo;<b>CACP</b>&rdquo;) under the CACP
          arbitration rules in force when the notice of arbitration is submitted. The law of this
          arbitration clause shall be the laws of the Republic of Panama. The seat of arbitration
          shall be the Republic of Panama. The number of arbitrators shall be one (1). The
          arbitration proceedings shall be conducted in English.
        </p>
        <p>
          Any Dispute arising out of or related to these Terms is personal to you and us and will be
          resolved solely through individual arbitration and will not be brought as a class
          arbitration, class action or any other type of representative proceeding. There will be no
          class arbitration or arbitration in which an individual attempts to resolve a dispute as a
          representative of another individual or group of individuals. Further, a dispute cannot be
          brought as a class or other type of representative action, whether within or outside of
          arbitration, or on behalf of any other individual or group of individuals.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">18. Severability</h3>

        <p>
          If any provision of these Terms is determined by any court or other competent authority to
          be unlawful or unenforceable, the other provisions of these Terms will continue in effect.
        </p>
        <p>
          If any unlawful or unenforceable provision would be lawful or enforceable if part of it
          were deleted, that part will be deemed to be deleted, and the rest of the provision will
          continue in effect (unless that would contradict the clear intention of the clause, in
          which case the entirety of the relevant provision will be deemed to be deleted).
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">19. Notices</h3>

        <p>
          All notices, requests, demands, and determinations for us under these Terms (other than
          routine operational communications) shall be sent to contact@swapscanner.io.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">20. Assignment</h3>

        <p>
          You may not assign or transfer any right to use the Services or any of your rights or
          obligations under these Terms without prior written consent from Swapscanner, including
          any right or obligation related to the enforcement of laws or the change of control.
          Swapscanner may assign or transfer any or all of its rights or obligations under these
          Terms, in whole or in part, without notice or obtaining your consent or approval.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">21. Third Party Rights</h3>

        <p>No third party shall have any rights to enforce any terms contained herein.</p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">22. Third Party Website Disclaimer</h3>

        <p>
          Any links to third party websites from our Services does not imply endorsement by us of
          any product, service, information or disclaimer presented therein, nor do we guarantee the
          accuracy of the information contained on them. If you suffer loss from using such third
          party product and service, we will not be liable for such loss. In addition, since we have
          no control over the terms of use or privacy policies of third-party websites, you should
          carefully read and understand those policies.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">23. Risks</h3>

        <p>
          You should conduct your own due diligence of any issuer or cryptocurrencies and consult
          your advisors prior to making any investment decision. You are recommended to exercise
          prudence and trade and invest responsibly within your own capabilities. You are solely
          responsible for determining whether any investment, investment strategy or related
          transaction is appropriate for you according to your personal investment objectives,
          financial circumstances and risk tolerance, and you shall be solely responsible for any
          loss or liability therefrom. You should consult legal or tax professionals regarding your
          specific situation.
        </p>

        <p>
          We do not recommend that any cryptocurrencies should be bought, earned, sold, or held by
          you and we will not be held responsible for the decisions you make based on the
          information provided by us on this Website.
        </p>

        <p className="font-bold">
          BY MAKING USE OF OUR SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (A) YOU ARE AWARE OF THE
          RISKS ASSOCIATED WITH TRANSACTIONS OF ENCRYPTED OR DIGITAL TOKENS OR CRYPTOCURRENCIES WITH
          A CERTAIN VALUE THAT ARE BASED ON BLOCKCHAIN AND CRYPTOGRAPHY TECHNOLOGIES AND ARE ISSUED
          AND MANAGED IN A DECENTRALISED FORM (&ldquo;DIGITIAL ASSETS&rdquo;); (B) YOU SHALL ASSUME
          ALL RISKS RELATED TO THE USE OF THE SERVICES AND TRANSACTIONS OF DIGITAL ASSETS; AND (C)
          SWAPSCANNER SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES. AS WITH ANY ASSET,
          THE VALUES OF DIGITAL ASSETS ARE VOLATILE AND MAY FLUCTUATE SIGNIFICANTLY AND THERE IS A
          SUBSTANTIAL RISK OF ECONOMIC LOSS WHEN PURCHASING, HOLDING OR INVESTING IN DIGITAL ASSETS.
        </p>
      </div>
    </div>
  );
}
