// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://d694af2858de43449c4fc923a8047eb7@o1173694.ingest.sentry.io/6269088',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate:
    process.env.ENV === 'production' ? 0.01 : process.env.NODE_ENV === 'development' ? 0 : 1.0,
  environment: process.env.ENV + '-' + process.env.NODE_ENV,
  beforeSend(event, hint) {
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id, labelName: 'Wallet address' });
    }
    return event;
  },
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    // WalletConnect errors
    'No matching key. pairing:',
  ],
  release: process.env.SENTRY_RELEASE,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
