import * as Slider from '@radix-ui/react-slider';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

const SLIDER_DIVERGENCY_NEGATIVE = 1.000000000006615;
const SLIDER_DIVERGENCY_POSITIVE = 1.00000000001;

const min = Math.round(Math.log(1 / 90) / Math.log(SLIDER_DIVERGENCY_NEGATIVE));
const max = Math.round(Math.log(900) / Math.log(SLIDER_DIVERGENCY_POSITIVE));

const toLogScale = (v: number) => {
  if (v === 0) {
    return 0;
  }

  if (v > 0) {
    return Math.log(v) / Math.log(SLIDER_DIVERGENCY_POSITIVE);
  }

  return Math.log(1 / -v) / Math.log(SLIDER_DIVERGENCY_NEGATIVE);
};

const fromLogScale = (v: number) => {
  if (v === 0) {
    return 0;
  }

  if (v > 0) {
    return SLIDER_DIVERGENCY_POSITIVE ** v;
  }

  return -(1 / SLIDER_DIVERGENCY_NEGATIVE ** v);
};

type DCAPriceConditionSliderProps = {
  values: [number | null, number | null];
  setValues: (lower: number, upper: number) => void;
  forceDark?: boolean;
};

export function DCAPriceConditionSliderV2({
  values,
  setValues,
  forceDark,
}: DCAPriceConditionSliderProps) {
  const { t: td } = useTranslation('dca');

  const scaled = useMemo<[number, number]>(() => {
    if (values[0] === null || values[1] === null) {
      return [toLogScale(-50), toLogScale(100)];
    }

    const ordered = (values as [number, number]).slice().sort((a, b) => a - b);

    return ordered.map(toLogScale) as [number, number];
  }, [values]);

  return (
    <div>
      <Slider.Root
        className="relative flex items-center select-none touch-none w-full h-5"
        value={[scaled[0], scaled[1]]}
        min={min}
        max={max}
        onValueChange={(v) => {
          setValues(...(v.map((v) => Math.round(fromLogScale(v))) as [number, number]));
        }}
      >
        <Slider.Track
          className={clsx(
            'relative grow rounded-[4px] h-1.5',
            forceDark ? 'bg-gray-700' : 'bg-gray-200 dark:bg-gray-700',
          )}
        >
          <Slider.Range
            className={clsx(
              'absolute rounded-full h-full',
              forceDark ? 'bg-teal-400' : 'bg-teal-500 dark:bg-teal-400',
            )}
          />
        </Slider.Track>
        <Slider.Thumb
          className={clsx(
            'block w-5 h-5 bg-white border shadow-blackA4 rounded-full',
            forceDark ? 'border-teal-400' : 'border-teal-500 dark:border-teal-400',
          )}
        />
        <Slider.Thumb
          className={clsx(
            'block w-5 h-5 bg-white border shadow-blackA4 rounded-full',
            forceDark ? 'border-teal-400' : 'border-teal-500 dark:border-teal-400',
          )}
        />
        <div
          className={clsx(
            'absolute top-[7px] left-1/2 -translate-x-1/2 w-px h-5',
            forceDark ? 'bg-white' : 'bg-gray-300 dark:bg-white',
          )}
        />
      </Slider.Root>
      <div className="mt-2 flex justify-between items-baseline text-xs font-medium text-gray-400">
        <span>-90%</span>
        <span>{td('Current Rate')}</span>
        <span>+900%</span>
      </div>
    </div>
  );
}
