import { useEffect, useState } from 'react';

export default function useBrowserDocumentVisibility({ initialVisibility = true } = {}) {
  const [visible, setVisible] = useState(initialVisibility);

  /**
   * @see https://davidwalsh.name/page-visibility
   */
  useEffect(() => {
    let hidden: string, visibilityChange: string, state: string;

    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
      state = 'visibilityState';
    } else if (typeof (document as any).mozHidden !== 'undefined') {
      hidden = 'mozHidden';
      visibilityChange = 'mozvisibilitychange';
      state = 'mozVisibilityState';
    } else if (typeof (document as any).msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
      state = 'msVisibilityState';
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
      state = 'webkitVisibilityState';
    } else {
      return;
    }

    const handler = () => setVisible((document as any)[state] !== hidden);

    handler();
    document.addEventListener(visibilityChange, handler, false);

    return () => {
      document.removeEventListener(visibilityChange, handler, false);
    };
  }, []);

  return { visible };
}
