export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const SCNR_ADDRESS = '0x8888888888885b073f3c81258c27e83db228d5f3';
export const JTS_ADDRESS = '0x92f2617ee7d386e3852c1843f61dfea203a0f0d2';
export const TETHER_ADDRESS = '0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167';
export const USDC_ADDRESS = '0x754288077d0ff82af7a5317c7cb8c444d421d103';
export const WEMIX_ADDRESS = '0x5096db80b21ef45230c9e423c373f1fc9c0198dd';
export const XRP_ADDRESS = '0x9eaefb09fe4aabfbe6b1ca316a3c36afc83a393f';
export const KSD_ADDRESS = '0x4fa62f1f404188ce860c8f0041d6ac3765a72e67';
export const KSP_ADDRESS = '0xc6a2ad8cc6e4a7e08fc37cc5954be07d499e7654';
export const MBX_ADDRESS = '0xd068c52d81f4409b9502da926ace3301cc41f623';
export const BTC_ADDRESS = '0x16d0e1fbd024c600ca0380a4c5d57ee7a2ecbf9c';
export const ETH_ADDRESS = '0x34d21b1e550d73cee41151c77f3c73359527a396';
export const ORC_ADDRESS = '0xfe41102f325deaa9f303fdd9484eb5911a7ba557';
export const PALA_ADDRESS = '0x7a1cdca99fe5995ab8e317ede8495c07cbf488ad';
export const BORA_ADDRESS = '0x02cbe46fb8a1f579254a9b485788f2d86cad51aa';
export const GCKLAY_ADDRESS = '0x999999999939ba65abb254339eec0b2a0dac80e9';
export const KCD_ADDRESS = '0x54640a6b9f5a6b0eae043f0a6fd69a4431323356';
export const STKLAY_ADDRESS = '0xf80f2b22932fcec6189b9153aa18662b15cc9c00';
export const KRW_ADDRESS = 'krw';
export const KRW_COINGECKO_ADDRESS = 'krw_coingecko';
export const USD_COINGECKO_ADDRESS = 'usd_coingecko';
export const KRW_BITHUMB_ADDRESS = 'krw_bithumb';

export const WGCKLAY_ADDRESS = '0xa9999999c3d05fb75ce7230e0d22f5625527d583';

export const IMAGINARY_FIAT_ADDRESS_MAP = {
  KRW: KRW_ADDRESS,
  KRW_COINGECKO: KRW_COINGECKO_ADDRESS,
  USD_COINGECKO: USD_COINGECKO_ADDRESS,
  KRW_BITHUMB: KRW_BITHUMB_ADDRESS,
} as const;

export type ImaginaryFiat = keyof typeof IMAGINARY_FIAT_ADDRESS_MAP;
export type ImaginaryFiatAddress =
  | typeof KRW_ADDRESS
  | typeof KRW_COINGECKO_ADDRESS
  | typeof USD_COINGECKO_ADDRESS
  | typeof KRW_BITHUMB_ADDRESS;

export const ENV_ADAPTIVE_SCNR_ADDRESS = ['production', 'next'].includes(
  process.env.ENV || 'development',
)
  ? SCNR_ADDRESS
  : JTS_ADDRESS;

export const oBUSD_ADDRESS = '0x210bc03f49052169d5588a52c317f71cf2078b85';
export const oDAI_ADDRESS = '0x5c74070fdea071359b86082bd9f9b3deaafbe32b';

export const PORTAL_USDC_ADDRESS = '0x608792deb376cce1c9fa4d0e6b7b44f507cffa6a';
export const PORTAL_USDT_ADDRESS = '0x5c13e303a62fc5dedf5b52d66873f2e59fedadc2';
export const PORTAL_DAI_ADDRESS = '0xcb2c7998696ef7a582dfd0aafadcd008d03e791a';

export const SYN_USDC_ADDRESS = '0x6270b58be569a7c0b8f47594f191631ae5b2c86c';
export const SYN_USDT_ADDRESS = '0xd6dab4cff47df175349e6e7ee2bf7c40bb8c05a3';
export const SYN_DAI_ADDRESS = '0x078db7827a5531359f6cb63f62cfa20183c4f10c';

export const STABLE_TOKENS = new Set([
  TETHER_ADDRESS,
  USDC_ADDRESS,
  KSD_ADDRESS,
  oBUSD_ADDRESS,
  oDAI_ADDRESS,
  PORTAL_USDC_ADDRESS,
  PORTAL_USDT_ADDRESS,
  PORTAL_DAI_ADDRESS,
  SYN_USDC_ADDRESS,
  SYN_USDT_ADDRESS,
  SYN_DAI_ADDRESS,
  '0x54640a6b9f5a6b0eae043f0a6fd69a4431323356', // KCD,
  '0x7d8142a696ddbb8eaaa937696c35772190b234cb', // oUSDT-B
  '0xee9521d806e55c8a399d7741106395f9afa61e63', // iUSDT
]);

export const defaultFromAddress = ZERO_ADDRESS;
export const defaultToAddress = ['production', 'next'].includes(process.env.ENV || 'development')
  ? SCNR_ADDRESS
  : PORTAL_USDT_ADDRESS;
