import { Icon } from '@iconify/react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import TokenAmountV2 from '@/components/core/TokenAmountV2';
import NumberFormat from '@/components/NumberFormat';
import { Skeleton } from '@/components/Skeleton';
import TokenIconV2 from '@/components/TokenIconV2';
import TokenSymbol from '@/components/TokenSymbol';
import { InfoIcon } from '@/components/vector';
import { useCurrencyContext } from '@/context/currency';
import { useDialog } from '@/context/dialog';
import { useWalletContext } from '@/context/wallet';
import { ZERO_ADDRESS } from '@/defines/token-address';
import useForex from '@/hooks/use-forex';
import usePriceV1 from '@/hooks/use-price-v1';
import useTokens from '@/hooks/use-tokens';

import type { Token } from '@/lib/tokens';

type Props = {
  address: string | null;
  balance: number | null;
  token?: Token;
  label?: string;
  tooltipTitle?: ReactNode;
  price?: number;
  value: string;
  hidePrice?: boolean;
  hideBalance?: boolean;
  onValueChange?: (value: string) => void;
  onTokenAddressChange?: (address: string) => void;
  disabled?: boolean;
  disableAddressChange?: boolean;
  readOnly?: boolean;
  labelEndAdornment?: ReactNode;
  forceDark?: boolean;
  placeholder?: string;
  useValueInput?: boolean;
  inputId?: string;
};

export default function TokenAmountInputV2({
  address,
  balance,
  token,
  label,
  tooltipTitle = undefined,
  price = undefined,
  value: propsValue,
  hidePrice = false,
  hideBalance = false,
  onValueChange = undefined,
  onTokenAddressChange = undefined,
  disabled = false,
  disableAddressChange = false,
  readOnly = false,
  labelEndAdornment,
  forceDark = false,
  placeholder = undefined,
  useValueInput = false,
  inputId,
}: Props) {
  const { t } = useTranslation('common');
  const { usdToReadableCurrency, usdToValue } = useForex();
  const { currencyType, rate } = useCurrencyContext();
  const { wallet } = useWalletContext();
  const { tokenAddressToToken } = useTokens();
  const [value, setValue] = useState(propsValue);

  const { openTokenSelector } = useDialog();

  const { price: usdcPrice } = usePriceV1({ tokenAddress: address ?? undefined });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/,/g, '');
    setValue(value);
    onValueChange && onValueChange(value);
  };

  const handleValueInputChange = (value: string) => {
    setValue(value);
    onValueChange && onValueChange(value);
  };

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  const valueUSDC = useMemo(() => {
    if (price !== undefined) {
      return price;
    } else {
      return +value * (usdcPrice ?? 0);
    }
  }, [price, usdcPrice, value]);

  const tokenDecimals = useMemo(
    () =>
      address === ZERO_ADDRESS
        ? wallet?.type === 'klip'
          ? 6
          : 18
        : +tokenAddressToToken?.[address ?? '']?.decimals || 18,
    [address, tokenAddressToToken, wallet],
  );

  return (
    <div>
      {label && (
        <div className="mb-2 flex justify-between items-center">
          <span
            className={clsx(
              'text-md leading-5 flex items-center',
              forceDark ? 'text-white' : 'text-gray-700 dark:text-white',
            )}
          >
            {label}
            {!!tooltipTitle && (
              <>
                <div data-tooltip-id="you-receive-tooltip" className="ml-1 p-1">
                  <InfoIcon className="h-4 w-4 text-gray-500" />
                </div>
                <Tooltip
                  className="ss-tooltip-base"
                  opacity={1}
                  id="you-receive-tooltip"
                  render={() => tooltipTitle}
                />
              </>
            )}
          </span>
          {labelEndAdornment}
        </div>
      )}

      <div
        className={clsx(
          'p-4 rounded-lg bg-gray-50 dark:bg-gray-800 space-y-2',
          forceDark ? 'bg-gray-800' : 'bg-gray-50 dark:bg-gray-800',
        )}
      >
        <div className="flex items-center">
          {disableAddressChange ? (
            // Array.isArray(address) ? (
            //   <div className="flex">
            //     {address.map((a) => (
            //       <TokenIconV2 className="h-6 w-6" address={a} key={a} width={24} height={24} />
            //     ))}
            //   </div>
            // ) : (
            <TokenIconV2 className="h-6 w-6" address={address} width={24} height={24} />
          ) : (
            <button
              className={clsx(
                'p-2 flex items-center rounded-[100px] border min-w-max',
                forceDark
                  ? 'border-0 bg-gray-900'
                  : 'border-gray-200 bg-white dark:border-0 dark:bg-gray-900',
              )}
              onClick={() =>
                openTokenSelector({
                  label: label || '',
                  onSelectToken: ({ address }) => {
                    onTokenAddressChange && onTokenAddressChange(address);
                  },
                  withImaginaryFiats: false,
                  forceDark,
                })
              }
            >
              <TokenIconV2 className="h-7 w-7" address={address} width={28} height={28} />
              <span
                className={clsx(
                  'ml-1.5 mr-1 font-medium text-sm',
                  forceDark ? 'text-white' : 'text-gray-900 dark:text-white',
                )}
              >
                <TokenSymbol address={address} />
              </span>
              <Icon className="text-gray-400 text-xs" icon="bi:caret-down-fill" />
            </button>
          )}

          <NumberFormat
            // @ts-ignore
            className="p-0 bg-transparent w-full text-[28px] font-medium tracking-tight text-right border-none pl-5 placeholder:text-gray-300 dark:placeholder:text-gray-600 focus:ring-0"
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            decimalScale={tokenDecimals}
            thousandSeparator=","
            allowNegative={false}
            id={inputId}
          />
        </div>
        <div className="w-full flex justify-between items-center">
          {!hideBalance ? (
            <span className="flex align-baseline">
              <span className="text-sm text-gray-500 font-medium">{t('Balance')}:</span>
              <span className="ml-1 text-sm text-gray-400">
                <TokenAmountV2
                  icon={false}
                  address={token?.address || null}
                  amount={balance || 0}
                  colorClassNameOverride="text-gray-400"
                  forceDark
                />
              </span>
            </span>
          ) : (
            // NOTE: this is to prevent shift when only price is shown
            <></>
          )}

          {useValueInput ? (
            <div className="flex items-center">
              <span
                className={clsx('mr-1 select-none text-gray-500', {
                  hidden: currencyType !== 'USD',
                })}
              >
                $
              </span>
              <NumberFormat
                // @ts-ignore
                className={clsx(
                  'p-0 bg-transparent text-base font-medium tracking-tight text-right border-none leading-tight focus:ring-0',
                  {
                    'w-24': currencyType === 'USD',
                    'w-24 sm:w-48': currencyType === 'KRW',
                  },
                )}
                thousandSeparator=","
                allowNegative={false}
                maxLength={16}
                decimalScale={currencyType === 'USD' ? 2 : 0}
                placeholder="0"
                value={valueUSDC > 0 ? usdToValue(valueUSDC) : ''}
                onChange={(e: any) => {
                  if (!usdcPrice) return;
                  const value = e.target.value.replace(/,/g, '');
                  const amount = +value / (usdcPrice * rate);

                  handleValueInputChange(
                    amount.toLocaleString(undefined, {
                      maximumSignificantDigits: Math.min(tokenDecimals, 18),
                    }),
                  );
                }}
              />
              <span
                className={clsx('ml-1 select-none text-gray-500', {
                  hidden: currencyType !== 'KRW',
                })}
              >
                원
              </span>
            </div>
          ) : !hidePrice ? (
            <span className="text-sm text-gray-400">
              {typeof valueUSDC !== 'number' ? (
                <Skeleton width={100} />
              ) : (
                t('~{{amount}}', {
                  amount: usdToReadableCurrency(valueUSDC),
                })
              )}
            </span>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
}
