import clsx from 'clsx';

interface Props {
  className?: string;
  percent: number;
  baseColor?: string;
}

export default function CircularProgressIcon({ className, percent, baseColor }: Props) {
  return (
    <svg
      className={clsx(className, 'transform -rotate-90')}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="10.5"
        cx="12"
        cy="12"
        fill="transparent"
        stroke={baseColor ?? 'transparent'}
        strokeWidth="2.4px"
      />
      <circle
        r="10.5"
        cx="12"
        cy="12"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="2.4px"
        strokeLinecap="round"
        strokeDasharray={`${21 * Math.PI}px`}
        strokeDashoffset={`${(21 * Math.PI * (100 - percent)) / 100}px`}
      />
    </svg>
  );
}
