import { Menu } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { SwitchVerticalIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';

import { MenuTransition } from '@/components/transition';

const tokenSortOptions = ['balances-dsc', 'volume-dsc', 'tvl-dsc'] as const;
export type SortOption = (typeof tokenSortOptions)[number];

interface SortMenuButtonProps {
  forceDark?: boolean;
  sortOption: SortOption;
  onSortChange: (newSortOption: SortOption) => void;
}

export default function SortMenuButton({
  forceDark = false,
  sortOption,
  onSortChange,
}: SortMenuButtonProps) {
  const { t: ts } = useTranslation('swap');

  const Icon = SwitchVerticalIcon;

  return (
    <Menu as="div" className="z-20 relative inline-flex text-left">
      {({ open }) => (
        <>
          <Menu.Button
            data-tooltip-id="global-tooltip"
            data-tooltip-content={ts('sort')}
            data-tooltip-hidden={open}
            className={clsx(
              'rounded-md ml-4',
              forceDark
                ? 'text-slate-300 hover:text-slate-400'
                : 'dark:text-slate-300 dark:hover:text-slate-400 text-slate-600 hover:text-slate-500',
            )}
          >
            <Icon className="h-6 w-6" />
          </Menu.Button>

          <MenuTransition>
            <Menu.Items
              className={clsx(
                'absolute top-full translate-y-2 right-0 mt-2 origin-top-right',
                'rounded-md shadow-xl ring-1 focus-visible:outline-none ring-slate-50/5',
              )}
            >
              <div
                className={clsx(
                  'rounded-md divide-y min-w-max',
                  forceDark
                    ? 'divide-slate-500 bg-dark-card-color'
                    : 'divide-slate-300 bg-slate-50 dark:divide-slate-500 dark:bg-dark-card-color',
                )}
              >
                {tokenSortOptions.map((option) => (
                  <Menu.Item key={option} as={Fragment}>
                    {({ active }) => (
                      <button
                        className={clsx(
                          'px-3 py-2 text-sm w-full flex items-center space-x-2',
                          forceDark
                            ? {
                                'text-slate-300': active && option !== sortOption,
                                'text-slate-400': !active,
                                'text-slate-100 font-medium': option === sortOption,
                              }
                            : {
                                'text-slate-600 dark:text-slate-300':
                                  active && option !== sortOption,
                                'text-slate-500 dark:text-slate-400': !active,
                                'text-slate-800 dark:text-slate-100 font-medium':
                                  option === sortOption,
                              },
                        )}
                        onClick={() => onSortChange(option)}
                      >
                        <CheckIcon
                          className={clsx(
                            'h-5 w-5',
                            forceDark ? 'text-slate-400' : 'text-slate-500 dark:text-slate-400',
                            {
                              invisible: option !== sortOption,
                            },
                          )}
                        />
                        <span className="flex-1 text-left pr-2">{ts(option)}</span>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </MenuTransition>
        </>
      )}
    </Menu>
  );
}
