interface Props {
  className?: string;
}

export default function EcosystemMenuIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4571 3.6305C17.4482 3.4776 17.3834 3.3333 17.2751 3.22499C17.1668 3.11669 17.0225 3.05193 16.8696 3.043C10.922 2.69378 6.15789 4.4844 4.12664 7.84378C3.42255 8.99293 3.07559 10.3253 3.12977 11.6719C3.16513 12.533 3.34033 13.3827 3.64852 14.1875C3.66663 14.2371 3.69698 14.2812 3.73674 14.3159C3.77651 14.3505 3.82439 14.3745 3.87594 14.3857C3.92749 14.3968 3.98102 14.3947 4.03155 14.3796C4.08208 14.3645 4.12796 14.3369 4.16492 14.2992L10.8055 7.55706C10.8636 7.49899 10.9326 7.45293 11.0084 7.4215C11.0843 7.39008 11.1656 7.3739 11.2477 7.3739C11.3299 7.3739 11.4112 7.39008 11.487 7.4215C11.5629 7.45293 11.6319 7.49899 11.6899 7.55706C11.748 7.61513 11.7941 7.68407 11.8255 7.75994C11.8569 7.83581 11.8731 7.91713 11.8731 7.99925C11.8731 8.08137 11.8569 8.16269 11.8255 8.23856C11.7941 8.31443 11.748 8.38337 11.6899 8.44144L4.43289 15.8078L3.3243 16.9164C3.20901 17.0287 3.14018 17.1802 3.13149 17.3409C3.12281 17.5015 3.1749 17.6596 3.27742 17.7836C3.33357 17.8486 3.40252 17.9014 3.47996 17.9386C3.5574 17.9757 3.64168 17.9966 3.72753 17.9997C3.81338 18.0029 3.89895 17.9883 3.97891 17.9569C4.05888 17.9255 4.1315 17.8779 4.19227 17.8172L5.50399 16.5055C6.60867 17.0399 7.72352 17.3313 8.82899 17.3703C8.91597 17.3735 9.00268 17.375 9.08914 17.375C10.3481 17.3783 11.5831 17.0315 12.6563 16.3735C16.0157 14.3422 17.8071 9.57894 17.4571 3.6305Z"
        fill="currentColor"
      />
    </svg>
  );
}
