interface Props {
  className?: string;
  width?: number;
  height?: number;
  opcaity?: number;
}

export default function StarIcon({ className, width, height, opcaity = 1 }: Props) {
  return (
    <svg
      className={className}
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      fillOpacity={opcaity}
      stroke={opcaity === 0 ? 'currentColor' : 'none'}
      strokeWidth={opcaity === 0 ? '1.5' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0096 3.83347C11.3754 3.08664 12.4399 3.08664 12.8058 3.83347L14.7424 7.78727C14.8873 8.08318 15.1687 8.28869 15.4947 8.33671L19.8398 8.97673C20.6573 9.09715 20.9843 10.1009 20.3946 10.6797L17.2423 13.7739C17.0086 14.0032 16.902 14.3325 16.9569 14.6553L17.6993 19.0174C17.8385 19.8354 16.9784 20.4573 16.2452 20.0688L12.376 18.0182C12.0831 17.8629 11.7323 17.8629 11.4394 18.0182L7.57016 20.0688C6.83699 20.4573 5.97685 19.8354 6.11606 19.0174L6.85846 14.6553C6.9134 14.3325 6.80681 14.0032 6.57313 13.7739L3.42083 10.6797C2.83111 10.1009 3.15809 9.09715 3.9756 8.97673L8.32069 8.33671C8.64667 8.28869 8.92808 8.08317 9.07302 7.78726L11.0096 3.83347Z"
        fill="currentColor"
      />
    </svg>
  );
}
