import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { Skeleton } from '@/components/Skeleton';

type TokenIconProps = {
  address?: string | null;
  className?: string;
  symbol?: string;
  width: number;
  height: number;
};

export default function TokenIconV2({ address, className, symbol, width, height }: TokenIconProps) {
  // TODO: Will be removed after fixing the underlying issue of icon image not re-rendering
  // force the component to render
  const [tokenAddress, setTokenAddress] = useState<string | null>(null);

  useEffect(() => {
    if (!address) return;
    setTokenAddress(address);
  }, [address]);

  if (!tokenAddress) {
    return <Skeleton className={clsx(className, 'rounded-full')} width={width} height={height} />;
  }

  return (
    <img
      className={clsx(className, 'rounded-full')}
      width={width}
      height={height}
      style={{ borderRadius: '50%', minWidth: `${width}px` }}
      src={`${process.env.API_HOST}/tokens/${tokenAddress}/icon`}
      alt={`${symbol ?? tokenAddress} token icon`}
      draggable={false}
    />
  );
}
