import clsx from 'clsx';

import { Skeleton } from '@/components/Skeleton';
import TokenIconV2 from '@/components/TokenIconV2';
import TokenSymbol from '@/components/TokenSymbol';

import type { ReactNode } from 'react';

interface TokenAmountProps {
  forceDark?: boolean;
  address: string | null;
  amount: number | string;
  icon?: boolean;
  disableGutter?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  prefix?: ReactNode;
  suffix?: ReactNode;
  colorClassNameOverride?: string;
}

export default function TokenAmountV2({
  forceDark = false,
  address,
  amount,
  icon = true,
  disableGutter = false,
  minimumFractionDigits = 6,
  maximumFractionDigits = minimumFractionDigits,
  prefix = <></>,
  suffix = <></>,
  colorClassNameOverride,
}: TokenAmountProps) {
  return (
    <span className="flex items-center space-x-1">
      {icon && (
        <TokenIconV2
          address={address}
          width={14}
          height={14}
          className={clsx({
            'mr-1': !disableGutter,
          })}
        />
      )}
      <div className="flex items-center space-x-1">
        {prefix}
        <span
          className={clsx(
            'font-medium',
            colorClassNameOverride
              ? colorClassNameOverride
              : forceDark
              ? 'text-slate-200'
              : 'text-slate-700 dark:text-slate-200',
          )}
        >
          {typeof amount === 'string' ? (
            amount
          ) : typeof amount !== 'number' || isNaN(amount) ? (
            <Skeleton width={50} />
          ) : (
            amount.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits })
          )}
        </span>
        <span
          className={clsx(
            'text-sm font-semibold',
            forceDark ? 'text-slate-400' : 'text-slate-500 dark:text-slate-400',
          )}
        >
          <TokenSymbol address={address} />
        </span>
        {suffix}
      </div>
    </span>
  );
}
