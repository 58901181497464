import { Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDialog } from '@/context/dialog';
import { useWalletContext } from '@/context/wallet';
import { ApproveABI } from '@/defines/consts';
import { ENV_ADAPTIVE_SCNR_ADDRESS as SCNR_ADDRESS, ZERO_ADDRESS } from '@/defines/token-address';
import useBalancesV1 from '@/hooks/use-balances-v1';
import { useChatProfileStats } from '@/hooks/use-chat-profile-stats';
import { TransactionSpec, useWeb3Transaction } from '@/lib/useWeb3Transaction';

import type { ChatProfile } from '@/components/pro-chat/types';

type NicknameValid = {
  isValid: boolean;
  validErrorMsg: string;
};

const SCNR_DECIMALS = 25;
const DEFAULT_NICKNAME_CHANGE_COST = 2.8 * 10 ** 25;

const formatAmount = (amount: BigInt): string =>
  (Number(amount) / 10 ** SCNR_DECIMALS).toLocaleString();

const updateNicknameInRedis = async ({
  channelName,
  accessToken,
  address,
}: {
  channelName: string;
  accessToken?: string | null;
  address?: string;
}) => {
  try {
    const res = await fetch(`${process.env.API_HOST}/chat/nickname`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        channelName,
        accessToken,
        address,
      }),
    });

    const { success } = await res.json();
    return success;
  } catch (e) {
    return false;
  }
};

const validateNickname = async (nickname: string) => {
  try {
    const res = await (
      await fetch(
        `${process.env.API_HOST}/chat/validate-nickname?proposedNickname=${encodeURIComponent(
          nickname,
        )}`,
      )
    ).json();

    if (res.error) {
      let errorMsg = 'Invalid nickname format';

      switch (res.error) {
        case 'execution reverted: duplicate_nickname':
          errorMsg = 'Nickname already taken';
          break;
      }

      return {
        isValid: false,
        errorMsg,
      };
    }

    return {
      isValid: true,
      errorMsg: '',
    };
  } catch (err) {
    throw err;
  }
};

export type ChatNicknameEditDialogProps = {
  open: boolean;
  onClose: () => void;
  chatProfile: ChatProfile | null;
  accessToken?: string | null;
  forceDark?: boolean;
  onNicknameChange: (account: string, nickname: string) => void;
  onClickClose: () => void;
};

export const ChatNicknameEditDialog: React.FC<ChatNicknameEditDialogProps> = ({
  open,
  chatProfile,
  accessToken,
  forceDark = false,
  onNicknameChange,
  onClickClose,
  ...params
}) => {
  const router = useRouter();
  const { resetAllChatRelatedDialogs } = useDialog();

  const { t } = useTranslation('chat');
  const { t: tc } = useTranslation('common');

  const { data: stats, mutate } = useChatProfileStats({ skip: !open });
  const { balances, mutate: mutateBalances } = useBalancesV1({
    skip: !open,
    account: chatProfile?.address ?? null,
  });

  const { wallet } = useWalletContext();

  const defaultNickname = useMemo(() => {
    if (!open) {
      return '';
    }
    if (stats?.profile.nickname) {
      return stats.profile.nickname;
    }
    const guestPrefix = t('Guest');
    if (!wallet?.account) {
      return guestPrefix;
    }
    return `${guestPrefix} ${wallet.account.slice(2, 6)}`;
  }, [open, stats, t, wallet]);

  useEffect(() => {
    if (!!defaultNickname) {
      setNicknameDraft(defaultNickname);
    }
  }, [defaultNickname]);

  const contract = stats?.contract;
  const allowance = useMemo(() => BigInt(contract?.allowance || 0), [contract?.allowance]);

  const changeFee = useMemo(
    () => BigInt(contract?.nicknameChangeCost || DEFAULT_NICKNAME_CHANGE_COST),
    [contract],
  );

  const isApprovalNeeded = useMemo(
    () => !!stats?.profile.nickname && changeFee > allowance,
    [allowance, changeFee, stats?.profile.nickname],
  );

  const [nicknameDraft, setNicknameDraft] = useState<string>('');
  const [txSpec, setTXSpec] = useState<Partial<TransactionSpec> | undefined>(undefined);

  useEffect(() => {
    if (!open) {
      return;
    }

    let txSpec: Partial<TransactionSpec> = {
      from: wallet?.account ?? undefined,
      value: '0',
    };

    if (isApprovalNeeded) {
      txSpec = {
        ...txSpec,
        to: SCNR_ADDRESS,
        abi: ApproveABI,
        params: [contract?.address, changeFee.toString()],
        name: `Approve SCNR`,
      };
    } else {
      txSpec = {
        ...txSpec,
        to: contract?.address,
        abi: !stats?.profile.nickname
          ? contract?.abi.setInitialNickname
          : contract?.abi.changeNickname,
        params: [nicknameDraft],
        name: `Change Nickname`,
      };
    }

    setTXSpec(txSpec);
  }, [
    open,
    isApprovalNeeded,
    contract,
    wallet?.account,
    stats?.profile.nickname,
    nicknameDraft,
    changeFee,
  ]);

  const [sentApproval, setSentApproval] = useState<boolean>(false);
  const [isEditComplete, setEditComplete] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setEditComplete(false);
    }
  }, [open]);

  const onClose = useCallback(
    (close: boolean = false) => {
      if (!close && isEditComplete) {
        setEditComplete(false);
        return;
      }

      setTXSpec(undefined);
      params.onClose();
      setNicknameDraft('');

      setSentApproval(false);
      setEditComplete(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isEditComplete, params],
  );

  const afterSend = useCallback(async () => {
    if (txSpec?.name === 'Change Nickname') {
      const success = !accessToken
        ? false
        : await updateNicknameInRedis({
            channelName: 'general',
            accessToken,
            address: wallet?.account ?? undefined,
          });

      mutate();

      if (success || !accessToken) {
        onNicknameChange(chatProfile?.address ?? '', nicknameDraft);
        mutateBalances();
        onClose(true);
      }
    }

    if (txSpec?.name === 'Approve SCNR') {
      setSentApproval(false);
      mutate();
    }
  }, [
    mutate,
    txSpec,
    onClose,
    chatProfile?.address,
    nicknameDraft,
    onNicknameChange,
    wallet?.account,
    accessToken,
    mutateBalances,
  ]);

  const { send, inFlight } = useWeb3Transaction({
    tx: txSpec as TransactionSpec,
    and: { then: afterSend },
    wait: true,
  });
  const transactionLoading = (!!sentApproval && !isApprovalNeeded) || inFlight;

  const scnrBalance = useMemo(() => BigInt(balances?.[SCNR_ADDRESS] ?? 0), [balances]);
  const isInsufficientBalanceWhenBurnRequired = useMemo(() => {
    return scnrBalance < changeFee && !!stats?.profile.nickname;
  }, [scnrBalance, changeFee, stats?.profile.nickname]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [nicknameValid, setNicknameValid] = useState<NicknameValid>({
    isValid: true,
    validErrorMsg: '',
  });

  const onClickSubmit = useCallback(async () => {
    setButtonLoading(true);

    try {
      if (txSpec?.name === 'Approve SCNR') {
        setSentApproval(true);
      }
      await send();

      if (txSpec?.name !== 'Approve SCNR') {
        setEditComplete(false);
        onClose();
      }
    } catch (e) {
      console.error('Transaction failed', e);
    }

    setButtonLoading(false);
  }, [onClose, send, txSpec?.name]);

  const button = useMemo(() => {
    if (!isEditComplete) {
      if (!nicknameDraft || !nicknameValid.isValid || defaultNickname === nicknameDraft) {
        return {
          title: t('Submit'),
          disabled: true,
        };
      }
      return {
        title: t('Submit'),
        disabled: false,
        onClick: async () => {
          const { isValid, errorMsg } = await validateNickname(nicknameDraft);

          if (isValid) {
            setNicknameValid({
              isValid,
              validErrorMsg: '',
            });
          } else {
            setNicknameValid({
              isValid,
              validErrorMsg: errorMsg,
            });
            return;
          }
          setEditComplete(true);
        },
      };
    }
    if (buttonLoading || transactionLoading) {
      return {
        title: t('Loading'),
        disabled: true,
      };
    }
    if (isApprovalNeeded) {
      return {
        title: t('Approve'),
        disabled: false,
        onClick: onClickSubmit,
      };
    }
    if (isInsufficientBalanceWhenBurnRequired) {
      return {
        title: t('Go to Swap'),
        disabled: false,
        onClick: () => {
          resetAllChatRelatedDialogs();
          router.push({ pathname: '/swap', query: { from: ZERO_ADDRESS, to: SCNR_ADDRESS } });
          onClose(true);
          onClickClose();
        },
      };
    }
    return {
      title: t('Submit'),
      disabled: false,
      onClick: onClickSubmit,
    };
  }, [
    buttonLoading,
    defaultNickname,
    isApprovalNeeded,
    isEditComplete,
    isInsufficientBalanceWhenBurnRequired,
    nicknameValid.isValid,
    nicknameDraft,
    onClickSubmit,
    resetAllChatRelatedDialogs,
    router,
    t,
    transactionLoading,
    onClose,
    onClickClose,
  ]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const handleKeyup = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      onClose(true);
    };

    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [onClose, open]);

  return (
    <Transition.Root show={open}>
      <Transition.Child
        enter="ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 transition-all bg-black/50 backdrop-blur z-[2100]"
          onClick={() => onClose()}
        />
      </Transition.Child>

      <div className="--chat-dialog nickname-edit fixed inset-0 z-[2500] overflow-y-auto p-4 flex justify-center items-center sm:p-6 md:p-20 pointer-events-none">
        <Transition.Child
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={clsx(
              'w-fit h-fit px-[32px] py-[29px] flex flex-col gap-5 rounded-xl pointer-events-auto',
              forceDark ? 'bg-gray-900' : 'bg-white dark:bg-gray-900',
            )}
          >
            {!isEditComplete && (
              <>
                <div className="flex mx-auto">
                  {!!chatProfile && (
                    <img
                      className="mr-[18px] w-[118px] h-[118px] rounded-lg"
                      alt={`Sdoonge ${chatProfile.sdoongeLevel || 'Avatar'}`}
                      src={`${chatProfile.profileImage}`}
                    />
                  )}
                </div>

                <div className="flex flex-col w-full gap-[9px]">
                  <span
                    className={clsx(
                      'text-[12px] leading-[14px]',
                      forceDark ? 'text-gray-400' : 'text-gray-900 dark:text-gray-400',
                    )}
                  >
                    {t('Change Nickname')}
                  </span>
                  <div className="flex flex-col space-y-1">
                    <div className="relative flex w-full">
                      <input
                        placeholder={defaultNickname}
                        value={nicknameDraft}
                        onChange={(e) => {
                          setNicknameDraft(e.target.value.toLowerCase().replaceAll(' ', ''));
                          setNicknameValid({
                            isValid: true,
                            validErrorMsg: '',
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Space') return false;
                        }}
                        className={clsx(
                          'w-full pl-[16px] pr-10 py-[15px] outline-none focus:ring-0 rounded-lg text-base leading-[18px] placeholder:text-gray-500',
                          forceDark
                            ? 'bg-gray-800 text-white'
                            : 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
                        )}
                      />

                      <button
                        className={clsx(
                          'absolute w-4 h-4 top-[16px] right-[20px] bottom-[16px] active:opacity-60',
                          { hidden: nicknameDraft.length === 0 },
                        )}
                        onClick={() => setNicknameDraft('')}
                      >
                        <XCircleIcon className="w-4 h-4 text-[#606E83]" />
                      </button>
                    </div>
                    <p className={clsx('font-normal text-xs text-gray-500')}>
                      {t('Only korean/Alphabet, Up to 20 charactors')}
                    </p>
                  </div>

                  {nicknameDraft.length > 0 && !nicknameValid.isValid && (
                    <span className="text-red-500 text-[12px]">
                      {t(nicknameValid.validErrorMsg)}
                    </span>
                  )}
                </div>
              </>
            )}

            {isEditComplete && (
              <div className="flex flex-col items-center">
                <span
                  className={clsx(
                    'font-semibold text-center text-[18px] leading-[27px]',
                    forceDark ? 'text-white' : 'text-gray-900 dark:text-white',
                  )}
                >
                  {txSpec?.name === 'Approve SCNR' ? (
                    <div
                      className={clsx(router.locale === 'ko' ? 'max-w-[210px]' : 'max-w-[300px]')}
                    >
                      {t('Nickname change requires approval to use SCNR')}
                    </div>
                  ) : !isInsufficientBalanceWhenBurnRequired ? (
                    <Trans
                      t={t}
                      i18nKey="Change nickname to <Username>{{draft}}</Username>?"
                      components={{
                        Username: (
                          <span
                            className={
                              forceDark ? 'text-teal-300' : 'text-teal-500 dark:text-teal-300'
                            }
                          />
                        ),
                      }}
                      values={{ draft: nicknameDraft }}
                    />
                  ) : (
                    t('Insufficient SCNR Balance')
                  )}
                </span>
                <span
                  className={clsx(
                    'mt-4 text-center text-[14px] leading-[21px]',
                    forceDark ? 'text-gray-400' : 'text-gray-600 dark:text-gray-400',
                  )}
                >
                  {!stats?.profile.nickname ? (
                    t('Initial setup is free')
                  ) : (
                    <>
                      {t('Future updates requires {{changeFee}} SCNR', {
                        changeFee: formatAmount(changeFee),
                      })}
                      <br />
                      {`(${t('Balance')}: ${formatAmount(scnrBalance)} SCNR)`}
                    </>
                  )}
                </span>
              </div>
            )}

            <div className="flex gap-[15px] justify-center">
              <button
                className={clsx(
                  'w-[132px] py-[13.5px] rounded-lg  transition-colors text-[16px] leading-[21px] font-medium tracking-tight hover:opacity-70',
                  forceDark
                    ? 'bg-gray-800 text-gray-300'
                    : 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-300',
                )}
                onClick={() => onClose()}
              >
                {tc('Cancel')}
              </button>
              <button
                className={clsx(
                  'w-[132px] py-[13.5px] rounded-lg transition-colors text-[16px] leading-[21px] font-medium tracking-tight hover:opacity-70',
                  button.disabled
                    ? clsx(
                        forceDark
                          ? 'bg-gray-600 text-gray-400'
                          : 'bg-gray-200 dark:bg-gray-600 text-gray-900 dark:text-gray-400',
                        'cursor-disabled',
                      )
                    : 'bg-teal-500 text-white',
                )}
                onClick={button.disabled ? undefined : button.onClick}
              >
                {/* TODO: Work on CTA wording: https://swapscanner.slack.com/archives/C03368GDUS1/p1667107636959429 */}
                {button.title}
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};
