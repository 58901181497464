import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';

import { LOCAL_STORAGE_KEYS } from '@/defines/local-storage-keys';
import { ImaginaryFiat, IMAGINARY_FIAT_ADDRESS_MAP, USDC_ADDRESS } from '@/defines/token-address';
import useLocalStorageV2 from '@/hooks/use-local-storage-v2';
import { fetchInversedForex, Forex } from '@/lib/forex';
import { GTM } from '@/utils/tag-manager';

import type { Expand } from '@/types';

export type Currency = Expand<'USD' | ImaginaryFiat>;
type CurrencyType = 'USD' | 'KRW';
type CurrencySetter = (currency: Currency) => void;
type CurrencyContextType = {
  currency: Currency;
  setCurrency: CurrencySetter;
  currencyType: CurrencyType;
  rate: number;
  forex?: Forex;
};

const BASE_CURRENCY: Currency = 'USD_COINGECKO';
const BASE_CURRENCY_TYPE: CurrencyType = 'USD';
export const CURRENCY_LIST: Currency[] = (
  Object.keys(IMAGINARY_FIAT_ADDRESS_MAP) as ImaginaryFiat[]
).filter((currency) => currency !== 'KRW');
export const CURRENCY_ADDRESS_MAP: { [currency in Currency]: string } = {
  USD: USDC_ADDRESS,
  ...IMAGINARY_FIAT_ADDRESS_MAP,
};

export const CurrencyContext = createContext<CurrencyContextType>({
  currency: BASE_CURRENCY,
  setCurrency: () => BASE_CURRENCY,
  currencyType: BASE_CURRENCY_TYPE,
  rate: 1,
});

type Props = {
  children?: React.ReactNode;
  initialForex?: Forex;
};

export const CurrencyContextProvider: React.FC<Props> = ({ children, initialForex }) => {
  const [currency, _setCurrency] = useLocalStorageV2<Currency>(
    LOCAL_STORAGE_KEYS.currencyV2,
    BASE_CURRENCY,
  );
  const [currencyType, setCurrencyType] = useLocalStorageV2<CurrencyType>(
    LOCAL_STORAGE_KEYS.currencyTypeV2,
    BASE_CURRENCY_TYPE,
  );

  useEffect(() => {
    setCurrencyType(currency.slice(0, 3) as CurrencyType);
  }, [currency, setCurrencyType]);

  const { data: forex } = useSWR('@forex', async () => await fetchInversedForex(), {
    refreshInterval: 1000 * 60,
    fallbackData: initialForex,
  });

  const rate = useMemo(
    () => (forex && forex[currency] ? 1 / forex[currency] : 1),
    [forex, currency],
  );

  useEffect(() => {
    if (forex && !forex[currency]) {
      _setCurrency(BASE_CURRENCY);
    }
  }, [forex, currency, _setCurrency]);

  const setCurrency = useCallback(
    (currency: Currency) => {
      GTM.dataLayer({
        dataLayer: {
          event: 'currency_change',
          currency,
        },
      });
      _setCurrency(currency);
    },
    [_setCurrency],
  );

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrency,
        currencyType,
        rate,
        forex,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrencyContext = () => {
  const context = useContext(CurrencyContext);

  if (typeof context === 'undefined') {
    throw new Error(`useCurrencyContext must be used within a CurrencyContextProvider`);
  }

  return context;
};
