const safeMinMax = (val: number) => Math.min(Math.max(val, 0), 20);

/**
 * convert a number to readable string.
 * @example
 * numberToReadableLocaleString(1234.1234) // 1,234
 * numberToReadableLocaleString(34.1234) // 34.1
 * numberToReadableLocaleString(0.1234) // 0.123
 * numberToReadableLocaleString(0.0034) // 0.00340
 * numberToReadableLocaleString(0.00341234) // 0.00341
 */
export const numberToReadableLocaleString = (
  val?: number | null,
  {
    desiredSignificantDigits = 3,
    cutZeros = false,
    calcMinimumFractionDigits,
    calcMaximumFractionDigits,
  }: {
    desiredSignificantDigits?: number;
    cutZeros?: boolean;
    calcMinimumFractionDigits?: (fractionDigits: number) => number;
    calcMaximumFractionDigits?: (fractionDigits: number) => number;
  } = {},
) => {
  const logVal = val ? Math.floor(Math.log10(val)) : 0;
  const fractionDigits = desiredSignificantDigits - 1 - logVal;

  return (val ?? 0).toLocaleString(undefined, {
    minimumFractionDigits: calcMinimumFractionDigits
      ? safeMinMax(calcMinimumFractionDigits(fractionDigits))
      : !cutZeros
      ? safeMinMax(fractionDigits)
      : undefined,
    maximumFractionDigits: calcMaximumFractionDigits
      ? safeMinMax(calcMaximumFractionDigits(fractionDigits))
      : safeMinMax(fractionDigits),
    useGrouping: true,
  });
};
