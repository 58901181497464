import moment from 'moment';

import 'moment/locale/es-us';
import 'moment/locale/ko';

export default moment;

export const fromNow = (date: Date | string) => moment(date).fromNow();
export const calendar = (date: Date | string) => moment(date).calendar().replace('지난주', '지난');
export const isoFormat = (date: Date | string) => moment(date).format('YYYY-MM-DD HH:mm:ss');

export const getSafeTimezone = (): string => {
  try {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (tz) return tz;
      throw new Error('falsey Intl timeZone');
    } catch (e) {
      const tz = moment.tz.guess();
      if (tz) return tz;
      throw new Error('falsey moment.tz.guess');
    }
  } catch (e) {
    return 'Asia/Seoul';
  }
};
