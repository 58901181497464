export type TokenAllowanceTarget = {
  token: string;
  account: string;
  contract: string;
};

export type TokenAllowance = {
  token: string;
  account: string;
  contract: string;
  allowance: string;
};

export const getTokenAllowances = async (targets: TokenAllowanceTarget[]) => {
  try {
    const res = await (
      await fetch(`${process.env.API_HOST}/token-allowances`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          targets: targets.map(({ token, account, contract }) => ({
            token,
            account,
            contract,
          })),
        }),
      })
    ).json();

    return {
      allowances: res.allowances as TokenAllowance[],
    };
  } catch (err) {
    throw err;
  }
};
