import clsx from 'clsx';
import { useMemo } from 'react';

import { useWalletContext } from '@/context/wallet';
import useBalancesV1 from '@/hooks/use-balances-v1';
import useForex from '@/hooks/use-forex';
import usePrices from '@/hooks/use-prices';
import useTokens from '@/hooks/use-tokens';

import type { Token } from '@/lib/tokens';

export default function TokenBalance({
  className,
  token,
  forceDark,
}: {
  className?: string;
  token: Token;
  forceDark: boolean;
}) {
  const { wallet } = useWalletContext();
  const { usdToReadableCurrency } = useForex();
  const { tokenAddressToToken } = useTokens();
  const { balances } = useBalancesV1({ account: wallet?.account ?? null });
  const { prices } = usePrices();

  const [balance, priceUSDC] = useMemo(() => {
    if (
      !balances ||
      !balances[token.address] ||
      !prices ||
      !prices[token.address] ||
      !tokenAddressToToken[token.address]
    ) {
      return [null, 0];
    }

    const _balance = +balances[token.address] / 10 ** +tokenAddressToToken[token.address].decimals;
    const _priceUSDC = prices[token.address] * _balance;

    return [_balance, _priceUSDC];
  }, [balances, prices, tokenAddressToToken, token.address]);

  if (!balance || balance <= 0) {
    return <></>;
  }

  return (
    <span
      className={clsx(
        className,
        'text-xs flex items-center space-x-2',
        forceDark ? 'text-slate-200' : 'text-gray-700 dark:text-slate-200',
      )}
    >
      <span>
        {balance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 7,
        })}
      </span>
      <span>/</span>
      <span>{usdToReadableCurrency(priceUSDC)}</span>
    </span>
  );
}
