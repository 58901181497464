type Environment = 'off' | 'test' | 'next' | 'production';
type Features<Name extends string> = { [name in Name]: Environment };

const defineFeatures = <Name extends string>(features: Features<Name>): Features<Name> => features;
const features = defineFeatures({
  isTemporalFeeCapEnabled: 'next',
  isShowChatUserList: 'next',
  isShowExternalStaking: 'next',
  isShowTokenDonation: 'next',
  isLargeSwapHistoryInProChartViewable: 'next',
  isClaimableRewardsInPortfolio: 'next',
  isWalletConnectEnabled: 'production',
  SCNRBurnRate: 'next',
});

type FeatureName = keyof typeof features;
type FeatureFlags = { [name in FeatureName]: boolean };

const getFeatureFlags = (featureEnvironments: Features<FeatureName>): FeatureFlags => {
  const environmentTiers: { [env in Environment]: number } = {
    off: 0,
    test: 1,
    next: 2,
    production: 3,
  };
  const env = (process.env.ENV as Environment | undefined) ?? 'test';
  const buildTier = environmentTiers[env];

  return Object.entries(featureEnvironments).reduce((state, [name, environment]) => {
    const featureTier = environmentTiers[environment];
    const isEnabled = featureTier >= buildTier;
    return { ...state, [name]: isEnabled };
  }, {} as FeatureFlags);
};

const FeatureFlag = getFeatureFlags(features);
export default FeatureFlag;
