import Bowser from 'bowser';

export const isMobile: (fallback?: boolean) => boolean = (fallback = false) => {
  if (typeof window === 'undefined') return fallback;

  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getPlatform().type === 'mobile';
};

export const isTablet: (fallback?: boolean) => boolean = (fallback = false) => {
  if (typeof window === 'undefined') return fallback;

  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getPlatform().type === 'tablet';
};

export const isDesktop: (fallback?: boolean) => boolean = (fallback = false) => {
  if (typeof window === 'undefined') return fallback;

  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getPlatform().type === 'desktop';
};

export const isMacOS: (fallback?: boolean) => boolean | null = (fallback = false) => {
  if (typeof window === 'undefined') return fallback;

  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getOS().name?.toLowerCase().includes('mac') ?? null;
};

export const isKakaoInApp: (fallback?: boolean) => boolean = (fallback = false) => {
  if (typeof window === 'undefined') return fallback;

  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getUA().toLowerCase().includes('kakao');
};
