import { useCallback, useMemo } from 'react';

import { LOCAL_STORAGE_KEYS } from '@/defines/local-storage-keys';

import useLocalStorageV2 from './use-local-storage-v2';

export type FromToWithInverse = {
  from: string;
  to: string;
  inverse: boolean;
};

type FromToWithInverseString = `${string}:${string}:${boolean}`;

export function useStarredPairs() {
  const [pairStrings, setPairStrings, reload] = useLocalStorageV2<FromToWithInverseString[]>(
    LOCAL_STORAGE_KEYS.starredPairs,
    [],
  );

  const starredPairs = useMemo(
    () => (pairStrings ?? []).map(parseFromToWithInverse),
    [pairStrings],
  );

  const isStarredPair = useCallback(
    ({ from, to, inverse }: FromToWithInverse) => {
      const fromToString = stringifyFromToWithInverse({ from, to, inverse });

      return (pairStrings ?? []).includes(fromToString);
    },
    [pairStrings],
  );

  const toggleStarred = useCallback(
    ({ from, to, inverse }: FromToWithInverse) => {
      const fromToString = stringifyFromToWithInverse({ from, to, inverse });

      const isStarred = (pairStrings ?? []).includes(fromToString);

      if (isStarred) {
        setPairStrings((pairStrings ?? []).filter((v) => v !== fromToString));
      } else {
        setPairStrings([...(pairStrings ?? []), fromToString]);
      }
    },
    [pairStrings, setPairStrings],
  );

  return {
    starredPairs,
    isStarredPair,
    toggleStarred,
    reloadStarredPairs: reload,
  };
}

function stringifyFromToWithInverse({
  from,
  to,
  inverse,
}: FromToWithInverse): FromToWithInverseString {
  return `${from}:${to}:${inverse}`;
}

function parseFromToWithInverse(str: FromToWithInverseString): FromToWithInverse {
  if (str.split(':').length !== 3)
    throw new Error(`invalid favoriteFromToString type. received: ${str}`);

  const [from, to, inverse] = str.split(':');

  return {
    from,
    to,
    inverse: inverse === 'true',
  };
}
