import usePrices from '@/hooks/use-prices';

type UsePriceOptions = {
  tokenAddress?: string;
};

export default function usePriceV1({ tokenAddress }: UsePriceOptions) {
  const { prices } = usePrices();

  const price = !tokenAddress ? null : prices?.[tokenAddress] ?? null;

  return { price };
}
