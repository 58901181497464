export const copyToClipboard = (message: string) => {
  const textField = document.createElement('textarea');
  textField.innerText = message;
  document.body.appendChild(textField);

  textField.select();
  document.execCommand('copy');

  textField.remove();
};
