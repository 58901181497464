interface Props {
  className?: string;
}

export default function StakingMenuIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mdi:pickaxe">
        <path
          id="Vector"
          d="M12.325 9.35L2.91667 18.75L1.75 17.5833L11.15 8.175L12.325 9.35ZM16.0583 6.94167L16.55 6.45L15.8917 5.79167L16.425 5.25834L15.2417 4.075L14.7083 4.60834L14.05 3.95L13.5583 4.44167C12.1083 3.25834 10.475 2.30834 8.725 1.64167L8.03333 3.13334C9.49167 3.9 10.8333 4.825 12.0833 5.91667L11.6667 6.33334L14.1667 8.83334L14.5833 8.41667C15.675 9.66667 16.6 11.0083 17.3667 12.4667L18.8583 11.775C18.1917 10.025 17.2417 8.39167 16.0583 6.94167Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
