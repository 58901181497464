import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

import useAssertiveDisabled from '@/hooks/use-assertive-disabled';
import fetcher from '@/lib/fetcher';

const FORCE_REFRESH_TIMEOUT = 1000 * 60 * 5; // 5 minutes

const UpdateButton = ({ version }: { version: string }) => {
  const { t } = useTranslation('common');
  const [secondsToRefresh, setSecondsToRefresh] = useState<number | null>(null);

  const forceUpdate = useCallback(() => {
    /**
     * cache-control policy for the normal "pages" has stale-while-invalidate=60, which
     * means that the stale version can still be served for at most 60 seconds. This will
     * make the CDN forcefully miss the cache in such cases, allowing clients to always
     * able to hit the latest version when clicking this button.
     */
    const sp = new URLSearchParams(window.location.search);
    sp.set('v', version);
    window.location.search = sp.toString();
  }, [version]);

  useEffect(() => {
    const refreshAt = Date.now() + FORCE_REFRESH_TIMEOUT + 1000;
    const timer = setInterval(() => {
      setSecondsToRefresh(Math.max(0, Math.floor((refreshAt - Date.now()) / 1000)));
    }, 100);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (secondsToRefresh === null) return;
    if (secondsToRefresh <= 0) {
      forceUpdate();
    }
  }, [secondsToRefresh, forceUpdate]);

  return (
    <button onClick={() => forceUpdate()} className="text-white font-extrabold px-2">
      <Trans
        t={t}
        i18nKey={'Updating<br />in {{seconds}}s'}
        values={{ seconds: secondsToRefresh }}
      />
    </button>
  );
};

/**
 * This component should be placed inside of <SnackbarProvider />.
 */
export default function VersionCheckerProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const isAssertiveDisabled = useAssertiveDisabled();

  const { data } = useSWR<{ version: string }>('/api/version', fetcher, {
    refreshInterval: 10000,
    isPaused: () => isAssertiveDisabled,
  });

  useEffect(() => {
    // remove the temporal version query
    if (router.query.v) {
      delete router.query.v;
      router.replace({
        query: router.query,
      });
    }
  }, [router]);

  useEffect(() => {
    if (isAssertiveDisabled) return;
    if (!data?.version) return;
    if (!process.env.VERSION) return;
    if (data?.version !== process.env.VERSION) {
      enqueueSnackbar(t('New version of the Swapscanner available'), {
        persist: true,
        preventDuplicate: true,
        variant: 'info',
        action: <UpdateButton version={data?.version} />,
      });
    }
  }, [data?.version, enqueueSnackbar, t, isAssertiveDisabled]);

  return <>{children}</>;
}
