interface Props {
  className?: string;
  width?: number;
  height?: number;
  opcaity?: number;
}

export default function StarIconForTradingView({ className, width, height, opcaity = 1 }: Props) {
  return (
    <svg
      className={className}
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill="none"
      fillOpacity={opcaity}
      stroke={opcaity === 0 ? 'currentColor' : 'none'}
      strokeWidth={opcaity === 0 ? '1.5' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00964 0.833467C8.37545 0.0866399 9.43995 0.0866408 9.80575 0.833467L11.7424 4.78727C11.8873 5.08318 12.1687 5.28869 12.4947 5.33671L16.8398 5.97673C17.6573 6.09715 17.9843 7.10088 17.3946 7.67972L14.2423 10.7739C14.0086 11.0032 13.902 11.3325 13.9569 11.6553L14.6993 16.0174C14.8385 16.8354 13.9784 17.4573 13.2452 17.0688L9.37597 15.0182C9.08308 14.8629 8.73231 14.8629 8.43943 15.0182L4.57016 17.0688C3.83699 17.4573 2.97685 16.8354 3.11606 16.0174L3.85846 11.6553C3.9134 11.3325 3.80681 11.0032 3.57313 10.7739L0.420832 7.67972C-0.168888 7.10088 0.158089 6.09715 0.9756 5.97673L5.32069 5.33671C5.64667 5.28869 5.92808 5.08317 6.07302 4.78726L8.00964 0.833467Z"
        fill="currentColor"
      />
    </svg>
  );
}
