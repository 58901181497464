import { Client as WebSocket } from 'rpc-websockets';

import fetcher from './lib/fetcher';
import sleep from './utils/sleep';

import type { IWSRequestParams } from 'rpc-websockets/dist/lib/client';

const isBrowser = typeof window !== 'undefined';
const isTopWindow = isBrowser && window.top === window.self;

type ChatChannelRegistryType = {
  [channel: string]: {
    count: number;
    handler: (data: any) => any;
  };
};

export type WebSocketStorage = {
  wsPromise: Promise<WebSocket>;
  closed: boolean;
  subscriptionRegistry: {
    [key: string]: number;
  };
  chatChannelSubscriptionRegistry: {
    [channelName: string]: number;
  };
  onConnectHandlers: Set<(ws: WebSocket) => any>;
  onCloseHandlers: Set<() => any>;
};

// NOTE: THIS MUST NOT BE CALLED OUTSIDE OF `getWS()`, THUS NOT EXPORTED
const initializeWS = () => {
  if (!isTopWindow) {
    throw new Error('cannot create WS connection in iframe.');
  }

  const subscriptionRegistry = {};
  const chatChannelSubscriptionRegistry = {};
  const onConnectHandlers = new Set<(ws: WebSocket) => any>();
  const onCloseHandlers = new Set<() => any>();
  let wsPromiseResolve: (ws: WebSocket) => void;

  const storage = {
    wsPromise: new Promise<WebSocket>((resolve) => (wsPromiseResolve = resolve)),
    subscriptionRegistry,
    chatChannelSubscriptionRegistry,
    onConnectHandlers,
    onCloseHandlers,
  } as WebSocketStorage;

  const ws = new WebSocket(process.env.WSAPI_HOST, {
    reconnect: true,
    max_reconnects: 0,
  });

  let socketIdx = 0;
  ws.on('open', async () => {
    const currentSocketIdx = ++socketIdx;
    const isNotReady = () => socketIdx !== currentSocketIdx;

    while (!isNotReady()) {
      try {
        const token = await fetcher(process.env.API_HOST + `/ws/token?t=${Date.now()}`);
        if (isNotReady()) return;

        const result = await ws.call('rpc.login', token, 10_000);
        if (result == false) {
          throw new Error('failed to rpc login');
        }
        if (isNotReady()) return;

        break;
      } catch (error) {
        console.error('failed to login', error);
      }

      await sleep(1_000);
    }

    await Promise.all(Object.keys(subscriptionRegistry).map((e) => ws.subscribe(e))).catch(
      (err) => {
        if (isNotReady()) return;
        console.error('failed to register existing subscriptions', err);
      },
    );
    if (isNotReady()) return;

    onConnectHandlers.forEach((f) => {
      try {
        f(ws);
      } catch (err) {
        console.error('on connect handler error', err);
      }
    });

    wsPromiseResolve(ws);
  });

  ws.on('error', (error) => {
    console.error('socket error', error);
  });

  ws.on('close', () => {
    // invalidate previous socket session index to prevent socket not ready error
    socketIdx++;

    // replacing the Promise to block `getWS()` calls
    storage.wsPromise = new Promise<WebSocket>((resolve) => (wsPromiseResolve = resolve));

    onCloseHandlers.forEach((f) => {
      try {
        f();
      } catch (err) {
        console.error('on close handler error', err);
      }
    });
  });

  return storage;
};

export default async function getWS(): Promise<WebSocket> {
  if (!window.parent.wsStorage) {
    window.parent.wsStorage = initializeWS();
  }

  return await window.parent.wsStorage.wsPromise;
}

export function onConnect(handler: (ws: WebSocket) => any) {
  if (!window.parent.wsStorage) {
    window.parent.wsStorage = initializeWS();
  }

  window.parent.wsStorage.onConnectHandlers.add(handler);
}

export function offConnect(handler: (ws: WebSocket) => any) {
  if (!window.parent.wsStorage) {
    window.parent.wsStorage = initializeWS();
  }

  window.parent.wsStorage.onConnectHandlers.delete(handler);
}

/**
 * Directly using `ws.call()` is discouraged. Instead use this method with following features:
 * - default request timeout
 * - rejects immediately when closed
 *
 * @param method
 * @param params
 * @param timeout
 * @returns {Promise<any>}
 */
export function call(
  method: string,
  params?: IWSRequestParams,
  timeout: number = 10_000,
  socketCloseRetries: number = 5,
): Promise<any> {
  if (!window.parent.wsStorage) {
    window.parent.wsStorage = initializeWS();
  }

  let resolve: (result: any) => void, reject: (error: any) => void;
  const promise = new Promise<any>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  let retrying = false;
  const closeRetrier = () => {
    retrying = true;
    window.parent.wsStorage!.onCloseHandlers.delete(closeRetrier);

    if (socketCloseRetries <= 0) {
      reject(new Error('socket closed retry count exceeded'));
      return;
    }

    // immediate retry is valid since WebSocket will reconnect immediately.
    call(method, params, timeout, socketCloseRetries - 1)
      .then(resolve)
      .catch(reject);
  };

  (async () => {
    try {
      const ws = await getWS();

      window.parent.wsStorage!.onCloseHandlers.add(closeRetrier);
      const res = await ws.call(method, params, timeout);

      resolve!(res);
    } catch (err) {
      // if retrying, resolve/reject will be called from nested `call()` call.
      if (!retrying) {
        reject!(err);
      }
    } finally {
      window.parent.wsStorage!.onCloseHandlers.delete(closeRetrier);
    }
  })();

  return promise;
}

export async function subscribe(event: string) {
  if (!window.parent.wsStorage) {
    window.parent.wsStorage = initializeWS();
  }

  const registry = window.parent.wsStorage.subscriptionRegistry;
  registry[event] = (registry[event] || 0) + 1;

  return call('rpc.on', [event])
    .then((result) => {
      if (typeof event === 'string' && result[event] !== 'ok')
        throw new Error("Failed subscribing to an event '" + event + "' with: " + result[event]);
      return result;
    })
    .catch((err) => {
      console.error('failed to subscribe', event, err);
    });
}

export async function unsubscribe(event: string) {
  if (!window.parent.wsStorage) {
    window.parent.wsStorage = initializeWS();
  }

  const registry = window.parent.wsStorage.subscriptionRegistry;
  registry[event] = (registry[event] || 0) - 1;

  if (registry[event] <= 0) {
    call('rpc.off', [event], undefined, 0)
      .then((result) => {
        if (typeof event === 'string' && result[event] !== 'ok')
          throw new Error('Failed unsubscribing from an event with: ' + result);
        return result;
      })
      .catch((err) => {
        console.error('failed to unsubscribe', event, err);
      });
  }

  if (registry[event] < 0) {
    console.error(`Event subscription count for ${event} is below zero: ${registry[event]}`);
  }

  if (registry[event] === 0) {
    delete registry[event];
  }
}

export async function subscribeChat(
  channelName: string,
  address: string,
  handler: (data: any) => void,
  chatUserListHandler: ({ subscribedUserList }: { subscribedUserList: string[] }) => void,
) {
  if (!window.parent.wsStorage) {
    window.parent.wsStorage = initializeWS();
  }

  const registry = window.parent.wsStorage.chatChannelSubscriptionRegistry;
  registry[channelName] = (registry[channelName] || 0) + 1;

  onConnect(() => _subscribeChatChannel(channelName, address, handler, chatUserListHandler));
  return _subscribeChatChannel(channelName, address, handler, chatUserListHandler);
}

async function _subscribeChatChannel(
  channelName: string,
  address: string,
  handler: (data: any) => void,
  chatUserListHandler: ({ subscribedUserList }: { subscribedUserList: string[] }) => void,
) {
  const ws = await getWS();
  ws.on('chatV1', handler);
  ws.on('chatV1UserList', chatUserListHandler);

  return call('c_j', { channelName, address })
    .then((result) => {
      console.log('succeed to join channel', channelName, result);
      return result;
    })
    .catch((err) => {
      console.error('failed to poll channel', err);
    });
}

export async function unsubscribeChat(
  channelName: string,
  address: string,
  handler: (data: any) => void,
  chatUserListHandler: ({ subscribedUserList }: { subscribedUserList: string[] }) => void,
) {
  if (!window.parent.wsStorage) {
    window.parent.wsStorage = initializeWS();
  }
  const ws = await getWS();

  const registry = window.parent.wsStorage.chatChannelSubscriptionRegistry;
  registry[channelName] = (registry[channelName] || 0) - 1;

  if (registry[channelName] <= 0) {
    ws.off('chatV1', handler);
    ws.off('chatV1UserList', chatUserListHandler);
    ws.call('c_e', { channelName, address }).catch((err) => {
      console.error('failed to exit channel', err);
    });
  }

  if (registry[channelName] < 0) {
    console.error(
      `Event subscription count for chat channel ${channelName} is below zero: ${registry[channelName]}`,
    );
  }

  if (registry[channelName] === 0) {
    delete registry[channelName];
  }
}
