export const dressTokenCount = (tokenCount: number | string) => {
  if (!tokenCount || isNaN(Number(tokenCount)) || !isFinite(Number(tokenCount))) {
    return '0.0';
  }

  const _tokenCount = Number(tokenCount);

  return _tokenCount >= 1_000
    ? Math.floor(_tokenCount).toLocaleString()
    : _tokenCount.toLocaleString(undefined, {
        maximumSignificantDigits: 3,
        minimumSignificantDigits: 3,
      });
};
