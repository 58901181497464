module.exports = ({ amount, decimals }) => {
  if (!amount || !isFinite(+decimals)) {
    return null;
  }

  let [n, f = ''] = amount.toString().replace(/^0+/, '').replace(/,/g, '').split('.');

  f = f.replace(/0+$/, '');

  decimals - n.length;

  let paddedN = n.padStart(+decimals, '0');
  if (paddedN[0] === '0') {
    paddedN = '0.' + paddedN;
  } else {
    const ff = paddedN.slice(paddedN.length - decimals);
    paddedN = paddedN.slice(0, paddedN.length - decimals) + (ff.length > 0 ? '.' + ff : '');
  }

  if (paddedN[0] === '.') {
    paddedN = '0' + paddedN;
  }

  return paddedN + (paddedN.indexOf('.') === -1 && f.length > 0 ? '.' + f : f);
};

// module.exports({amount:'1168284665238925814530', decimals:18})
