import React from 'react';
import ReactNumberFormat, { NumberFormatProps } from 'react-number-format';

const NumberFormat = React.forwardRef<HTMLInputElement, NumberFormatProps>((props, ref) => {
  const { ...other } = props;

  return <ReactNumberFormat getInputRef={ref} {...other} />;
});

export default NumberFormat;
