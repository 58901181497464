import { memo } from 'react';

interface Props {
  className?: string;
}
function Grid2ICon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 21V3H11V21H3ZM4.5 4.5H9.5V19.5H4.5V4.5ZM13 3V21H21V3H13ZM19.5 4.5H14.5V19.5H19.5V4.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default memo(Grid2ICon);
