import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';

export type ChatSDoongELevelDialogProps = {
  open: boolean;
  onClose: () => void;
  scnrBalance: number;
  sdoongeLevel: string;
  forceDark?: boolean;
};

const LEVELS = [
  {
    level: 'Community Manager',
    avatar: 'COMMUNITY_MANAGER.svg',
    scnr: 0,
    show: false,
  },
  {
    level: 'Level 1',
    scnr: 1_000,
    avatar: 'T1_R.svg',
    show: true,
  },
  {
    level: 'Level 2',
    scnr: 5_000,
    avatar: 'T2_R.svg',
    show: true,
  },
  {
    level: 'Level 3',
    scnr: 25_000,
    avatar: 'T3_R.svg',
    show: true,
  },
  {
    level: 'Level 4',
    scnr: 100_000,
    avatar: 'T4_R.svg',
    show: true,
  },
  {
    level: 'Level 5',
    scnr: 200_000,
    avatar: 'T5_R.svg',
    show: true,
  },
  {
    level: 'Level 6',
    scnr: 200_000,
    avatar: 'T6_R.svg',
    show: true,
  },
];

export const ChatSDoongELevelDialog: React.FC<ChatSDoongELevelDialogProps> = ({
  open,
  onClose,
  scnrBalance,
  sdoongeLevel,
  forceDark = false,
}) => {
  const { t } = useTranslation('chat');

  useEffect(() => {
    if (!open) {
      return;
    }

    const handleKeyup = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;

      onClose();
    };

    document.addEventListener('keyup', handleKeyup);

    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [onClose, open]);

  const userProfile = useMemo(
    () => LEVELS.find(({ level }) => sdoongeLevel === level),
    [sdoongeLevel],
  );

  return (
    <Transition.Root show={open}>
      <div className="--chat-dialog sdoonge-level fixed inset-0 z-[1500] overflow-y-auto p-4 flex justify-center items-center sm:p-6 md:p-20">
        <Transition.Child
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 transition-all bg-black/50 backdrop-blur"
            onClick={onClose}
          />
        </Transition.Child>

        <Transition.Child
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={clsx('max-w-[300px] w-screen h-fit flex flex-col rounded-xl')}
          >
            <div
              className={clsx(
                'flex flex-row items-center space-x-4 p-8 rounded-t-xl border-l border-r border-t',
                forceDark
                  ? 'bg-gray-800 border-gray-600'
                  : 'dark:bg-gray-800 bg-white dark:border-gray-600 border-gray-200',
              )}
            >
              <img
                src={`/images/chat/${userProfile?.avatar}`}
                alt="my-level"
                className="w-[54px] h-auto"
              />
              <div className="flex flex-col space-y-1">
                <p
                  className={clsx(
                    'font-bold text-2xl',
                    forceDark ? 'text-white' : 'text-gray-900 dark:text-white',
                  )}
                >
                  {sdoongeLevel.toUpperCase()}
                </p>
                <p
                  className={clsx(
                    'font-medium text-sm',
                    forceDark ? 'text-gray-400' : 'dark:text-gray-400 text-gray-500',
                  )}
                >
                  <Trans
                    t={t}
                    i18nKey="Hold: {{scnrBalance}} SCNR"
                    values={{
                      scnrBalance: scnrBalance.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }),
                    }}
                  />
                </p>
              </div>
            </div>
            <div
              className={clsx(
                'py-6 px-10 flex flex-col space-y-6 rounded-b-xl border',
                forceDark
                  ? 'border-gray-600 bg-gray-700'
                  : 'border-gray-200 dark:border-gray-600 dark:bg-gray-700 bg-gray-100',
              )}
            >
              {LEVELS.filter(({ show }) => show).map(({ level, scnr, avatar }) => (
                <div
                  key={`level-${level}-${scnr}`}
                  className="flex flex-row items-center space-x-4"
                >
                  <img src={`/images/chat/${avatar}`} alt={level} className="w-10 h-auto" />
                  <div className="flex flex-col space-y-1">
                    <p
                      className={clsx(
                        'font-semibold text-base',
                        forceDark ? 'text-white' : 'dark:text-white text-gray-700',
                      )}
                    >
                      {level.toUpperCase()}
                    </p>
                    <p
                      className={clsx(
                        'font-medium text-sm',
                        forceDark ? 'text-gray-400' : 'text-gray-500 dark:text-gray-400',
                      )}
                    >
                      {level === 'Level 6'
                        ? `${scnr.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })} SCNR ~`
                        : `~ ${scnr.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })} SCNR`}
                    </p>
                  </div>
                </div>
              ))}
              <button
                className={clsx(
                  'mx-auto w-[132px] py-[13.5px] rounded-lg hover:opacity-70 transition-colors text-[16px] leading-[21px] font-medium tracking-tight !mt-10',
                  forceDark
                    ? 'bg-gray-600 text-gray-300'
                    : 'bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-300',
                )}
                onClick={onClose}
              >
                {t('Close')}
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};
