import type { MaybePromise } from '@/types';

export class TimeoutHandler {
  handlerRef = { id: -1 as unknown as NodeJS.Timeout };

  get handler() {
    return this.handlerRef.id;
  }
  set handler(n: NodeJS.Timeout) {
    this.handlerRef.id = n;
  }

  clear() {
    clearTimeout(this.handlerRef.id);
  }
}

type SetIntervalWithTimeoutClearCallback = () => void;
type SetIntervalWithTimeoutCallback = (
  clear: SetIntervalWithTimeoutClearCallback,
) => MaybePromise<void>;

export default function setIntervalWithTimeout(
  callback: SetIntervalWithTimeoutCallback,
  intervalMs: number,
  handleWrapper = new TimeoutHandler(),
) {
  let cleared = false;

  const timeout = () => {
    handleWrapper.handler = setTimeout(() => {
      Promise.resolve(
        callback(() => {
          cleared = true;
          handleWrapper.clear();
        }),
      ).finally(() => {
        if (!cleared) {
          timeout();
        }
      });
    }, intervalMs);
  };
  timeout();
  return handleWrapper;
}
