interface Props {
  className?: string;
}

export default function SwapMenuIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.30782 7.19219C3.24971 7.13415 3.20361 7.06522 3.17215 6.98934C3.1407 6.91347 3.12451 6.83214 3.12451 6.75C3.12451 6.66787 3.1407 6.58654 3.17215 6.51067C3.20361 6.43479 3.24971 6.36586 3.30782 6.30782L5.80782 3.80782C5.89523 3.72031 6.00663 3.6607 6.12794 3.63655C6.24924 3.61239 6.37498 3.62477 6.48925 3.67211C6.60351 3.71946 6.70116 3.79964 6.76983 3.90252C6.8385 4.00539 6.8751 4.12632 6.875 4.25V6.125H16.25C16.4158 6.125 16.5747 6.19085 16.6919 6.30806C16.8092 6.42527 16.875 6.58424 16.875 6.75C16.875 6.91576 16.8092 7.07474 16.6919 7.19195C16.5747 7.30916 16.4158 7.375 16.25 7.375H6.875V9.25C6.8751 9.37369 6.8385 9.49462 6.76983 9.59749C6.70116 9.70036 6.60351 9.78055 6.48925 9.82789C6.37498 9.87524 6.24924 9.88762 6.12794 9.86346C6.00663 9.8393 5.89523 9.7797 5.80782 9.69219L3.30782 7.19219ZM16.6922 13.8078L14.1922 11.3078C14.1048 11.2203 13.9934 11.1607 13.8721 11.1365C13.7508 11.1124 13.625 11.1248 13.5108 11.1721C13.3965 11.2195 13.2988 11.2996 13.2302 11.4025C13.1615 11.5054 13.1249 11.6263 13.125 11.75V13.625H3.75C3.58424 13.625 3.42527 13.6909 3.30806 13.8081C3.19085 13.9253 3.125 14.0842 3.125 14.25C3.125 14.4158 3.19085 14.5747 3.30806 14.6919C3.42527 14.8092 3.58424 14.875 3.75 14.875H13.125V16.75C13.1249 16.8737 13.1615 16.9946 13.2302 17.0975C13.2988 17.2004 13.3965 17.2805 13.5108 17.3279C13.625 17.3752 13.7508 17.3876 13.8721 17.3635C13.9934 17.3393 14.1048 17.2797 14.1922 17.1922L16.6922 14.6922C16.7503 14.6341 16.7964 14.5652 16.8279 14.4893C16.8593 14.4135 16.8755 14.3321 16.8755 14.25C16.8755 14.1679 16.8593 14.0865 16.8279 14.0107C16.7964 13.9348 16.7503 13.8659 16.6922 13.8078Z"
        fill="currentColor"
      />
    </svg>
  );
}
