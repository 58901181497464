interface Props {
  className?: string;
}

export default function MenuKebabIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="4" r="2" fill="white" />
      <circle cx="12" cy="12" r="2" fill="white" />
      <circle cx="12" cy="20" r="2" fill="white" />
    </svg>
  );
}
