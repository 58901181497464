export default function PrivacyPolicy() {
  return (
    <div className="flex flex-col items-start space-y-6">
      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">WE VALUE YOUR PRIVACY</h3>

        <p>
          We value the privacy of individuals who visit our website at https://swapscanner.io/ (the
          &ldquo;<b>Website</b>&rdquo;), and any of our other applications, or services that link to
          this Privacy Policy (collectively, the &ldquo;<b>Services</b>&rdquo;). This Privacy Policy
          (&ldquo;<b>Policy</b>&rdquo;) is designed to explain how we collect, use, and share
          information from users of the Services. This Policy is incorporated by reference into
          our Terms of Use. By agreeing to this Policy through your continued use of the Services,
          you agree to the terms and conditions of this Policy.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">WHAT TYPE OF INFORMATION WE COLLECT?</h3>

        <p>
          We collect any information you provide to us when you use the Services. You may provide us
          with information in various ways on the Services.
        </p>

        <p>
          <b>Personal Information:</b> When you use our Services, you may need to provide us with
          the information to verify your identity, as required by law.
        </p>
        <p>
          <b>Financial Information:</b> We may collect necessary financial information such as your
          wallet address when you use our Services.
        </p>
        <p>
          <b>Usage Information</b>: We may collect information about how you access and use our
          Services, your actions on the Services, including your interactions with others on the
          Services, any photos or media you may upload to the Services, comments and posts you may
          make in our public discussion forums, and other content you may provide.
        </p>
        <p>
          <b>Technical Data</b>: We may collect data such as your IP (internet protocol) address,
          ISP (internet services provider), the web browser used to access the Services, the time
          the Services were accessed, which web pages were visited, operating system and platform, a
          mobile device-type identifier, and other technology on the devices you use to access our
          Services.
        </p>
        <p>
          <b>Communications</b>: We may receive additional information about you when you contact us
          directly. For example, we will receive your email address, the contents of your message
          and any attachments that you may send to us, and other information you choose to provide
          when you contact us through email.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">WE COLLECT COOKIES</h3>

        <p>
          We collect any information you provide to us when you use the Services. You may provide us
          with information in various ways on the Services.
        </p>
        <p>
          We may use this information, for example, to ensure that the Services function properly,
          to determine how many users have visited certain pages or opened messages, or to prevent
          fraud. We also work with analytics providers which use cookies and similar technologies to
          collect and analyse information about use of the Services and report on activities and
          trends. If you do not want information collected through the use of cookies, there is a
          procedure in most browsers that allows you to automatically decline cookies or be given
          the choice of declining or accepting the transfer to your computer of a particular cookie
          (or cookies) from a particular site. You may also wish to refer to 
          <a
            className="underline underline-offset-2 text-blue-400 break-all"
            href="http://www.allaboutcookies.org/manage-cookies/index.html"
            target="_blank"
            rel="noreferrer"
          >
            http://www.allaboutcookies.org/manage-cookies/index.html
          </a>
          . If, however, you do not accept cookies, you may experience some inconvenience in your
          use of the Services.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">HOW DO WE USE THE INFORMATION WE COLLECT?</h3>

        <p>
          ・ Operating, maintaining, enhancing and providing features of the Services, providing
          Services and information that you request, responding to comments and questions, and
          otherwise providing support to users.
        </p>
        <p>
          ・ Understanding and analysing the usage trends and preferences of our users, improving
          the Services, developing new products, services, features, and functionality.
        </p>
        <p>
          ・ Contacting you for administrative or informational purposes, including by providing
          customer services or sending communications, including changes to our terms, conditions,
          and policies.
        </p>
        <p>
          ・ For marketing purposes, such as developing and providing promotional and advertising
          materials that may be useful, relevant, valuable or otherwise of interest.
        </p>
        <p>・ Personalising your experience on the Services by presenting tailored content.</p>
        <p>
          ・ We may aggregate data collected through the Services and may use and disclose it for
          any purpose.
        </p>
        <p>
          ・ For our business purposes, such as audits, security, compliance with applicable laws
          and regulations, fraud monitoring and prevention.
        </p>
        <p>・ Complying with legal and regulatory requirements.</p>
        <p>・ Protecting our interests, enforcing our Terms of Use or other legal rights.</p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">WHEN AND WITH WHOM DO WE SHARE THE INFORMATION WE COLLECT?</h3>

        <p>
          We do not rent, sell or share your information with third parties except as described in
          this Policy. We may share your information with the following:
        </p>
        <p>・ Entities in our group or our affiliates in order to provide you with the Services;</p>
        <p>
          ・ Our third party service providers who provide services such as website hosting, data
          storage, data analysis, customer services, email delivery, auditing, and other services;
        </p>
        <p>
          ・ Credit bureaus and other third parties who provide Know Your Customer and Anti-Money
          Laundering services;
        </p>
        <p>
          ・ Potential or actual acquirer, successor, or assignee as part of any reorganisation,
          merger, sale, joint venture, assignment, transfer or other disposition of all or any
          portion of our business, assets or stock (including in bankruptcy or similar proceedings);
          and/or
        </p>
        <p>
          ・ If required to do so by law or in the good faith belief that such action is
          appropriate: (a) under applicable law, including laws outside your country of residence;
          (b) to comply with legal process; (c) to respond to requests from public and government
          authorities, including public and government authorities outside your country of
          residence; (d) to enforce our terms and conditions; (e) to protect our operations or those
          of any of our subsidiaries; (f) to protect our rights, privacy, safety or property, and/or
          that of our subsidiaries, you or others; and (g) to allow us to pursue available remedies
          or limit the damages that we may sustain.
        </p>

        <p>
          In addition, we may use third party analytics vendors to evaluate and provide us with
          information about your use of the Services. We do not share your information with these
          third parties, but these analytics service providers may set and access their own cookies,
          pixel tags and similar technologies on the Services and they may otherwise collect or have
          access to information about you which they may collect over time and across different
          websites. For example, we use Google Analytics and Sentry to collect and process certain
          analytics data. Google provides some additional privacy options described at&nbsp;
          <a
            className="underline underline-offset-2 text-blue-400 break-all"
            href="https://www.google.com/policies/privacy/partners"
            target="_blank"
            rel="noreferrer"
          >
            https://www.google.com/policies/privacy/partners
          </a>
          .
        </p>

        <p>
          We may use and disclose aggregate information that does not identify or otherwise relate
          to an individual for any purpose, unless we are prohibited from doing so under applicable
          law.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">THIRD PARTY SERVICES</h3>

        <p>
          We may use third party service providers to keep, store or maintain part of your Personal
          Information, except for your cryptocurrency wallet addresses, which will be stored only by
          us. We will, however, use reasonable commercial endeavours to ensure any such third party
          service provider engaged by us to store your Personal Information will provide reasonable
          care and skill in the performance of their duties and services.
        </p>
        <p>
          We may display third party content on the Services. Third party content may use cookies,
          web beacons, or other mechanisms for obtaining data in connection with your viewing of
          and/or interacting with the third party content on the Services.{' '}
        </p>
        <p>
          You should be aware that there is always some risk involved in transmitting information
          over the internet. While we strive to protect your Personal Information, we cannot ensure
          or warrant the security and privacy of your Personal Information or other content you
          transmit using the Services, and you do so at your own risk.{' '}
        </p>
        <p>
          Please note that we cannot control, nor will we be responsible for the Personal
          Information collected and processed by third parties, its safekeeping or a breach thereof,
          or any other act or omission pertaining to it and their compliance with applicable privacy
          laws or regulations. We advise you to read the respective privacy policy of any such third
          party and use your best discretion.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">HOW WE PROTECT YOUR PERSONAL INFORMATION</h3>
        <p>
          You acknowledge that no data transmission over the internet is totally secure.
          Accordingly, we cannot warrant the security of any information which you transmit to us.
          That said, we do use certain physical, organisational, and technical safeguards that are
          designed to maintain the integrity and security of information that we collect. For
          instance, we have implemented administrative, technical, and physical safeguards to help
          prevent unauthorised access, use, or disclosure of your Personal Information. Your
          Personal Information is stored on secure servers and is not publicly available. We limit
          access of your Personal Information only to those employees or partners that need to know
          the Personal Information in order to achieve the purposes of processing, as described
          above.
        </p>
        <p>
          However, as described above, we engage third party service providers to store your
          Personal Information. We note that we cannot control the security and privacy of the
          information stored with our service providers, but we will use our reasonable commercial
          endeavours to ensure that any such third party service provider has in place certain
          physical, organisational and technical safeguards that are designed to maintain the
          integrity and security of your Personal Information.
        </p>
        <p>
          You need to help us prevent unauthorised access to your account by protecting and limiting
          access to your account appropriately (for example, by logging out after you have finished
          accessing your account). You will be solely responsible for keeping your account against
          any unauthorised use. While we seek to protect your information to ensure that it is kept
          confidential, we cannot absolutely guarantee its security. However, we do not store any
          passwords as an added layer of security.
        </p>
        <p>
          Please be aware that no security measures are perfect or impenetrable and thus we cannot
          and do not guarantee the security of your data. While we strive to protect your Personal
          Information, we cannot ensure or warrant the security and privacy of your Personal
          Information or other content you transmit using the Services, and you do so at your own
          risk. It is important that you maintain the security and control of your account
          credentials.
        </p>
        <p>
          PLEASE KEEP YOUR WALLET RECOVERY PHRASE AND PRIVATE KEYS SAFE. IF YOU LOSE THE KEYS OR
          RECOVERY PHRASE, YOU MAY LOSE ACCESS TO YOUR DIGITAL ASSETS.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">HOW LONG DO WE KEEP YOUR INFORMATION?</h3>

        <p>
          We will retain your Information for as long as necessary to provide our Services, and as
          necessary to comply with our legal obligations (including those specific to financial
          transactions), resolve disputes, and enforce our policies. Retention periods will be
          determined taking into account the type of information that is collected and the purpose
          for which it is collected, bearing in mind the requirements applicable to the situation
          and the need to destroy outdated, unused information at the earliest reasonable time.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">YOUR RIGHTS</h3>

        <p>
          You may, of course, decline to share certain information with us, in which case we may not
          be able to provide to you some of the features and functionality of the Services. You have
          the right to access and correct your information, have your information deleted, object
          how we use or share your information, restrict how we use or share your information,
          withdraw your consent, and request to transmit your information to other controller. You
          can always exercise your rights mentioned above, by contacting us at
          contact@swapscanner.io.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">NO USE OF SERVICES BY MINORS</h3>

        <p>
          The Services are not directed to individuals under the age of eighteen (18), and we
          request that you do not provide Personal Information through the Services if you are under
          18.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">CROSS-BORDER DATA TRANSFER</h3>

        <p>
          Please note that we may be transferring your information outside of your region for
          storage and processing and around the globe. By using the Services, you consent to the
          transfer of information to countries outside of your country of residence, which may have
          data protection rules that are different from those of your country.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">UPDATES TO THIS PRIVACY POLICY</h3>

        <p>
          We may make changes to this Policy. The &ldquo;Last Updated &rdquo; date at the bottom of
          this page indicates when this Policy was last revised. If we make material changes, we may
          notify you through the Services or other communication. The most current version will
          always be posted on our website. We encourage you to read this Policy periodically to stay
          up-to-date about our privacy practices. By continuing to access or use our Services after
          any revisions become effective, you agree to be bound by the updated Policy.
        </p>
      </div>

      <div className="flex flex-col items-start space-y-4">
        <h3 className="font-bold">CONTACT US</h3>

        <p>
          If you have any questions about this Policy, please contact us at contact@swapscanner.io.
        </p>
      </div>

      <b>LAST UPDATED ON 01 June 2024</b>
    </div>
  );
}
