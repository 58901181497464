import getWS, { call } from '@/ws';

export type SubscribePricesProps = {
  tokenAddressList?: string[];
  handler: (data: { timestamp: number; prices: { [tokenAddress: string]: number } }) => void;
};

export async function subscribePrices({ tokenAddressList, handler }: SubscribePricesProps) {
  const ws = await getWS();
  call('token_prices_poll', { tokenAddressList }).catch((err) => {
    console.error('failed to poll token prices', err);
  });
  ws.on('token_prices_poll', handler);

  const unsubscriber = () => {
    ws.off('token_prices_poll', handler);
    call('token_prices_unpoll', {}).catch((err) => {
      console.error('failed to unpoll token prices', err);
    });
  };

  return unsubscriber;
}
