interface Props {
  className?: string;
}

export default function EditIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M13.44 2.56a1.914 1.914 0 0 0-2.707 0L3.338 9.955a1.65 1.65 0 0 0-.398.644l-.914 2.743a.5.5 0 0 0 .632.632l2.743-.914c.243-.08.463-.217.644-.398l7.395-7.394a1.914 1.914 0 0 0 0-2.708Zm-2 .708a.914.914 0 1 1 1.293 1.292L12 5.294l-1.293-1.293l.734-.733ZM10 4.708l1.292 1.293l-5.954 5.954a.648.648 0 0 1-.253.156l-1.794.598l.598-1.793a.649.649 0 0 1 .156-.254L10 4.708Z"
      />
    </svg>
  );
}
