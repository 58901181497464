interface Props {
  className?: string;
}

export default function ExchangeRateIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.628123 5.90119L7.62812 9.90119C7.74139 9.96586 7.86957 9.99987 8 9.99987C8.13043 9.99987 8.25861 9.96586 8.37187 9.90119L15.3719 5.90119C15.4867 5.8356 15.5821 5.74081 15.6485 5.62644C15.7149 5.51207 15.7499 5.38218 15.7499 5.24994C15.7499 5.11769 15.7149 4.9878 15.6485 4.87343C15.5821 4.75906 15.4867 4.66428 15.3719 4.59869L8.37187 0.598687C8.25861 0.534015 8.13043 0.5 8 0.5C7.86957 0.5 7.74139 0.534015 7.62812 0.598687L0.628123 4.59869C0.513293 4.66428 0.417847 4.75906 0.351461 4.87343C0.285074 4.9878 0.250109 5.11769 0.250109 5.24994C0.250109 5.38218 0.285074 5.51207 0.351461 5.62644C0.417847 5.74081 0.513293 5.8356 0.628123 5.90119ZM8.00312 2.11369L13.4881 5.24994L8 8.38619L2.51187 5.24994L8.00312 2.11369ZM15.6544 7.87806C15.753 8.05079 15.779 8.25561 15.7266 8.44748C15.6742 8.63935 15.5477 8.80255 15.375 8.90119L8.375 12.9012C8.26173 12.9659 8.13355 12.9999 8.00312 12.9999C7.87269 12.9999 7.74452 12.9659 7.63125 12.9012L0.631248 8.90119C0.544466 8.85302 0.468108 8.7881 0.406601 8.7102C0.345094 8.6323 0.299661 8.54296 0.272937 8.44737C0.246212 8.35178 0.238727 8.25184 0.250916 8.15334C0.263104 8.05483 0.294724 7.95973 0.343942 7.87353C0.393159 7.78734 0.458996 7.71177 0.537636 7.65121C0.616275 7.59065 0.706153 7.54631 0.80206 7.52074C0.897966 7.49518 0.997993 7.4889 1.09634 7.50228C1.19469 7.51566 1.28941 7.54843 1.375 7.59869L8 11.3862L14.625 7.59869C14.7107 7.54937 14.8052 7.51747 14.9032 7.50483C15.0013 7.49218 15.1008 7.49903 15.1962 7.525C15.2916 7.55096 15.3809 7.59552 15.459 7.65611C15.5371 7.7167 15.6024 7.79213 15.6512 7.87806H15.6544Z"
        fill="#606E83"
      />
    </svg>
  );
}
