import type { PropsOf } from '@headlessui/react/dist/types';

interface Props extends PropsOf<'svg'> {
  className?: string;
}

export default function TriangleIcon({ className, ...props }: Props) {
  return (
    <svg
      className={className}
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.12946 5.41876L4.18627 1.13923C4.58509 0.580878 5.41491 0.580878 5.81373 1.13923L8.87054 5.41876C9.34331 6.08063 8.87018 7 8.05681 7H1.94319C1.12982 7 0.656693 6.08063 1.12946 5.41876Z"
        fill="currentColor"
      />
    </svg>
  );
}
