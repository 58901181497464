import useSWRImmutable from 'swr/immutable';

import fetcher from '@/lib/fetcher';

export type TokenDonationContract = {
  address: string;
  abi: {
    donate: {
      inputs: [
        {
          internalType: string;
          name: string;
          type: string;
        },
        {
          internalType: string;
          name: string;
          type: string;
        },
        {
          internalType: string;
          name: string;
          type: string;
        },
      ];
      name: string;
      outputs: [];
      stateMutability: string;
      type: string;
      payable: boolean;
      signature: string;
    };
    calculateFee: {
      inputs: [
        {
          internalType: string;
          name: string;
          type: string;
        },
        {
          internalType: string;
          name: string;
          type: string;
        },
      ];
      name: string;
      outputs: [
        {
          internalType: string;
          name: string;
          type: string;
        },
        {
          internalType: string;
          name: string;
          type: string;
        },
        {
          internalType: string;
          name: string;
          type: string;
        },
      ];
      stateMutability: string;
      type: string;
      constant: boolean;
      signature: string;
    };
  };
};

export type TokenDonation = {
  contract: TokenDonationContract;
  feeInfo: {
    feeMode: {
      enum: string;
      value: string;
    };
    burnMode: {
      enum: string;
      value: string;
    };
    feeNumerator: string;
    feeDenominator: string;
    feeAmount: string;
    feeTo: string;
    feeToken: string;
  };
};

type Options = { skip: boolean; refreshInterval?: number };

export const useTokenDonation = ({ skip, refreshInterval }: Options) => {
  const res = useSWRImmutable<TokenDonation>(
    skip ? null : `${process.env.API_HOST}/chat/token-donation/info`,
    { fetcher, refreshInterval: refreshInterval ?? 1_000 * 60 * 30 },
  );

  return res;
};
