export const ErrorIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00049 1.26483C8.65588 1.26483 9.26668 1.58565 9.64079 2.12088L9.7124 2.23075L15.3645 11.6666C15.7198 12.282 15.722 13.0398 15.3701 13.6571C15.0401 14.2359 14.4455 14.6099 13.7809 14.6608L13.6472 14.6667H2.3465C1.63591 14.6588 0.982831 14.2745 0.630924 13.6571C0.302478 13.0809 0.28243 12.3824 0.574539 11.784L0.641913 11.6574L6.29049 2.22758C6.65306 1.62986 7.3014 1.26483 8.00049 1.26483ZM8.00049 2.59816C7.79659 2.59816 7.60564 2.69132 7.4814 2.84596L7.4324 2.91591L1.79115 12.3334C1.6727 12.5385 1.67199 12.7911 1.78929 12.9969C1.89193 13.1769 2.07143 13.2975 2.26848 13.3266L2.35382 13.3334H13.6398C13.8767 13.3308 14.0944 13.2027 14.2117 12.9969C14.3143 12.8168 14.3266 12.6009 14.2524 12.4185L14.2152 12.3426L8.57049 2.91908C8.44964 2.71984 8.23352 2.59816 8.00049 2.59816ZM8.00049 10.6667C8.36868 10.6667 8.66716 10.9651 8.66716 11.3333C8.66716 11.7015 8.36868 12 8.00049 12C7.6323 12 7.33382 11.7015 7.33382 11.3333C7.33382 10.9651 7.6323 10.6667 8.00049 10.6667ZM8.00049 5.33333C8.34238 5.33333 8.62416 5.59069 8.66267 5.92225L8.66716 5.99999V8.66666C8.66716 9.03485 8.36868 9.33333 8.00049 9.33333C7.6586 9.33333 7.37682 9.07597 7.33831 8.74441L7.33382 8.66666V5.99999C7.33382 5.6318 7.6323 5.33333 8.00049 5.33333Z"
      fill="#F66161"
    />
  </svg>
);
