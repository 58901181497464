const KLIP_SERVER_URL = 'https://a2a-api.klipwallet.com/v2/a2a';

export type BAppData = {
  name: string;
  callback?: {
    success?: string;
    fail?: string;
  };
};

export type KlipRequestRaw = {
  request_key: string;
  status: string;
  expiration_time: number;
};

export type KlipResult<Result = any> = KlipRequestRaw & {
  status: 'prepared' | 'requested' | 'completed' | 'canceled' | 'error';
  result: Result;
};

export type TxKlip = {
  from: string;
  to: string;
  abi: string;
  params: string;
  value: string | number;
  txType: string;
  txWarning: string;
};

export type MessageKlip = {
  from: string;
  value: string;
};

type FetchKlipOptions = {
  method: 'GET' | 'POST';
  path: string;
  params?: any;
  json?: any;
};

type KlipPrepareOptions = {
  type: string;
  transaction?: TxKlip;
  message?: MessageKlip;
};

export type KlipClientOptions = {
  bappData: BAppData;
};

export class KlipClient {
  private bappData: BAppData;

  constructor({ bappData }: KlipClientOptions) {
    this.bappData = bappData;
  }

  async prepare({ type, transaction, message }: KlipPrepareOptions): Promise<KlipRequestRaw> {
    const response = await this.fetchKlipApi({
      method: 'POST',
      path: '/prepare',
      json: { type, transaction, message }, // undefined keys will be deleted automatically in `JSON.stringify()` in `fetchKlipApi()`
    });
    return await response.json();
  }

  async getResult<Result = any>(requestKey: string): Promise<KlipResult<Result>> {
    const response = await this.fetchKlipApi({
      method: 'GET',
      path: '/result',
      params: { request_key: requestKey },
    });
    return await response.json();
  }

  private async fetchKlipApi({ method, path, params, json }: FetchKlipOptions): Promise<Response> {
    let url = `${KLIP_SERVER_URL}${path}`;
    const options: RequestInit = { method };
    if (params) {
      const query = new URLSearchParams(params);
      url += `?${query.toString()}`;
    }
    if (json) {
      options.headers = { 'Content-Type': 'application/json' };
      options.body = JSON.stringify({ bapp: this.bappData, ...(json ?? {}) });
    }
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`[fetchKlipApi] Request failed with status code ${response.status}`);
    }

    return response;
  }
}
