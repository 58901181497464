interface Props {
  className?: string;
  rectColor?: string;
  hexaColor?: string;
  textColor?: string;
}

export default function NFTIcon({
  className,
  rectColor = '#2F3948', // default: darkmode
  textColor = '#2F3948', // default: darkmode
}: Props) {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2491_14652)">
        <rect x="7" y="6" width="32" height="32" rx="4" fill={rectColor} />
        <path
          d="M32.75 17.5169V26.4832C32.7494 26.7517 32.6767 27.0152 32.5395 27.2461C32.4023 27.477 32.2056 27.6668 31.97 27.7957L23.72 32.3116C23.4996 32.4334 23.2518 32.4973 23 32.4973C22.7482 32.4973 22.5004 32.4334 22.28 32.3116L14.03 27.7957C13.7944 27.6668 13.5977 27.477 13.4605 27.2461C13.3233 27.0152 13.2506 26.7517 13.25 26.4832V17.5169C13.2506 17.2484 13.3233 16.9849 13.4605 16.754C13.5977 16.5231 13.7944 16.3333 14.03 16.2044L22.28 11.6885C22.5004 11.5667 22.7482 11.5028 23 11.5028C23.2518 11.5028 23.4996 11.5667 23.72 11.6885L31.97 16.2044C32.2056 16.3333 32.4023 16.5231 32.5395 16.754C32.6767 16.9849 32.7494 17.2484 32.75 17.5169Z"
          fill="#9FA8B7"
        />
        <path
          d="M16.1055 25.0859C16.5312 25.0859 16.7852 24.832 16.7852 24.375V21.6914H16.8164L18.9883 24.6875C19.1953 24.9727 19.3867 25.0859 19.6758 25.0859C20.1094 25.0859 20.3516 24.8477 20.3516 24.4141V19.9883C20.3516 19.5312 20.1016 19.2773 19.6719 19.2773C19.2461 19.2773 18.9922 19.5312 18.9922 19.9883V22.6406H18.9609L16.8047 19.6758C16.5898 19.3945 16.3945 19.2773 16.1211 19.2773C15.6758 19.2773 15.4258 19.5156 15.4258 19.957V24.375C15.4258 24.832 15.6758 25.0859 16.1055 25.0859ZM22.0858 25.0859C22.5389 25.0859 22.8006 24.8164 22.8006 24.3398V22.8984H24.4569C24.828 22.8984 25.0545 22.6953 25.0545 22.3516C25.0545 22.0039 24.8202 21.8008 24.4569 21.8008H22.8006V20.5195H24.6405C25.0194 20.5195 25.2772 20.3086 25.2772 19.9414C25.2772 19.5742 25.0272 19.3633 24.6405 19.3633H22.0858C21.6327 19.3633 21.367 19.6367 21.367 20.1133V24.3398C21.367 24.8164 21.6327 25.0859 22.0858 25.0859ZM28.0192 25.0859C28.4723 25.0859 28.7341 24.8164 28.7341 24.3398V20.5195H29.7262C30.1052 20.5195 30.363 20.3086 30.363 19.9414C30.363 19.5742 30.113 19.3633 29.7262 19.3633H26.3161C25.9255 19.3633 25.6755 19.5742 25.6755 19.9414C25.6755 20.3086 25.9333 20.5195 26.3161 20.5195H27.3044V24.3398C27.3044 24.8164 27.5661 25.0859 28.0192 25.0859Z"
          fill={textColor}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2491_14652"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2491_14652" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2491_14652"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
