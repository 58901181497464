import { Menu } from '@headlessui/react';
import { CheckIcon, CogIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { MenuTransition } from '@/components/transition';
import { Currency, CURRENCY_LIST, useCurrencyContext } from '@/context/currency';
import { useSettingsContext } from '@/context/settings';

interface SettingsMenuButtonProps {
  withoutTheme?: boolean;
  forceDark?: boolean;
}

export const ONLY_DARK_THEME_LIST = ['/pro/swap', '/pro/chart'];
const CURRENCY_ORDER: Currency[] = ['USD_COINGECKO', 'KRW_COINGECKO', 'KRW_BITHUMB'];

export default function SettingsMenuButton({
  withoutTheme = false,
  forceDark = false,
}: SettingsMenuButtonProps) {
  const router = useRouter();
  const { currency, setCurrency, forex } = useCurrencyContext();
  const { t } = useTranslation('common');
  const { themeMode, onChangeMode } = useSettingsContext();

  const currentLocale = router.locale;
  const pathName = useMemo(() => router.pathname, [router.pathname]);

  return (
    <Menu as="div" className="z-20 relative inline-flex text-left">
      <Menu.Button
        className={clsx(
          'rounded-md',
          forceDark
            ? 'text-slate-300 hover:text-slate-400'
            : 'dark:text-slate-300 dark:hover:text-slate-400 text-slate-600 hover:text-slate-500',
        )}
      >
        <CogIcon className="h-6 w-6" />
      </Menu.Button>

      <MenuTransition>
        <Menu.Items
          className={clsx(
            'absolute top-full translate-y-2 right-0 mt-2 w-56 origin-top-right',
            'divide-y rounded-md shadow-xl ring-1 focus-visible:outline-none ring-slate-50/5',
            forceDark
              ? 'divide-slate-500 bg-dark-card-color'
              : 'divide-slate-300 bg-slate-50 dark:divide-slate-500 dark:bg-dark-card-color',
          )}
          style={{ zIndex: 10 }}
        >
          <div className="px-2.5 py-2">
            <p
              className={clsx(
                'px-2 mb-1',
                forceDark ? 'text-slate-300' : 'text-slate-600 dark:text-slate-300',
              )}
            >
              {t('Language')}
            </p>
            {router.locales?.map((lang) => (
              <Menu.Item key={lang}>
                {({ active }) => (
                  <button
                    className={clsx(
                      'flex w-full items-center rounded-md px-2 py-2 text-sm justify-between',
                      forceDark
                        ? {
                            'text-slate-300': active && lang !== currentLocale,
                            'text-slate-400': !active,
                            'text-slate-100 font-medium': lang === currentLocale,
                          }
                        : {
                            'text-slate-600 dark:text-slate-300': active && lang !== currentLocale,
                            'text-slate-500 dark:text-slate-400': !active,
                            'text-slate-800 dark:text-slate-100 font-medium':
                              lang === currentLocale,
                          },
                    )}
                    onClick={() => {
                      document.cookie = `NEXT_LOCALE=${lang}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
                      router.push(router.asPath, router.asPath, {
                        locale: lang,
                        scroll: false,
                      });
                    }}
                  >
                    <span>{t(lang)}</span>
                    <CheckIcon
                      className={clsx(
                        'h-5 w-5',
                        forceDark ? 'text-slate-400' : 'text-slate-500 dark:text-slate-400',
                        {
                          hidden: lang !== currentLocale,
                        },
                      )}
                    />
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>

          <div className="px-2.5 py-2">
            <p
              className={clsx(
                'px-2 mb-1',
                forceDark ? 'text-slate-300' : 'text-slate-600 dark:text-slate-300',
              )}
            >
              {t('Currency')}
            </p>
            {CURRENCY_LIST.sort(
              (a, b) => CURRENCY_ORDER.indexOf(a) - CURRENCY_ORDER.indexOf(b),
            ).map((cur) => (
              <Menu.Item key={cur}>
                {({ active }) => (
                  <button
                    className={clsx(
                      'flex w-full items-center rounded-md px-2 py-2 text-sm justify-between text-left',
                      forceDark
                        ? {
                            'text-slate-300': active && cur !== currency,
                            'text-slate-400': !active,
                            'text-slate-100 font-medium': cur === currency,
                          }
                        : {
                            'text-slate-600 dark:text-slate-300': active && cur !== currency,
                            'text-slate-500 dark:text-slate-400': !active,
                            'text-slate-800 dark:text-slate-100 font-medium': cur === currency,
                          },
                      {
                        'cursor-not-allowed opacity-50': !forex || !forex[cur],
                      },
                    )}
                    disabled={!forex || !forex[cur]}
                    onClick={() => setCurrency(cur)}
                  >
                    <div className="flex flex-col items-start">
                      <span>{t(cur.slice(0, 3))}</span>
                      <span className="text-[13px]">
                        {t(
                          ['USD', 'KRW'].includes(cur)
                            ? 'main_description'
                            : cur.includes('COINGECKO')
                            ? 'coingecko_description'
                            : 'bithumb_description',
                        )}
                      </span>
                    </div>

                    <CheckIcon
                      className={clsx(
                        'h-5 w-5',
                        forceDark ? 'text-slate-400' : 'text-slate-500 dark:text-slate-400',
                        {
                          hidden: cur !== currency,
                        },
                      )}
                    />
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>

          {!withoutTheme && !ONLY_DARK_THEME_LIST.includes(pathName) && (
            <div className="px-2.5 py-2">
              <p
                className={clsx(
                  'px-2 mb-1',
                  forceDark ? 'text-slate-300' : 'text-slate-600 dark:text-slate-300',
                )}
              >
                {t('Display Mode')}
              </p>
              {['dark', 'light'].map((theme) => (
                <Menu.Item key={theme}>
                  {({ active }) => (
                    <button
                      className={clsx(
                        'flex w-full items-center rounded-md px-2 py-2 text-sm justify-between',
                        forceDark
                          ? {
                              'text-slate-300': active && active && theme !== themeMode,
                              'text-slate-400': !active,
                              'text-slate-100 font-medium': theme === themeMode,
                            }
                          : {
                              'text-slate-600 dark:text-slate-300':
                                active && active && theme !== themeMode,
                              'text-slate-500 dark:text-slate-400': !active,
                              'text-slate-800 dark:text-slate-100 font-medium': theme === themeMode,
                            },
                      )}
                      // @ts-ignore
                      onClick={() => onChangeMode({ target: { value: theme } })}
                    >
                      <span>{t(theme)}</span>
                      <CheckIcon
                        className={clsx(
                          'h-5 w-5',
                          forceDark ? 'text-slate-400' : 'text-slate-500 dark:text-slate-400',
                          {
                            hidden: theme !== themeMode,
                          },
                        )}
                      />
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          )}
        </Menu.Items>
      </MenuTransition>
    </Menu>
  );
}
