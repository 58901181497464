import {
  ChangeEvent,
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { LOCAL_STORAGE_KEYS } from '@/defines/local-storage-keys';
import useLocalStorage from '@/hooks/use-local-storage';
import { GTM } from '@/utils/tag-manager';

// ----------------------------------------------------------------------

export type SettingsContext = {
  themeMode: string;
  onChangeMode: (event: ChangeEvent<HTMLInputElement>) => void;
  onToggleMode: () => void;
  onResetSetting: () => void;
};

const initialState = {
  themeMode: 'dark',
  onChangeMode: () => {},
  onToggleMode: () => {},
  onResetSetting: () => {},
};

const SettingsContext = createContext<SettingsContext>(initialState);

type Props = {
  children?: ReactNode;
};

const SettingsProvider: FC<Props> = ({ children }) => {
  const [settings, setSettings] = useLocalStorage(LOCAL_STORAGE_KEYS.settings, {
    themeMode: 'dark',
  });

  useEffect(() => {
    if (settings.themeMode === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [settings.themeMode]);

  const onChangeMode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      GTM.dataLayer({
        dataLayer: {
          event: 'theme_change',
          themeMode: event.target.value,
        },
      });
      setSettings({
        ...settings,
        themeMode: event.target.value,
      });
    },
    [settings, setSettings],
  );

  const onToggleMode = useCallback(() => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  }, [settings, setSettings]);

  const onResetSetting = useCallback(() => {
    setSettings({
      themeMode: initialState.themeMode,
    });
  }, [setSettings]);

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        onToggleMode,
        // Reset Setting
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

const useSettingsContext = () => {
  return useContext(SettingsContext);
};

export { SettingsProvider, SettingsContext, useSettingsContext };
