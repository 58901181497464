interface Props {
  className?: string;
  width?: number;
  height?: number;
}

export default function InfoIcon({ className, width, height }: Props) {
  return (
    <svg
      className={className}
      width={width || 14}
      height={height || 14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 4.25C5.75 4.05222 5.80865 3.85888 5.91853 3.69443C6.02841 3.52998 6.18459 3.40181 6.36732 3.32612C6.55005 3.25043 6.75111 3.23063 6.94509 3.26921C7.13907 3.3078 7.31726 3.40304 7.45711 3.54289C7.59696 3.68275 7.6922 3.86093 7.73079 4.05491C7.76937 4.24889 7.74957 4.44996 7.67388 4.63268C7.5982 4.81541 7.47002 4.97159 7.30557 5.08147C7.14112 5.19135 6.94778 5.25 6.75 5.25C6.48479 5.25 6.23043 5.14464 6.0429 4.95711C5.85536 4.76957 5.75 4.51522 5.75 4.25ZM13.75 7C13.75 8.33502 13.3541 9.64007 12.6124 10.7501C11.8707 11.8601 10.8165 12.7253 9.58312 13.2362C8.34971 13.7471 6.99252 13.8808 5.68314 13.6203C4.37377 13.3598 3.17104 12.717 2.22703 11.773C1.28303 10.829 0.640153 9.62623 0.379702 8.31686C0.119252 7.00749 0.252925 5.65029 0.763816 4.41689C1.27471 3.18349 2.13987 2.12928 3.2499 1.38758C4.35994 0.645881 5.66498 0.25 7 0.25C8.78961 0.251985 10.5053 0.963781 11.7708 2.22922C13.0362 3.49466 13.748 5.2104 13.75 7ZM12.25 7C12.25 5.96165 11.9421 4.94661 11.3652 4.08326C10.7883 3.2199 9.9684 2.54699 9.00909 2.14963C8.04978 1.75227 6.99418 1.6483 5.97578 1.85088C4.95738 2.05345 4.02192 2.55346 3.28769 3.28769C2.55347 4.02191 2.05345 4.95738 1.85088 5.97578C1.64831 6.99418 1.75228 8.04978 2.14964 9.00909C2.547 9.9684 3.2199 10.7883 4.08326 11.3652C4.94662 11.9421 5.96165 12.25 7 12.25C8.39193 12.2485 9.72643 11.6949 10.7107 10.7107C11.6949 9.72642 12.2485 8.39193 12.25 7ZM7.75 9.2925V7.25C7.75 6.91848 7.61831 6.60054 7.38389 6.36612C7.14947 6.1317 6.83152 6 6.5 6C6.32288 5.99974 6.15139 6.06217 6.01588 6.17623C5.88038 6.2903 5.78962 6.44864 5.75968 6.62321C5.72974 6.79778 5.76254 6.97731 5.85229 7.13001C5.94203 7.28271 6.08292 7.39872 6.25 7.4575V9.5C6.25 9.83152 6.3817 10.1495 6.61612 10.3839C6.85054 10.6183 7.16848 10.75 7.5 10.75C7.67712 10.7503 7.84862 10.6878 7.98412 10.5738C8.11962 10.4597 8.21038 10.3014 8.24032 10.1268C8.27027 9.95222 8.23746 9.77269 8.14772 9.61999C8.05798 9.46729 7.91709 9.35128 7.75 9.2925Z"
        fill="currentColor"
      />
    </svg>
  );
}
