import { useCallback, useEffect, useRef } from 'react';
import useSWRImmutable from 'swr/immutable';

import fetcher from '@/lib/fetcher';

export type Balances = {
  [address: string]: string;
};

type UseBalancesV1Options = {
  account: string | null;
  skip?: boolean;
};

export default function useBalancesV1({ account, skip = false }: UseBalancesV1Options) {
  const { data, mutate, isValidating } = useSWRImmutable<{ balances: Balances; fetchedAt: number }>(
    skip || !account ? null : `${process.env.API_HOST}/v0/tokens/balances?account=${account}`,
    fetcher,
    {
      revalidateIfStale: false,
      refreshInterval: 300_000, // 5 minutes
    },
  );

  const updateBalancesOf = useCallback(
    (newBalances: Balances, fetchedAt?: number) => {
      mutate(
        (prev) => ({
          balances: {
            ...prev?.balances,
            ...newBalances,
          },
          fetchedAt: fetchedAt ?? Date.now(),
        }),
        {
          revalidate: false,
          populateCache: true,
        },
      );
    },
    [mutate],
  );

  return {
    balances: data?.balances,
    fetchedAt: data?.fetchedAt,
    updateBalancesOf,
    mutate,
    isValidating,
  };
}
