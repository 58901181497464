import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useWindowSize } from '@/hooks/use-window-size';
import { isMobile as isMobileBowser } from '@/utils/bowser';
import { GTM } from '@/utils/tag-manager';

type ChatContextType = {
  isChatOpen: boolean;
  openChat: () => void;
  closeChat: () => void;
  isMobile: boolean;
};

export const ChatContext = createContext<ChatContextType>({
  isChatOpen: false,
  openChat: () => {},
  closeChat: () => {},
  isMobile: false,
});

export const ChatContextProvider = ({ children }: { children: ReactNode }) => {
  const [isChatOpen, setChatOpen] = useState(false);
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => isMobileBowser() || windowSize.width <= 408, [windowSize.width]);

  const chatOpenedAt = useRef<number | null>(null);

  const openChat = useCallback(() => {
    chatOpenedAt.current = Date.now();
    GTM.dataLayer({
      dataLayer: {
        event: 'chat_open',
      },
    });
    setChatOpen(true);
  }, [setChatOpen]);

  const closeChat = useCallback(() => {
    if (chatOpenedAt.current) {
      const chatOpenTimeMS = Date.now() - chatOpenedAt.current;
      GTM.dataLayer({
        dataLayer: {
          event: 'chat_close',
          chatOpenTimeMS,
        },
      });
      chatOpenedAt.current = null;
    }
    setChatOpen(false);
  }, [setChatOpen]);

  return (
    <ChatContext.Provider
      value={{
        isChatOpen,
        openChat,
        closeChat,
        isMobile,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);

  if (typeof context === 'undefined') {
    throw new Error(`useChatContext must be used within a ChatContextProvider`);
  }

  return context;
};
