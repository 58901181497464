import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Fragment, useCallback, useEffect, useRef } from 'react';

import CopyIcon from '@/components/vector/CopyIcon';
import StarIcon from '@/components/vector/StarIcon';
import { DIALOG_BACKGROUND_COLOR } from '@/defines/consts';
import { copyToClipboard } from '@/utils/copy-to-clipboard';

import s from './AddressBookV2.module.css';

import type { AddressBook } from '@/components/modal/AddressBookDialog/AddressBookV2';

export type AddAddressBookV2DialogProps = {
  open: boolean;
  checksumAddress: string;
  addressBook: AddressBook[];
  setAddressBook: (value: AddressBook[]) => void;
  forceDark?: boolean;
  onClose: () => void;
};

export default function AddAddressBookV2Dialog({
  open,
  checksumAddress,
  addressBook,
  setAddressBook,
  forceDark = false,
  ...props
}: AddAddressBookV2DialogProps) {
  const dummyButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('addressBook');

  const onClose = useCallback(() => {
    props.onClose();
  }, [props]);

  const walletNameInputRef = useRef<HTMLInputElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!open) return;

    let timeoutId = setInterval(() => {
      if (walletNameInputRef.current) {
        walletNameInputRef.current.focus();
        clearInterval(timeoutId);
      } else {
        timeoutId = setInterval(() => {
          if (walletNameInputRef.current) {
            walletNameInputRef.current.focus();
            clearInterval(timeoutId);
          }
        }, 100);
      }
    }, 100);
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto"
        onClose={onClose}
        initialFocus={dummyButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className={clsx('fixed inset-0 transition-all', DIALOG_BACKGROUND_COLOR)}
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={clsx(
              forceDark
                ? 'bg-gray-900 ring-1 ring-slate-50/5'
                : 'bg-gray-50 dark:bg-gray-900 dark:ring-1 dark:ring-slate-50/5',
              'max-w-[92%] xs:max-w-lg w-full p-8 transform overflow-hidden rounded-xl shadow-2xl transition-all fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
            )}
          >
            <div className="flex flex-row items-center space-x-2">
              <StarIcon className="text-yellow-500" />
              <h2 className="text-2xl font-bold">{t('Added to address book')}</h2>
            </div>
            <div className="mt-8">
              <span className={s['input-label']}>{t('Address')}</span>
              <div className={clsx('flex flex-row justify-between', s['content'])}>
                <span className="truncate">{checksumAddress}</span>

                <button
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={t('Copy Address')}
                  className="hover:opacity-70"
                  onClick={() => {
                    copyToClipboard(checksumAddress);
                  }}
                >
                  <CopyIcon width={16} height={16} className={s['icon-color']} />
                </button>
              </div>
            </div>
            <div className="mt-4">
              <span className={s['input-label']}>{t('Name')}</span>
              <input
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
                    if (saveButtonRef.current) saveButtonRef.current.click();
                  }
                }}
                type="text"
                maxLength={26}
                ref={walletNameInputRef}
                className={s['content']}
                placeholder={t('Enter a name')}
              />
            </div>
            <div className="flex flex-row mt-8 justify-end">
              <button
                className={clsx(s['button'], s['close-button'])}
                onClick={() => {
                  setAddressBook(
                    addressBook.filter(
                      ({ address }) => address.toLowerCase() !== checksumAddress.toLowerCase(),
                    ),
                  );

                  onClose();
                }}
              >
                {t('Delete')}
              </button>
              <button
                ref={saveButtonRef}
                className={clsx(s['button'], s['save-button'])}
                onClick={() => {
                  if (walletNameInputRef.current) {
                    const loweredCheckSumAddress = checksumAddress.toLowerCase();

                    setAddressBook([
                      ...addressBook.filter(
                        ({ address }) => address.toLowerCase() !== loweredCheckSumAddress,
                      ),
                      {
                        name: walletNameInputRef.current.value,
                        address: loweredCheckSumAddress,
                      },
                    ]);
                  }

                  onClose();
                }}
              >
                {t('Save')}
              </button>
            </div>
          </div>
        </Transition.Child>
        <style jsx>{`
          .hide-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
          .hide-scrollbar::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera */
          }
        `}</style>
      </Dialog>
    </Transition.Root>
  );
}
