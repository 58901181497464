import millify from 'millify';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';

import { Currency, useCurrencyContext } from '@/context/currency';
import { numberToReadableLocaleString } from '@/utils/number-to-readable-locale-string';
import { safeFiniteNumber } from '@/utils/safe-finite-number';

export function numberToKorean(val: number, simpleString = false): string {
  if (!isFinite(val)) return '-';

  if (val < 100) {
    return numberToReadableLocaleString(val);
  }

  const unitWords = ['', '만', '억', '조', '경'];
  const splitUnit = 10000;
  const splitCount = unitWords.length;
  const resultArray = [];
  let resultString = '';
  let maxNumberAppliedUnit = 0;

  for (let i = 0; i < splitCount; i += 1) {
    const unitResult = (val % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    const unitResultAppliedFloor = Math.floor(unitResult);

    if (unitResultAppliedFloor > 0) {
      resultArray[i] = unitResultAppliedFloor;
      maxNumberAppliedUnit = Math.round(unitResult);
    }
  }

  if (simpleString) {
    const firstUnitLength = resultArray[resultArray.length - 1].toString().length;

    // When the unit is '억' or higher, the significant digits are expressed up to a minimum of three.
    if (3 <= resultArray.length && firstUnitLength < 3) {
      // Because 'resultArray[resultArray.length - 2]' is 'empty' when it is '0',
      // only show the first digit of the number in this case
      if (!resultArray[resultArray.length - 2]) {
        return resultArray[resultArray.length - 1] + unitWords[resultArray.length - 1];
      }

      const result = Number(
        resultArray[resultArray.length - 1] +
          '.' +
          resultArray[resultArray.length - 2].toString().padStart(4, '0'),
      );

      return (
        result.toLocaleString(undefined, {
          minimumFractionDigits: 3 - firstUnitLength,
          maximumFractionDigits: 3 - firstUnitLength,
        }) + unitWords[resultArray.length - 1]
      );
    }

    return maxNumberAppliedUnit / splitUnit === 1
      ? 1 + unitWords[resultArray.length]
      : maxNumberAppliedUnit + unitWords[resultArray.length - 1];
  }

  for (let i = 0; i < resultArray.length; i += 1) {
    if (!resultArray[i]) continue;
    resultString = resultArray[i].toLocaleString() + unitWords[i] + ' ' + resultString;
  }

  if (!resultString) {
    return '0';
  }

  return resultString.trim();
}

export default function useForex() {
  const { currency, currencyType, rate, forex } = useCurrencyContext();
  const { t } = useTranslation('common');
  const router = useRouter();

  const krwRate = forex?.KRW ?? 0;
  const krwRateWithKimp = useMemo(() => {
    if (!forex) return 0;
    if (!forex.USD_COINGECKO || !forex.KRW_BITHUMB) return safeFiniteNumber(1 / forex.KRW, 0);

    return safeFiniteNumber(forex.USD_COINGECKO / forex.KRW_BITHUMB, 0);
  }, [forex]);

  const usdToValue = useCallback(
    (
      value: number | null = 0,
      { inputCurrency, useKimp }: { inputCurrency?: Currency; useKimp?: boolean } = {},
    ) => {
      const _value = value ?? 0;

      if (useKimp) {
        if (currency === 'KRW_BITHUMB') {
          return _value * krwRateWithKimp;
        }

        if (currency === 'KRW_COINGECKO') {
          return _value * (1 / krwRate);
        }

        return _value;
      }

      return (
        _value *
        (inputCurrency && forex && forex[inputCurrency]
          ? safeFiniteNumber(1 / forex[inputCurrency], rate)
          : rate)
      );
    },
    [rate, forex, krwRateWithKimp, currency, krwRate],
  );

  const valueWithCurrency = useCallback(
    (valueString: string, { spacing }: { spacing?: boolean } = {}) => {
      return t((spacing ? 'forex-spacing-' : 'forex-') + currencyType, {
        amount: valueString,
      });
    },
    [currencyType, t],
  );

  const usdToReadableCurrency = useCallback(
    (
      value?: number | null,
      {
        fixTwoFractionsForUSD = true,
        inputCurrency,
        useKimp,
        spacing,
      }: {
        fixTwoFractionsForUSD?: boolean;
        inputCurrency?: Currency;
        useKimp?: boolean;
        spacing?: boolean;
      } = {},
    ) =>
      valueWithCurrency(
        currencyType === 'USD' && fixTwoFractionsForUSD
          ? numberToReadableLocaleString(usdToValue(value, { inputCurrency, useKimp }), {
              calcMinimumFractionDigits: (fractionDigits) => Math.max(fractionDigits, 2),
              calcMaximumFractionDigits: (fractionDigits) => Math.max(fractionDigits, 2),
            })
          : numberToReadableLocaleString(usdToValue(value, { inputCurrency, useKimp })),
        { spacing },
      ),
    [currencyType, valueWithCurrency, usdToValue],
  );

  const usdToReadableString = useCallback(
    (
      value?: number | null,
      {
        inputCurrency,
        useKimp,
        spacing,
      }: { inputCurrency?: Currency; useKimp?: boolean; spacing?: boolean } = {},
    ) => {
      if (currencyType === 'KRW' && router.locale === 'ko') {
        return valueWithCurrency(numberToKorean(usdToValue(value, { inputCurrency, useKimp })), {
          spacing,
        });
      }

      return usdToReadableCurrency(value, { inputCurrency, useKimp, spacing });
    },
    [valueWithCurrency, usdToValue, usdToReadableCurrency, router.locale, currencyType],
  );

  const usdToReadableSimpleString = useCallback(
    (
      value?: number | null,
      {
        precision = 1,
        inputCurrency,
        useKimp,
        spacing,
      }: {
        precision?: number;
        inputCurrency?: Currency;
        useKimp?: boolean;
        spacing?: boolean;
      } = {},
    ) => {
      if (currencyType === 'KRW' && router.locale === 'ko') {
        return valueWithCurrency(
          numberToKorean(usdToValue(value, { inputCurrency, useKimp }), true),
        );
      }

      return valueWithCurrency(
        millify(usdToValue(value, { inputCurrency, useKimp }) ?? 0, { precision }),
      );
    },
    [valueWithCurrency, usdToValue, router.locale, currencyType],
  );

  return {
    usdToReadableString,
    usdToValue,
    valueWithCurrency,
    usdToReadableCurrency,
    usdToReadableSimpleString,
    forex,
  };
}
