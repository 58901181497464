import useTokens from './use-tokens';

import type { Token } from '@/lib/tokens';

export default function useToken(address?: string | null): Token | null {
  const { tokenAddressToToken } = useTokens({ withImaginaryFiats: true });

  if (!address) {
    return null;
  }

  if (!tokenAddressToToken) {
    return null;
  }

  return tokenAddressToToken[address];
}
