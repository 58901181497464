interface Props {
  className?: string;
}

export default function TokenMenuIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.375 7.49766V7.0625C14.375 5.10312 11.4195 3.625 7.5 3.625C3.58047 3.625 0.625 5.10312 0.625 7.0625V10.1875C0.625 11.8195 2.67578 13.1164 5.625 13.5047V13.9375C5.625 15.8969 8.58047 17.375 12.5 17.375C16.4195 17.375 19.375 15.8969 19.375 13.9375V10.8125C19.375 9.19531 17.3891 7.89687 14.375 7.49766ZM4.375 11.9742C2.84453 11.5469 1.875 10.843 1.875 10.1875V9.08828C2.5125 9.53984 3.36641 9.90391 4.375 10.1484V11.9742ZM10.625 10.1484C11.6336 9.90391 12.4875 9.53984 13.125 9.08828V10.1875C13.125 10.843 12.1555 11.5469 10.625 11.9742V10.1484ZM9.375 15.7242C7.84453 15.2969 6.875 14.593 6.875 13.9375V13.6117C7.08047 13.6195 7.28828 13.625 7.5 13.625C7.80312 13.625 8.09922 13.6148 8.38984 13.5977C8.7127 13.7132 9.04157 13.8113 9.375 13.8914V15.7242ZM9.375 12.2383C8.75422 12.33 8.12751 12.3757 7.5 12.375C6.87249 12.3757 6.24578 12.33 5.625 12.2383V10.3797C6.2467 10.4607 6.87304 10.5009 7.5 10.5C8.12696 10.5009 8.7533 10.4607 9.375 10.3797V12.2383ZM14.375 15.9883C13.1316 16.1706 11.8684 16.1706 10.625 15.9883V14.125C11.2465 14.2085 11.8729 14.2503 12.5 14.25C13.127 14.2509 13.7533 14.2107 14.375 14.1297V15.9883ZM18.125 13.9375C18.125 14.593 17.1555 15.2969 15.625 15.7242V13.8984C16.6336 13.6539 17.4875 13.2898 18.125 12.8383V13.9375Z"
        fill="currentColor"
      />
    </svg>
  );
}
