interface Props {
  className?: string;
}

export default function AddWalletIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 8C10.9797 8 10.6406 8.15804 10.3905 8.43934C10.1405 8.72064 10 9.10218 10 9.5C10 10.325 10.6 11 11.3333 11H14V8H11.3333Z"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5V12.5C1 13.325 1.675 14 2.5 14H13V11.75"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8 3H2.55556C1.7 3 1 3.675 1 4.5C1 4.89782 1.16389 5.27936 1.45561 5.56066C1.74733 5.84196 2.143 6 2.55556 6H8"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M10 3H16" stroke="currentColor" strokeWidth="1.33" />
      <path d="M13 0L13 6" stroke="currentColor" strokeWidth="1.33" />
    </svg>
  );
}
