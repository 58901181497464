interface Props {
  className?: string;
  width?: number;
  height?: number;
}

export default function CopyIcon({ className, width, height }: Props) {
  return (
    <svg
      className={className}
      width={width || 16}
      height={height || 16}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 16 16"
    >
      <path
        d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
        fill="currentColor"
        fillOpacity="0"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33331 10H2.66665C2.31302 10 1.97389 9.85953 1.72384 9.60949C1.47379 9.35944 1.33331 9.0203 1.33331 8.66668V2.66668C1.33331 2.31305 1.47379 1.97392 1.72384 1.72387C1.97389 1.47382 2.31302 1.33334 2.66665 1.33334H8.66665C9.02027 1.33334 9.35941 1.47382 9.60946 1.72387C9.8595 1.97392 9.99998 2.31305 9.99998 2.66668V3.33334"
        fill="currentColor"
        fillOpacity="0"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
