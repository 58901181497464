import clsx from 'clsx';

import TokenIconV2 from '@/components/TokenIconV2';
import StarIconForTradingView from '@/components/vector/StarIconForTradingView';
import { numberToReadableLocaleString } from '@/utils/number-to-readable-locale-string';

import type { Token } from '@/lib/tokens';

type TokenPairWithPrices = {
  tokens: [Token, Token];
  prices: [number, number]; // [todayOpenPrice, currentPrice]
};

interface StarredPairListItemProps {
  pair: TokenPairWithPrices;
  onToggleStarred: () => void;
  onSelectPair: () => void;
  forceDark?: boolean;
}

export default function StarredPairListItem({
  pair,
  onToggleStarred,
  onSelectPair,
  forceDark = false,
}: StarredPairListItemProps) {
  const { tokens, prices } = pair;

  return (
    <li className="flex items-center my-1.5">
      <button
        className="w-8 h-8 p-1.5 select-none rounded-full hover:bg-gray-900/5 hover:dark:bg-slate-50/5"
        onClick={onToggleStarred}
      >
        <StarIconForTradingView className="text-yellow-500 w-full h-full" />
      </button>
      <button
        className={clsx(
          'flex-1 flex items-center space-x-4 text-left rounded-md px-3 py-2',
          forceDark
            ? 'hover:bg-slate-50/5 hover:text-slate-50'
            : ' hover:bg-gray-900/5 hover:text-gray-900 dark:hover:bg-slate-50/5 dark:hover:text-slate-50',
        )}
        onClick={onSelectPair}
      >
        <div className="contents -space-x-2">
          <TokenIconV2 className="h-8 w-8" address={tokens[0].address} width={32} height={32} />
          <TokenIconV2 className="h-8 w-8" address={tokens[1].address} width={32} height={32} />
        </div>

        <span className="flex-1 text-lg font-semibold">
          {tokens[0].symbol} / {tokens[1].symbol}
        </span>

        <span className="hidden xs:inline text-lg font-medium">
          {numberToReadableLocaleString(prices[1], {
            desiredSignificantDigits: 4,
          })}
        </span>

        <span className={clsx(prices[1] - prices[0] >= 0 ? 'text-teal-500' : 'text-red-500')}>
          {((prices[1] - prices[0]) / prices[0]).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            signDisplay: 'always',
            style: 'percent',
          })}
        </span>
      </button>
    </li>
  );
}
