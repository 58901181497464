// Diff with V1: add remove trailing zeros function
export default function denominateAmount({
  amount,
  decimals,
  removeTrailingZeros = false,
  maxFractionDigits,
}: {
  amount: BigInt;
  decimals: string;
  removeTrailingZeros?: boolean;
  maxFractionDigits?: number;
}) {
  if (!amount || !isFinite(+decimals)) {
    return null;
  }

  let [n, f = ''] = amount.toString().replace(/^0+/, '').replace(/,/g, '').split('.');

  f = f.replace(/0+$/, '');

  let paddedN = n.padStart(+decimals, '0');
  if (paddedN[0] === '0') {
    paddedN = '0.' + paddedN;
  } else {
    const ff = paddedN.slice(paddedN.length - +decimals);
    paddedN = paddedN.slice(0, paddedN.length - +decimals) + (ff.length > 0 ? '.' + ff : '');
  }

  if (paddedN[0] === '.') {
    paddedN = '0' + paddedN;
  }

  let returnAmount = paddedN + (paddedN.indexOf('.') === -1 && f.length > 0 ? '.' + f : f);

  if (maxFractionDigits !== undefined) {
    returnAmount = (+returnAmount).toLocaleString(undefined, {
      maximumFractionDigits: maxFractionDigits,
      useGrouping: false,
    });
  }

  if (removeTrailingZeros && returnAmount.indexOf('.') !== -1) {
    returnAmount = returnAmount.replace(/\.?0+$/, '');
  }

  return returnAmount;
}
