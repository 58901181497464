import type { LineData } from '@/lib/spline';

export type TokenStatsV2 = {
  address: string;
  volume: number;
  swap: number;
  prices: [number, number];
  tvl: number;
  splines?: LineData[];
};

export async function fetchTokenStatsV2(addrList?: string[]) {
  if (addrList?.length === 0) return [];

  const queryParams = !addrList ? '' : `?tokenAddressList=${addrList.join(',')}`;

  return (await fetch(`${process.env.API_HOST}/v2/stats/tokens${queryParams}`).then((r) =>
    r.json(),
  )) as TokenStatsV2[];
}
